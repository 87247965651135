import styled from '@emotion/styled';

import { TextLink } from 'components/Button/Button';
import Flex from 'components/Flex/Flex';

export const Menu = styled.div(({ theme, isMobileMenuOpen, menuHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  left: isMobileMenuOpen ? 0 : '-100vw',
  width: '100vw',
  backgroundColor: theme.color.contrast,
  position: 'relative',
  minHeight: menuHeight,
  paddingBottom: 'calc(100vh - 100svh)', // This is an adjustment for iPh UI bug covering the bottom of a page.

  flex: 1,
  zIndex: 2,

  transition: 'left 0.2s ease',
  overflow: 'hidden',

  [theme.media.lg]: {
    display: 'none',
  },
}));

export const MenuBlock = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100vw',
  padding: 20,
}));

export const CopyRight = styled.span(({ theme }) => ({
  color: theme.color.contrast5,
}));

export const MenuFooter = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  padding: 20,
  gap: 24,
}));

export const MenuFooterSocialsRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: 16,
}));

export const MenuRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignSelf: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '130%',
}));

export const MenuFooterDescription = styled(MenuRow)(() => ({
  flexDirection: 'column',
  maxWidth: 152,
  gap: 7,
}));

export const MenuFooterLinksRow = styled(MenuRow)(({ theme }) => ({
  gap: 24,
}));

export const BoldText = styled.span(({ theme }) => ({
  display: 'inline',
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 'normal',
}));

export const Spacer = styled.div(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.color.contrast2,
  height: 1,
  display: 'flex',
  alignSelf: 'center',
  marginTop: 11,
  marginBottom: 8,
}));

export const MenuButton = styled.button(({ theme }) => ({
  width: '100%',
  padding: '18px 0px 17px',
  textAlign: 'left',
  border: 'none',
  backgroundColor: theme.color.contrast,
  color: theme.color.primary,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.16px',
}));

export const SubMenu = styled(Flex)(
  ({ theme, isSubMenuOpen, isMobileMenuOpen }) => ({
    position: 'absolute',
    top: 0,
    left: isMobileMenuOpen && isSubMenuOpen ? 0 : 280,
    zIndex: 1,
    width: 280,
    height: '100vh',
    backgroundColor: theme.color.contrast1,
    padding: 0,
    transition: 'all 0.2s ease',
  }),
);

export const StyledLink = styled(TextLink)(({ theme }) => ({
  margin: '12px 0',

  '&:first-of-type': {
    marginTop: '40px',
  },
}));

export const SubTextLink = styled(TextLink)(({ theme }) => ({
  margin: '15px 0',

  '&:first-of-type': {
    marginTop: '30px',
  },
}));

export const UserIcon = styled.div(({ theme }) => ({
  width: 30,
  height: 30,
  overflow: 'hidden',
  borderRadius: '50%',
  backgroundColor: theme.color.contrast4,
  lineHeight: '30px',
  marginRight: 10,
}));

export const Overlay = styled.div(({ theme, isMobileMenuOpen }) => ({
  top: 0,
  left: 0,
  right: 0,
  bottom: isMobileMenuOpen ? 0 : '100%',
  backgroundColor: theme.color.contrast,
  opacity: isMobileMenuOpen ? 0.8 : 0,
  position: isMobileMenuOpen ? 'fixed' : 'relative',
  zIndex: 1,
  backdropFilter: isMobileMenuOpen ? 'blur(10px)' : 'unset',
  visibility: isMobileMenuOpen ? 'visible' : 'hidden',
}));

export const List = styled.ul(({ theme }) => ({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  minWidth: '100%',
}));

export const ListItem = styled.li(({ theme, activePage, slug }) => ({
  width: 165,
  height: 36,
  padding: '10px 0px 10px 15px',
  cursor: 'pointer',
  backgroundColor: activePage === slug && theme.color.contrast1,
  borderLeft: activePage === slug && `2px solid ${theme.color.primary}`,
}));
