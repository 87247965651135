import styled from '@emotion/styled';

export const Container = styled.div(({ theme, remove, isBrowse }) => ({
  position: 'fixed',
  zIndex: 1000,
  top: isBrowse ? 160 : 70,
  right: -630,
  height: 142,
  width: 610,
  borderRadius: 3,
  background: theme.color.staticWhite,
  fontColor: theme.color.staticBlack,
  animation: `${
    remove ? 'scrubbingPopupFadeOut' : 'scrubbingPopupFadeIn'
  } ease-in-out 0.3s`,
  animationFillMode: 'forwards',
  boxShadow: theme.shadow.depth2,
  paddingRight: 30,

  '@keyframes scrubbingPopupFadeIn': {
    from: {
      opacity: 0,
      right: -630
    },
    to: {
      opacity: 1,
      right: 15
    }
  },

  '@keyframes scrubbingPopupFadeOut': {
    from: {
      opacity: 1,
      right: 15
    },
    to: {
      opacity: 0,
      right: -630
    }
  }
}));

export const NewTag = styled.p(({ theme }) => ({
  backgroundColor: theme.color.blue,
  color: theme.color.staticWhite,
  fontWeight: 700,
  padding: '2px 4px',
  lineHeight: '10px',
  fontSize: 10,
  display: 'inline-block',
  margin: '0 0 7px 0'
}));

export const Image = styled.img(() => ({
  maxHeight: 142,
  minHeight: 142,
  borderRadius: '3px 0 0 3px'
}));

export const Key = styled.b(({ theme }) => ({
  fontWeight: 400,
  border: `1px solid ${theme.color.staticBlack}`,
  borderRadius: 4,
  padding: '0 8px',
  lineHeight: '22px',
  margin: '0 2px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const CloseIcon = styled.div(({ theme }) => ({
  width: 30,
  height: 30,
  position: 'absolute',
  right: 0,
  top: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const ExpandButton = styled.div({
  position: 'absolute',
  left: 5,
  bottom: 5,
  height: 20,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 6px',
  backgroundColor: 'rgba(0,0,0,0.7)',
  cursor: 'pointer'
});
