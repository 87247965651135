import styled from '@emotion/styled';
import { TextLink } from 'components/Button/Button';
import { Link, NavLink } from 'react-router-dom';
import Button from 'components/Button/Button';

export const Navbar = styled.nav(
  ({ theme, isExpanded, userLoggedIn, ...props }) => ({
    width: '100%',
    height: 56,
    background: props.masquerade
      ? theme.color.darkPurple
      : theme.color.contrast,
    border: 'none',
    borderBottom: `1px solid ${theme.color.contrast1}`,
    position: 'relative',
    zIndex: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 20px',

    [theme.media.md]: {
      padding: '0 20px',
    },
    [theme.media.lg]: {
      padding: '0 32px',
    },
    [theme.media.xl]: {
      padding: '0 32px',
    },
  }),
);

export const NavItem = styled(TextLink)(({ theme }) => ({
  display: 'none',

  '&& p': {
    height: '56px',
    cursor: 'pointer',
    lineHeight: '56px',
  },

  '&&:hover p': {
    color: theme.color.primary,
    opacity: 1,
  },

  [theme.media.lg]: {
    display: 'initial',
    marginRight: 25,
  },

  [theme.media.xxl]: {
    display: 'initial',
    marginRight: 30,
  },
}));

export const ProjectNavItem = styled(NavItem)(({ theme }) => ({
  [theme.media.lg]: {
    display: 'none',
  },

  [theme.media.xl]: {
    display: 'inline-flex',
  },
}));

export const SearchNavItem = styled(NavItem)(({ theme }) => ({
  marginLeft: 20,

  [theme.media.lg]: {
    marginLeft: 25,
  },

  [theme.media.xxl]: {
    marginLeft: 50,
  },

  p: {
    display: 'flex',
    alignItems: 'center',
  },

  span: {
    marginRight: 12,
  },
}));

export const NavItemsLeft = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover p': {
    color: theme.color.primary,
    opacity: '.5',
  },

  '.projectsPopoverContainer': {
    '&:hover p': {
      opacity: 1,
    },
  },

  '.projectsWrapper': {
    [theme.media.lg]: {
      display: 'none',
    },

    [theme.media.xl]: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
  },
}));

export const CartNavItem = styled(NavLink)(({ theme }) => ({
  display: 'none',
  marginRight: 8,
  textDecoration: 'none',

  p: {
    display: 'flex',
    alignItems: 'center',
  },

  [theme.media.lg]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 8,
  },

  [theme.media.xl]: {
    marginRight: 8,
  },
}));

export const MobileCartNavItem = styled.div(({ theme }) => ({
  display: 'initial',
  marginLeft: 25,
  position: 'relative',
  top: 3,

  [theme.media.lg]: {
    display: 'none',
  },
}));

export const NavItemsRight = styled.div({
  display: 'flex',
  alignItems: 'center',
  [NavItem]: {
    marginRight: 30,
  },
});

export const Divider = styled.div(({ theme }) => ({
  height: 25,
  width: 1,
  backgroundColor: theme.color.contrast3,
  marginRight: 30,
  display: 'none',

  [theme.media.xl]: {
    display: 'initial',
  },
}));

export const Account = styled(Button)(({ theme }) => ({
  display: 'none',

  '& > img': {
    height: 35,
    width: 35,
    borderRadius: '50%',
    marginRight: 5,
    background: theme.color.contrast3,
  },

  '& > span': {
    marginRight: 5,
  },

  [theme.media.lg]: {
    display: 'flex',
    paddingLeft: 12,
  },
}));

export const AccountDesktop = styled(Account)(({ theme }) => ({
  display: 'none',

  [theme.media.lg]: {
    display: 'flex',
    paddingLeft: 0,
  },
}));

export const MenuIconWrapper = styled.div(({ theme }) => ({
  marginRight: 20,
  maxHeight: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  [theme.media.lg]: {
    display: 'none',
  },
}));

export const MenuIcon = styled.div(() => ({
  width: 24,
  height: 15.25,
  position: 'relative',
  transform: 'rotate(0deg)',
  transition: '0.3s ease-in-out',
  cursor: 'pointer',
}));

export const MenuBar = styled.span(({ theme, isMobileMenuOpen }) => ({
  display: 'block',
  position: 'absolute',
  height: 3,
  width: '100%',
  opacity: 1,
  left: 0,
  transform: 'rotate(0deg)',
  transition: '.25s ease-in-out',
  backgroundColor: theme.color.primary,

  ':nth-of-type(1)': {
    top: isMobileMenuOpen ? 12 : 0,
    width: isMobileMenuOpen ? 0 : '100%',
    left: isMobileMenuOpen ? '50%' : 0,
  },
  ':nth-of-type(2)': {
    top: 6,
    transform: isMobileMenuOpen ? 'rotate(45deg)' : 'rotate(0deg)',
  },
  ':nth-of-type(3)': {
    top: 6,
    transform: isMobileMenuOpen ? 'rotate(-45deg)' : 'rotate(0deg)',
  },
  ':nth-of-type(4)': {
    top: 12,
    width: isMobileMenuOpen ? 0 : '100%',
    left: isMobileMenuOpen ? '50%' : 0,
  },
}));

export const SearchLink = styled(Link)(({ theme }) => ({
  display: 'initial',
  marginRight: 0,
  marginTop: 4,
  marginLeft: 25,

  [theme.media.lg]: {
    display: 'none',
  },
}));

export const CartWrapper = styled.div(({ theme }) => ({
  display: 'inline-flex',
  position: 'relative',
  marginLeft: 5,
}));

export const RegisterButton = styled(Button)(({ theme }) => ({
  display: 'none',

  [theme.media.lg]: {
    display: 'initial',
  },
}));

export const NoUserImage = styled.div(({ theme }) => ({
  width: 35,
  height: 35,
  overflow: 'hidden',
  borderRadius: '50%',
  backgroundColor: theme.color.contrast4,
  lineHeight: '35px',
  marginRight: 5,
  marginLeft: 13,
  textAlign: 'center',
}));

export const Overlay = styled.div(({ theme }) => ({
  backgroundColor: theme.color.staticBlack,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  opacity: 0.7,
  zIndex: 9998,
}));
