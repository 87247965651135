import { extractPublicId, buildImageUrl } from 'cloudinary-build-url';

/**
 * Transform a Cloudinary url
 * @param {string} url
 * @param {object} transformations
 * @returns string
 * @see https://cloudinary-build-url.netlify.app/
 */
export default function(url, transformations) {
  if (!url) {
    return url;
  }

  if (!url.includes('cloudinary.com')) {
    return url;
  }

  const publicId = extractPublicId(url);

  return buildImageUrl(publicId, {
    transformations
  });
}
