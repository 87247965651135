const meta = {
  name: 'Filmsupply',
  title: {
    default: 'Filmsupply | Revolutionizing Stock Footage',
    base: 'Stock Video Footage | Filmsupply'
  },
  description: {
    default:
      "With thousands of stunning clips from the world's best filmmakers, Filmsupply is revolutionizing stock footage. Find the perfect clip now."
  },
  og: {
    url: window.location.href,
    app_id: 1487845341514159,
    title: 'Filmsupply | Revolutionizing Stock Footage',
    site_name: 'Filmsupply',
    description:
      "With thousands of stunning clips from the world's best filmmakers, Filmsupply is revolutionizing stock footage. Find the perfect clip now.",
    image:
      'https://filmsupply-public.s3.amazonaws.com/site-assets/images/_social/fb_open-graph-default.jpg',
    type: 'website'
  },
  twitter: {
    card: 'summary_large_image',
    site: '@filmsupply',
    url: window.location.href,
    image:
      'https://filmsupply-public.s3.amazonaws.com/site-assets/images/_social/fb_open-graph-default.jpg',
    title: 'Filmsupply | Revolutionizing Stock Footage',
    description:
      "With thousands of stunning clips from the world's best filmmakers, Filmsupply is revolutionizing stock footage. Find the perfect clip now."
  }
};

export default meta;
