import React from 'react';
import * as Styled from './styles/StyledCreateAccountPopup';
import Icon from 'components/Icon/Icon';
import { T1, P1 } from 'components/Typography/Typography';
import { TextLink } from 'components/Button/Button';
import Auth from 'unstated/Auth';
import { useLocation, useHistory } from 'react-router-dom';
import RegisterForm from 'pages/Register/RegisterForm';
import Modals from 'unstated/Modals';
import Logger from 'utils/logger';
import Cookies from 'universal-cookie';
import FeatureToggles from 'unstated/FeatureToggles';

const CreateAccountPopup = () => {
  const { completeProfileModal } = Modals.useContainer();
  const { toggles } = FeatureToggles.useContainer();
  const [showModal, setShowModal] = React.useState(true);
  const [restrict, setRestrict] = React.useState(false);
  const { user } = Auth.useContainer();
  const location = useLocation();
  const history = useHistory();
  const cookies = new Cookies();
  const mountRef = React.useRef(false);

  const restrictedUrls = [
    '/cart',
    '/cart/success',
    '/pricing',
    '/login',
    '/register',
    '/privacy-policy',
    '/terms-and-conditions',
    '/always-sunny',
  ];

  React.useEffect(() => {
    let isRestricted = false;
    restrictedUrls.forEach((url) => {
      if (location.pathname.includes(url)) {
        isRestricted = true;
      }
    });

    if (
      location.pathname.includes('/clips/') &&
      location.pathname.split('/').length === 4
    ) {
      isRestricted = true;
    }

    setRestrict(isRestricted);
  }, [location.pathname, restrictedUrls]);

  const handleRegisterComplete = () => {
    history.push('/clips');
    completeProfileModal.open();
  };

  const closeModal = () => {
    setShowModal(false);
    Logger({
      event: 'Create Account Popup',
      actionType: 'Hide Popup',
      pageName: location.pathname,
    });
    cookies.set('filmsupply.hideCreateAccountPopup', 'hideCreateAccountPopup', {
      maxAge: 10 * 365 * 24 * 60 * 60,
    });
  };

  if (!restrict && !user.email && showModal) {
    if (
      cookies.get('filmsupply.hideCreateAccountPopup') ||
      !toggles.showCreateAccountPopup
    )
      return null;

    if (!mountRef.current) {
      Logger({
        event: 'Create Account Popup',
        actionType: 'Show Popup',
        pageName: location.pathname,
      });
      mountRef.current = true;
    }

    return (
      <Styled.Container>
        <Icon
          name="close"
          size="xs"
          color="staticBlack"
          css={{ position: 'absolute', top: 17, right: 17, cursor: 'pointer' }}
          onClick={closeModal}
        />
        <T1 color="staticBlack" center bold css={{ margin: '15px 0 3px 0' }}>
          Create Your Account
        </T1>
        <P1 color="staticBlack" center css={{ marginBottom: 15 }}>
          No credit card needed
        </P1>
        <RegisterForm
          buttonText="Create an Account"
          onComplete={handleRegisterComplete}
        />
        <P1 color="staticBlack" center css={{ marginTop: 15, fontSize: 12 }}>
          By creating an account, I agree to Filmsupply's <br />
          <TextLink
            bold
            to="/terms-and-conditions"
            textComponent="P1"
            color="primary"
            hoverColor="accent"
            textComponentStyles={{ fontSize: 12 }}
          >
            Terms
          </TextLink>
          <b> & </b>
          <TextLink
            bold
            to="/privacy-policy"
            textComponent="P1"
            color="primary"
            hoverColor="accent"
            textComponentStyles={{ fontSize: 12 }}
          >
            Privacy Policy
          </TextLink>
        </P1>
      </Styled.Container>
    );
  } else return null;
};

export default CreateAccountPopup;
