import React from 'react';
import { useTheme } from '@emotion/react';
import { Media } from 'react-matches';
import findLastKey from 'lodash/findLastKey';
import { Hr } from 'components/Hr/Hr';
import {
  FooterWrapper,
  Footer as StyledFooter,
  FindFootageWrapper,
  ServicesWrapper,
  BrandsWrapper,
  SubFooterWrapper,
  SubFooterContent,
  SuperDesktopDesktopOrTabletSubFooter,
  SuperDesktopDesktopOrTabletLinksWrapper,
  MobileSubFooter,
  MobileLinksWrapper,
  FooterColumn
} from './styles/StyledFooter';
import Services from './Services';
import { FindFootage } from './find-footage';
import { Brands } from './brands';
import { Copyright } from './copyright';
import { Links } from './links';
import { SocialLinks } from './social-links';

const customQuery = {
  xl: { minWidth: 1280 }
};

const superDesktopSizes = ['xl', 'xxl', 'xxxl'];
const desktopSizes = ['lg'];
const tabletSizes = ['md'];
const mobileSizes = ['sm'];
const tabletAndDesktopSizes = [...tabletSizes, ...desktopSizes];

export default function Footer() {
  const theme = useTheme();
  const queries = { ...theme.queries, ...customQuery };
  const isDarkMode = theme.mode === 'dark';

  return (
    <Media queries={queries}>
      {({ matches }) => {
        const largestSizeKey = findLastKey(matches, isSize => isSize);
        const isMobile = mobileSizes.includes(largestSizeKey);
        const isTabletOrDesktop = tabletAndDesktopSizes.includes(
          largestSizeKey
        );
        const isSuperDesktop = superDesktopSizes.includes(largestSizeKey);
        return (
          <>
            <FooterWrapper>
              <StyledFooter>
                <FindFootageWrapper>
                  <FindFootage isMobile={isMobile} />
                </FindFootageWrapper>
                {!isSuperDesktop && <Hr color="contrast2" />}
                <ServicesWrapper>
                  <Services matches={matches} />
                </ServicesWrapper>
                {!isSuperDesktop && <Hr color="contrast2" />}
                <BrandsWrapper>
                  <Brands />
                </BrandsWrapper>
              </StyledFooter>
            </FooterWrapper>
            {isDarkMode && <Hr color="contrast2" />}
            <SubFooterWrapper>
              <SubFooterContent>
                {(isSuperDesktop || isTabletOrDesktop) && (
                  <SuperDesktopDesktopOrTabletSubFooter>
                    <SuperDesktopDesktopOrTabletLinksWrapper>
                      <FooterColumn>
                        <Copyright />
                      </FooterColumn>
                      <FooterColumn>
                        <Links />
                      </FooterColumn>
                      <FooterColumn>
                        <SocialLinks />
                      </FooterColumn>
                    </SuperDesktopDesktopOrTabletLinksWrapper>
                  </SuperDesktopDesktopOrTabletSubFooter>
                )}

                {isMobile && (
                  <MobileSubFooter>
                    <MobileLinksWrapper>
                      <FooterColumn>
                        <SocialLinks />
                      </FooterColumn>
                      <FooterColumn>
                        <Copyright />
                      </FooterColumn>
                      <FooterColumn>
                        <Links isMobile={isMobile} />
                      </FooterColumn>
                    </MobileLinksWrapper>
                  </MobileSubFooter>
                )}
              </SubFooterContent>
            </SubFooterWrapper>
          </>
        );
      }}
    </Media>
  );
}
