import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import Auth from 'unstated/Auth';
import GlobalStyles from 'components/GlobalStyles/GlobalStyles';

import darkTheme from 'themes/darkTheme';
import lightTheme from 'themes/lightTheme';

const ThemeProvider = ({ children }) => {
  const { user, initialFetchComplete } = Auth.useContainer();
  return (
    <EmotionThemeProvider
      theme={user?.theme === 'dark' ? darkTheme : lightTheme}
    >
      <GlobalStyles />
      {initialFetchComplete && children}
    </EmotionThemeProvider>
  );
};

export default ThemeProvider;
