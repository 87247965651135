import React from 'react';
import PropTypes from 'prop-types';
import Cart from 'unstated/Cart';
import Modals from 'unstated/Modals';
import LicenseBuilder from 'unstated/LicenseBuilder';
import PaymentMethods from 'unstated/PaymentMethods';
import Filters from 'unstated/Filters';
import UnstatedNotification from 'unstated/GlobalNotification';
import Projects from 'unstated/Projects';
import SelectedLicense from 'unstated/SelectedLicense';
import MoreLikeThis from 'unstated/MoreLikeThis';
import KeyCommands from 'unstated/KeyCommands';
import EditFest from 'unstated/EditFest';

const UnstatedProviders = props => (
  <Cart.Provider>
    <PaymentMethods.Provider>
      <Modals.Provider>
        <Filters.Provider>
          <UnstatedNotification.Provider>
            <Projects.Provider>
              <SelectedLicense.Provider>
                <LicenseBuilder.Provider>
                  <MoreLikeThis.Provider>
                    <KeyCommands.Provider>
                      <EditFest.Provider>{props.children}</EditFest.Provider>
                    </KeyCommands.Provider>
                  </MoreLikeThis.Provider>
                </LicenseBuilder.Provider>
              </SelectedLicense.Provider>
            </Projects.Provider>
          </UnstatedNotification.Provider>
        </Filters.Provider>
      </Modals.Provider>
    </PaymentMethods.Provider>
  </Cart.Provider>
);

UnstatedProviders.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default UnstatedProviders;
