import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios from 'hooks/useAxios';
import Auth from 'unstated/Auth';

const usePaymentMethods = () => {
  const { user } = Auth.useContainer();
  const [savedPaymentMethods, setSavedPaymentMethods] = React.useState(null);
  const [paymentMethodsRes, paymentMethodAPI] = useAxios({
    baseURL: '/user/payment_methods'
  });

  const fetchPaymentMethods = React.useCallback(async () => {
    const { data } = await paymentMethodAPI.get();
    setSavedPaymentMethods(data);
  }, [paymentMethodAPI]);

  React.useEffect(() => {
    if (user.email) fetchPaymentMethods();
  }, [fetchPaymentMethods, user.email]);

  return { savedPaymentMethods, fetchPaymentMethods, paymentMethodsRes };
};

export default createContainer(usePaymentMethods);
