import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Text, TextArea as InformedTextArea } from 'informed';

const getBorderColor = ({
  disabled,
  errorMessage,
  isDirty,
  featured,
  inverted,
  theme
}) => {
  if (inverted)
    return theme.color[
      disabled ? 'contrast1' : errorMessage ? 'red' : 'contrast2'
    ];
  else
    return theme.color[
      disabled
        ? 'staticGrey7'
        : errorMessage
        ? 'red'
        : featured
        ? 'staticGrey3'
        : 'staticGrey5'
    ];
};

const getBackgroundColor = ({ inverted, disabled, theme }) => {
  if (inverted) return theme.color.contrast;
  else return theme.color[disabled ? 'staticGrey7' : 'staticWhite'];
};

export const InputWrapper = styled.div(
  {
    position: 'relative',
    padding: '10px 0 15px',

    'input[type=number]::-webkit-inner-spin-button': {
      appearance: 'none'
    },

    'input[type=number]::-webkit-outer-spin-button': {
      appearance: 'none'
    }
  },
  ({ theme, width, maxWidth }) => ({
    width: width ? width : '100%',
    maxWidth: maxWidth ? maxWidth : 'none'
  })
);

export const Label = styled.label({
  fontSize: 12,
  lineHeight: '14px',
  fontWeight: 'normal',
  height: 1,
  display: 'innline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 4px',
  margin: 'none',
  position: 'absolute',
  left: 10,
  top: 34,
  pointerEvents: 'none',
  transitionDuration: '0.15s',

  p: {
    margin: '-6px 0 0'
  }
});

export const Error = styled.p(
  {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 'normal',
    margin: '2px 0 -16px',
    textAlign: 'right'
  },
  ({ theme }) => ({
    color: theme.color.red
  })
);

const Input = styled(Text, {
  shouldForwardProp: prop =>
    prop === 'field' ||
    prop === 'validationSchema' ||
    prop === 'validateOnBlur' ||
    prop === 'validateOnChange' ||
    prop === 'initialValue' ||
    prop === 'height' ||
    prop === 'autocomplete' ||
    isPropValid(prop)
})(({ theme, label, isDirty, featured, errorMessage, disabled, inverted }) => ({
  width: '100%',
  height: 48,
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 15px',
  fontSize: 12,
  lineHeight: '30px',
  fontWeight: 'normal',
  outline: 'none',
  transitionDuration: '0.3s',

  border: `1px solid ${getBorderColor({
    disabled,
    errorMessage,
    isDirty,
    featured,
    inverted,
    theme
  })}`,
  color: theme.color[inverted ? 'primary' : 'staticBlack'],
  backgroundColor: getBackgroundColor({ inverted, disabled, theme }),
  pointerEvents: disabled ? 'none' : 'auto',

  '&::placeholder': {
    color: !label ? theme.color.contrast4 : 'transparent',
    transitionDuration: '0.2s'
  },

  '& + label': {
    top: isDirty ? 10 : 34,
    color:
      theme.color[
        disabled
          ? 'staticGrey4'
          : errorMessage
          ? 'red'
          : inverted
          ? 'primary'
          : 'staticBlack'
      ],
    borderRadius: 3,
    backgroundColor:
      theme.color[
        disabled && !inverted
          ? 'staticGrey7'
          : inverted
          ? 'contrast'
          : 'staticWhite'
      ]
  },

  '&:hover': {
    borderColor: theme.color[errorMessage ? 'red' : 'accent'],

    '& + label': {
      color:
        theme.color[
          errorMessage
            ? 'red'
            : isDirty
            ? 'accent'
            : inverted
            ? 'primary'
            : 'staticBlack'
        ]
    }
  },

  '&:focus': {
    borderColor: theme.color[errorMessage ? 'red' : 'accent'],

    '& + label': {
      top: 10,
      color: theme.color[errorMessage ? 'red' : 'accent']
    },

    '&::placeholder': {
      color: theme.color.contrast4
    }
  }
}));

const StyledTextarea = styled(Input)(({ height }) => ({
  padding: 15,
  minHeight: height ? height : 150,
  lineHeight: '18px'
}));

export const TextInput = Input.withComponent(Text);
export const TextArea = StyledTextarea.withComponent(InformedTextArea);
