import React from 'react';
import { Page } from 'components/Layout/styles/StyledLayout';
import NotFound from 'components/NotFound/NotFound';

const page404 = () => {
  return (
    <Page offsetHeader>
      <NotFound />
    </Page>
  );
};

export default page404;
