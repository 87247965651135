import React from 'react';
import { P1, P2 } from 'components/Typography/Typography';
import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import FeatureToggles from 'unstated/FeatureToggles';

import { StyledLink, FooterColumn } from './styles/StyledFooter';
import { PHONE_NUMBER } from 'constants/contactInfo';

const INITIAL_STATE = {
  thisIsFSToggle: false,
  helpToggle: false,
};

const TOGGLE_THIS_IS_FS = 'TOGGLE_THIS_IS_FS';
const TOGGLE_HELP = 'TOGGLE_HELP';

function optionsReducer(state, action) {
  switch (action.type) {
    case TOGGLE_THIS_IS_FS:
      return { ...state, thisIsFSToggle: !state.thisIsFSToggle };
    case TOGGLE_HELP:
      return { ...state, helpToggle: !state.helpToggle };
    default:
      throw new Error('Not a valid action type');
  }
}

const Dropdown = ({ label, children, onToggle, toggle }) => {
  return (
    <Flex
      align="center"
      column
      onClick={onToggle}
      css={{
        height: toggle ? 'auto' : '20px',
      }}
    >
      <Flex
        justify="space-between"
        css={{ width: '100%', alignItems: 'center' }}
      >
        <P1
          uppercase
          size="14"
          bold
          color="primary"
          weight="600"
          css={{ width: 200 }}
        >
          {label}
        </P1>
        <Icon name="downArrowOutline" color="contrast7" size={7} />
      </Flex>
      <Flex align="start" column shrink={0} css={{ width: '100%' }}>
        {toggle && children}
      </Flex>
    </Flex>
  );
};

const Services = ({ matches }) => {
  const [{ thisIsFSToggle, helpToggle }, dispatch] = React.useReducer(
    optionsReducer,
    INITIAL_STATE,
  );
  const {
    toggles: { enableSiteMap = false },
  } = FeatureToggles.useContainer();

  const toggleThisIsFS = () => dispatch({ type: TOGGLE_THIS_IS_FS });
  const toggleHelp = () => dispatch({ type: TOGGLE_HELP });

  return (
    <React.Fragment>
      {matches.md ? (
        <React.Fragment>
          <FooterColumn>
            <P2 color="primary" bold uppercase css={{ marginBottom: 2 }}>
              This is Filmsupply
            </P2>
            <StyledLink
              id="FooterAboutUs"
              to="/about"
              hoverColor="primary"
              color="contrast4"
              textComponent="P1"
            >
              Our Story
            </StyledLink>
            <br />
            <StyledLink
              id="FooterFilmmakers"
              hoverColor="primary"
              to="/filmmakers"
              color="contrast4"
              textComponent="P1"
            >
              Our Filmmakers
            </StyledLink>
            <br />
            <StyledLink
              id="FooterBlog"
              hoverColor="primary"
              href="https://www.filmsupply.com/articles/"
              target="_blank"
              rel="noopener"
              color="contrast4"
              textComponent="P1"
            >
              Articles
            </StyledLink>
            <br />
            <StyledLink
              id="FooterFilms"
              hoverColor="primary"
              href="https://www.filmsupply.com/films/"
              target="_blank"
              rel="noopener"
              color="contrast4"
              textComponent="P1"
            >
              Films
            </StyledLink>
            <br />
            <StyledLink
              id="footerPopularSearches"
              hoverColor="primary"
              to="/stock-footage"
              color="contrast4"
              textComponent="P1"
            >
              Popular Searches
            </StyledLink>
            {enableSiteMap && (
              <React.Fragment>
                <br />
                <StyledLink
                  hoverColor="primary"
                  to="/category"
                  color="contrast4"
                  textComponent="P1"
                >
                  All Categories
                </StyledLink>
              </React.Fragment>
            )}
          </FooterColumn>
          <FooterColumn>
            <P2 color="primary" bold uppercase css={{ marginBottom: 2 }}>
              Help &amp; Support
            </P2>
            <StyledLink
              id="FooterPricing"
              to="/pricing"
              hoverColor="primary"
              color="contrast4"
              textComponent="P1"
            >
              Pricing
            </StyledLink>
            <br />
            <StyledLink
              id="FooterFAQ"
              hoverColor="primary"
              to="/faq"
              color="contrast4"
              textComponent="P1"
            >
              FAQ
            </StyledLink>
            <br />
            <StyledLink
              id="FooterEmailUs"
              hoverColor="primary"
              href="mailto:support@filmsupply.com"
              color="contrast4"
              textComponent="P1"
            >
              Email Us
            </StyledLink>
            <br />
            <StyledLink
              href={PHONE_NUMBER.HREF}
              hoverColor="primary"
              to="/contact"
              color="contrast4"
              textComponent="P1"
            >
              {PHONE_NUMBER.LOCAL}
            </StyledLink>
            <br />
            <StyledLink
              id="footerContributorApply"
              href="http://apply.filmsupply.com/"
              hoverColor="primary"
              color="contrast4"
              textComponent="P1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Become a Contributor
            </StyledLink>
          </FooterColumn>
        </React.Fragment>
      ) : (
        <>
          <Dropdown
            label="This is Filmsupply"
            toggle={thisIsFSToggle}
            onToggle={toggleThisIsFS}
          >
            <br />
            <StyledLink
              id="FooterAboutUs"
              to="/about"
              hoverColor="primary"
              color="contrast4"
              textComponent="P1"
            >
              Our Story
            </StyledLink>
            <br />
            <StyledLink
              id="FooterFilmmakers"
              hoverColor="primary"
              to="/filmmakers"
              color="contrast4"
              textComponent="P1"
            >
              Our Filmmakers
            </StyledLink>
            <br />
            <StyledLink
              id="FooterBlog"
              hoverColor="primary"
              href="https://www.filmsupply.com/articles/"
              target="_blank"
              rel="noopener"
              color="contrast4"
              textComponent="P1"
            >
              Articles
            </StyledLink>
            <br />
            <StyledLink
              id="FooterFilms"
              hoverColor="primary"
              href="https://www.filmsupply.com/films"
              target="_blank"
              rel="noopener"
              color="contrast4"
              textComponent="P1"
            >
              Films
            </StyledLink>
            <br />
            <StyledLink
              id="footerPopularSearches"
              hoverColor="primary"
              to="/stock-footage"
              color="contrast4"
              textComponent="P1"
            >
              Popular Searches
            </StyledLink>
            {enableSiteMap && (
              <React.Fragment>
                <br />
                <StyledLink
                  hoverColor="primary"
                  to="/category"
                  color="contrast4"
                  textComponent="P1"
                >
                  All Categories
                </StyledLink>
              </React.Fragment>
            )}
            <br />
          </Dropdown>
          <br />
          <br />
          <Dropdown
            label="HELP &amp; SUPPORT"
            toggle={helpToggle}
            onToggle={toggleHelp}
          >
            <br />
            <StyledLink
              id="FooterPricing"
              to="/pricing"
              hoverColor="primary"
              color="contrast4"
              textComponent="P1"
            >
              Pricing
            </StyledLink>
            <br />
            <StyledLink
              id="FooterFAQ"
              hoverColor="primary"
              to="/faq"
              color="contrast4"
              textComponent="P1"
            >
              FAQ
            </StyledLink>
            <br />
            <StyledLink
              id="FooterEmailUs"
              hoverColor="primary"
              href="mailto:support@filmsupply.com"
              color="contrast4"
              textComponent="P1"
            >
              Email Us
            </StyledLink>
            <br />
            <StyledLink
              href={PHONE_NUMBER.HREF}
              hoverColor="primary"
              to="/contact"
              color="contrast4"
              textComponent="P1"
            >
              {PHONE_NUMBER.LOCAL}
            </StyledLink>
            <br />
            <StyledLink
              id="footerContributorApply"
              href="http://apply.filmsupply.com/"
              hoverColor="primary"
              color="contrast4"
              textComponent="P1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Become a Contributor
            </StyledLink>
          </Dropdown>
        </>
      )}
    </React.Fragment>
  );
};

export default Services;
