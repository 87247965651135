import styled from '@emotion/styled';

export const Wrapper = styled.div(({ theme }) => ({
  height: '100%',
  width: '100%',
  minHeight: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'hidden',
  zIndex: 0,
  padding: '0 25px',

  video: {
    width: 'auto',
    height: '100%',
    minHeight: '100%',
    minWidth: '100%',
    objectFit: 'cover'
  },

  [theme.media.lg]: {
    padding: '0 0 0 60px'
  },

  [theme.media.xl]: {
    padding: '0 60px 0 125px'
  }
}));
