import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  position: 'fixed',
  padding: '20px 30px',
  background: theme.color.staticWhite,
  bottom: 35,
  right: 35,
  zIndex: 100,
  width: 345,
  filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.12))',
  [theme.media.mobileOnly]: {
    display: 'none'
  }
}));

export const Content = styled.div(({ theme, close }) => ({
  height: 70,
  opacity: 1,
  paddingBottom: 20,
  overflow: 'hidden',

  ...(close && {
    animation: 'CloseCreateAccountPopup 0.3s ease forwards',
    '@keyframes CloseCreateAccountPopup': {
      '0%': {
        height: 70,
        paddingBottom: 20,
        opacity: 1
      },
      '50%': {
        height: 70,
        paddingBottom: 20,
        opacity: 0
      },
      '100%': {
        height: 0,
        paddingBottom: 0,
        opacity: 0
      }
    }
  })
}));
