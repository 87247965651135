/**
 * Default canonical URL (current URL without query string)
 * @returns
 */
export default function() {
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname.toLowerCase()
  );
}
