import Cookies from 'universal-cookie';
/**
 * Hubspot
 */
const init = code => {
  // Initialize Hubspot tracking
  // NOTE: There is currently no NPM library for the HubSpot tracker
  const hbs_script = document.createElement('script');
  const src = `https://js.hs-scripts.com/${code}.js`;
  hbs_script.src = src;
  document.body.appendChild(hbs_script);

  return hbs_script;
};

const cookies = new Cookies();
export const getCookieData = () => {
  /*
   * Get the Hubspot tracking token
   * NOTE: This will return nothing is ad blockers are active
   */

  const cookie = cookies.get('hubspotutk');
  return cookie;
};

// HubSpot tracking
// https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-apps
const trackPageView = path => {
  if (window._hsq) {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', { path }]);
    _hsq.push(['trackPageView']);
  }
};

const identifyUser = (filmsupply_user_id, email) => {
  if (window._hsq) {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['identify', { filmsupply_user_id, email }]);
  }
};

const Hubspot = {
  init,
  getCookieData,
  trackPageView,
  identifyUser
};
export default Hubspot;
