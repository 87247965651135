import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios from 'hooks/useAxios';

const useLicenseBuilder = () => {
  let [{ data, ...response }, request] = useAxios({
    baseURL: '/cart/licenses/schema'
  });
  let [licenseOptions, setLicenseOptions] = React.useState(null);
  React.useEffect(() => {
    if (response.success) setLicenseOptions(data);
  }, [setLicenseOptions, data, response]);

  let fetchLicenseOptions = React.useCallback(() => request.get(), [request]);

  return { licenseOptions, response, fetchLicenseOptions };
};

export default createContainer(useLicenseBuilder);
