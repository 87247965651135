import styled from '@emotion/styled';

const justifyMap = {
  start: 'flex-start',
  end: 'flex-end',
  between: 'space-between',
  'space-between': 'space-between',
  around: 'space-around',
  'space-around': 'space-around',
  evenly: 'space-evenly',
  'space-evenly': 'space-evenly',
  center: 'center'
};

const alignMap = {
  start: 'flex-start',
  end: 'flex-end',
  between: 'space-between',
  'space-between': 'space-between',
  around: 'space-around',
  'space-around': 'space-around',
  stretch: 'stretch',
  baseline: 'baseline',
  center: 'center'
};

const borderRadiusMap = {
  left: '4px 0 0 4px',
  right: '0 4px 4px 0',
  top: '4px 4px 0 0',
  bottom: '0 0 4px 4px'
};

export const Flex = styled.div(
  ({
    theme,
    inline,
    column,
    reverse,
    justify,
    align,
    wrap,
    grow,
    basis,
    shrink,
    rounded,
    backgroundColor,
    backgroundImage,
    disabled,
    endcap
  }) => ({
    borderRadius: endcap ? borderRadiusMap[endcap] : rounded ? 4 : 0,
    background: backgroundImage
      ? `url('${backgroundImage}') center center no-repeat`
      : 'none',
    backgroundColor: backgroundColor
      ? theme.color[backgroundColor]
      : 'transparent',
    backgroundSize: 'cover',
    display: inline ? 'inline-flex' : 'flex',
    flexDirection:
      column && reverse
        ? 'column-reverse'
        : !column && reverse
        ? 'row-reverse'
        : column
        ? 'column'
        : 'row',
    justifyContent: justifyMap[justify],
    alignItems: alignMap[align],
    flexWrap: wrap ? 'wrap' : 'no-wrap',
    flexGrow: grow,
    flexBasis: basis,
    flexShrink: shrink,
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.3 : 1,
    filter: disabled ? 'blur(3px)' : 'none',
    transitionDuration: '0.3s',
    userSelect: disabled ? 'none' : 'auto',
    ...(disabled && { button: { pointerEvents: 'none', userSelect: 'none' } }),
    ...(disabled && { span: { pointerEvents: 'none', userSelect: 'none' } }),
    ...(disabled && { div: { pointerEvents: 'none', userSelect: 'none' } })
  })
);

Flex.defaultProps = {
  justify: 'start',
  align: 'start',
  grow: 0,
  basis: 'auto',
  shrink: 1
};
