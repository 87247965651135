import React from 'react';
import * as Sentry from '@sentry/react';
import { Switch, withRouter } from 'react-router-dom';
import Route from 'components/Route/Route';
import routes from 'routes';
const SentryRoute = Sentry.withSentryRouting(Route);

const Routes = () => {
  return (
    <Switch>
      {routes.map(route => (
        <SentryRoute
          key={route.name}
          name={route.name}
          component={withRouter(route.component)}
          path={route.path}
          permissions={route.permissions}
          redirect={route.permissionRedirect}
          userConditionals={route.userConditionals}
          {...route.options}
        />
      ))}
    </Switch>
  );
};

export default Routes;
