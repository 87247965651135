import styled from '@emotion/styled';

export const Cell = styled.div(props => ({
  height: '100%',
  minWidth: '0',
  gridColumnEnd: `span ${props.width || 1}`,
  gridRowEnd: `span ${props.height || 1}`,

  ...(props.left && { gridColumnStart: props.left }),
  ...(props.top && { gridRowStart: props.top }),
  ...(props.center && { textAlign: props.center }),
  ...(props.area && { gridArea: props.area }),
  ...(props.middle && {
    display: 'inline-flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    justifySelf: 'stretch'
  })
}));
