import styled from '@emotion/styled';

const Paragraph = styled.p(
  {
    margin: 0,
    padding: 0,
    fontFamily:
      'proxima-nova,Proxima Nova,Helvetica Neue,Helvetica,Arial,sans-serif'
  },
  ({
    theme,
    bold,
    semibold,
    color,
    uppercase,
    center,
    right,
    nowrap,
    truncate,
    italic,
    underline,
    disabled,
    letterSpacing,
    serif
  }) => ({
    fontWeight: bold ? 700 : semibold ? 600 : 'normal',
    fontFamily: serif ? theme.font.serif : theme.font.sans,
    color: disabled ? theme.color.contrast5 : theme.color[color || 'contrast'],
    textTransform: uppercase ? 'uppercase' : 'inherit',
    textAlign: center ? 'center' : right ? 'right' : 'left',
    letterSpacing: letterSpacing ? letterSpacing : 'none',
    whiteSpace: truncate || nowrap ? 'nowrap' : 'inherit',
    overflow: truncate ? 'hidden' : 'inherit',
    textOverflow: truncate ? 'ellipsis' : 'inherit',
    fontStyle: italic ? 'italic' : 'inherit',
    textDecoration: underline ? 'underline' : 'inherit'
  })
);

export const Text = styled(Paragraph)(({ theme, fontSize, lineHeight }) => ({
  fontSize: fontSize || 14,
  lineHeight: lineHeight || 1
}));

export const H1 = styled(Paragraph)(({ theme, fontSize }) => ({
  fontSize: fontSize || 25,
  lineHeight: '27px',

  [theme.media.md]: {
    fontSize: fontSize || 35,
    lineHeight: '42px'
  },

  [theme.media.xxl]: {
    fontSize: fontSize || 45,
    lineHeight: '50px'
  }
}));

export const H2 = styled(Paragraph)(({ theme }) => ({
  fontSize: 26,
  lineHeight: '30px'
}));

export const T1 = styled(Paragraph)({
  fontSize: 20,
  lineHeight: '24px'
});

export const T2 = styled(Paragraph)({
  fontSize: 16,
  lineHeight: '20px'
});

export const P1 = styled(Paragraph)({
  fontSize: 14,
  lineHeight: '18px'
});

export const P2 = styled(Paragraph)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',

  [theme.media.md]: {
    fontSize: 12,
    lineHeight: '14px'
  }
}));

export const P3 = styled(Paragraph)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',

  [theme.media.md]: {
    fontSize: 12,
    lineHeight: '14px'
  },

  [theme.media.lg]: {
    fontSize: 10,
    lineHeight: '12px'
  }
}));

export const P4 = styled(Paragraph)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',

  [theme.media.md]: {
    fontSize: 10,
    lineHeight: '12px'
  }
}));

export const Subtitle = styled.h2(({ theme, color, bold, semibold }) => ({
  margin: 0,
  padding: 0,
  fontWeight: bold ? 700 : semibold ? 600 : 'normal',
  fontFamily:
    'proxima-nova, Proxima Nova, Helvetica Neue, Helvetica, Arial, sans-serif',
  color: theme.color[color || 'contrast'],
  fontSize: 16,
  lineHeight: '20px'
}));
