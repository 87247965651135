import React from 'react';
import { useTheme } from '@emotion/react';
import Auth from 'unstated/Auth';
import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';

const ThemeSwitcher = () => {
  const { user, setTheme } = Auth.useContainer();

  const theme = useTheme();

  const isDarkTheme = user.theme === 'dark';

  return (
    <Flex
      justify="between"
      align="center"
      css={{
        width: '100%',
        borderTop: `1px solid ${theme.color.staticGrey6}`,
        paddingTop: 5
      }}
    >
      <Flex
        justify="center"
        align="center"
        css={{
          width: '50%',
          height: 26,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme.color.staticGrey7
          }
        }}
        onClick={() => (isDarkTheme ? setTheme('light') : null)}
      >
        <Icon
          name={isDarkTheme ? 'lightModeOff' : 'lightModeOn'}
          color={!isDarkTheme ? 'goldenYellow' : 'staticBlack'}
        />
      </Flex>
      <Flex
        justify="center"
        align="center"
        css={{
          width: '50%',
          height: 26,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme.color.staticGrey7
          }
        }}
        onClick={() => (!isDarkTheme ? setTheme('dark') : null)}
      >
        <Icon
          name={isDarkTheme ? 'darkModeOn' : 'darkModeOff'}
          color={isDarkTheme ? 'blue' : 'staticBlack'}
        />
      </Flex>
    </Flex>
  );
};

export default ThemeSwitcher;
