import React, { Fragment } from 'react';
import MetaDecorator from 'components/MetaDecorator/MetaDecorator';
import meta from 'meta';
import PropTypes from 'prop-types';
import { Media } from 'react-matches';
import { useTheme } from '@emotion/react';

import { H1, T1, P2 } from 'components/Typography/Typography';
import Flex from 'components/Flex/Flex';
import Button, { TextLink } from 'components/Button/Button';
import * as Styled from './styles/StyledNotFound';
import useAxios from 'hooks/useAxios';
import Avatar from 'components/Avatar/Avatar';
const ReactPlayer = React.lazy(() => import('react-player'));
const NotFound = props => {
  const theme = useTheme();
  const [clip, fetchClipByID] = useAxios({
    baseURL: '/clips'
  });

  React.useEffect(() => {
    fetchClipByID.get('/211815');
  }, [fetchClipByID]);
  const clipData = clip?.data || {};
  const hasData = clipData.length > 0 || Object.keys(clipData).length > 0;

  return (
    <Fragment>
      <MetaDecorator
        title={`Page Not Found | ${meta.title.base}`}
        statusCode={404}
      />
      <Media queries={theme.queries}>
        {({ matches }) => (
          <Styled.Wrapper>
            <React.Suspense fallback={null}>
              <ReactPlayer
                height="100%"
                width="auto"
                playing
                playsinline
                url={
                  hasData
                    ? clipData.movie.data.public_paths['mp4.wat.h.484.']
                    : undefined
                }
                preload="none"
                loop
                muted
                css={{
                  margin: 'auto',
                  width: 'auto',
                  minWidth: '100%',
                  minHeight: '100%',
                  transform: 'translateX(calc((100% - 100vw) / 2))',
                  height: 'auto',
                  zIndex: 0,
                  position: 'absolute',
                  right: 0
                }}
              />
            </React.Suspense>

            <Flex
              justify="center"
              align={matches.sm ? 'center' : 'start'}
              column
              css={{
                zIndex: 5,
                position: 'relative',
                height: '100%',
                maxWidth: 588
              }}
            >
              <H1 bold color="staticWhite" center={matches.sm ? true : false}>
                {props.message}
              </H1>
              <T1 bold color="staticWhite" css={{ margin: '30px 0 50px' }}>
                {props.smallMessage}
              </T1>
              <Button bold to="/clips">
                Back to Browse
              </Button>
            </Flex>
            <Flex
              align="center"
              justify={matches.sm ? 'center' : 'start'}
              css={{
                position: 'absolute',
                right: 0,
                left: matches.sm ? 0 : 'unset',
                bottom: 30,
                marginRight: matches.sm ? 0 : 59
              }}
            >
              {hasData && (
                <Avatar
                  size="sm"
                  user={clipData.collection.data.filmmaker}
                  css={{ marginRight: 15 }}
                />
              )}

              <Flex column>
                {hasData && (
                  <Fragment>
                    <TextLink
                      textComponent="T2"
                      color="staticWhite"
                      bold
                      to={`/clips/${clipData.slug}/${clipData.id}`}
                    >
                      {clipData.name}
                    </TextLink>
                    <P2 bold color="staticWhite">
                      by {clipData.collection.data.filmmaker.data.name}
                    </P2>
                  </Fragment>
                )}
              </Flex>
            </Flex>
          </Styled.Wrapper>
        )}
      </Media>
    </Fragment>
  );
};

NotFound.propTypes = {
  message: PropTypes.string,
  smallMessage: PropTypes.string
};

NotFound.defaultProps = {
  message: 'The page you are looking for can’t be found...',
  metaTitle: `Page Not Found | ${meta.title.base}`,
  noindex: true,
  smallMessage: 'error 404',
  title: '404'
};

export default NotFound;
