import AuthUnstated from 'unstated/Auth';
import LicenseBuilder from 'unstated/LicenseBuilder';
import useMountEffect from 'hooks/useMountEffect';

// (JH) This component prevents an unnecessary rerender caused by the Auth Unstated Container and Routes component
const Auth = () => {
  const { auth } = AuthUnstated.useContainer();
  const { fetchLicenseOptions } = LicenseBuilder.useContainer();

  useMountEffect(() => {
    auth();
    fetchLicenseOptions();
  });

  return null;
};

export default Auth;
