import React, { Component } from 'react';
import { Form } from 'informed';

const baseForm = (PassedComponent, { values = {} } = {}) => {
  class WithForm extends Component {
    render() {
      return (
        <Form initialValues={values}>
          {({ formApi, formState }) => (
            <PassedComponent
              formApi={formApi}
              formState={formState}
              {...this.props}
            />
          )}
        </Form>
      );
    }
  }

  WithForm.displayName = `WithForm(${getDisplayName(PassedComponent)})`;

  return WithForm;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default baseForm;
