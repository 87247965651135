import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

export const Button = styled.button(
  {
    position: 'relative',
    outline: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    transitionDuration: '0.1s',
    fontFamily: `proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif`,
    whiteSpace: 'nowrap',
    borderRadius: 0
  },
  ({
    theme,
    color,
    outlineColor,
    hoverColor,
    iconHoverColor,
    disabled,
    inverted,
    size,
    bold,
    nopad,
    iconOnly,
    iconColor,
    iconPlacement,
    full,
    outline,
    endcap
  }) => ({
    display: full ? 'flex' : 'inline-flex',
    width: iconOnly ? heightMap[size] : full ? '100%' : 'auto',
    padding: iconOnly || nopad ? 0 : paddingMap[size],
    height: heightMap[size],
    maxHeight: heightMap[size],
    color: getColor({ color, disabled, inverted, outline, theme }),
    backgroundColor: getBackgroundColor({
      color,
      disabled,
      inverted,
      outline,
      theme
    }),
    border: outlineColor || outline ? '1px solid' : 'none',
    borderColor: getBorderColor({
      color,
      disabled,
      inverted,
      outlineColor,
      theme
    }),
    fontSize: fontSizeMap[size],
    fontWeight: bold && size === 'sm' ? 'bold' : bold ? 600 : 'normal',
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
    borderRadius: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
      circle: '50%'
    }[endcap],

    [theme.media.mobileOnly]: {
      height: mobileHeightMap[size]
    },

    span: {
      ...(iconPlacement === 'left' && { marginRight: 10 }),
      ...(iconPlacement === 'right' && { marginLeft: 10 }),
      ...(iconOnly && { margin: 0 })
    },

    '& svg': {
      fill: `${getColor({
        iconColor,
        color,
        disabled,
        inverted,
        outline,
        theme
      })} !important`,
      padding: 0,

      '& path': {
        fill: `${getColor({
          iconColor,
          color,
          disabled,
          inverted,
          outline,
          theme
        })} !important`
      }
    },

    ':hover': {
      color: getHoverColor({
        hoverColor,
        inverted,
        outline,
        theme,
        iconHoverColor
      }),
      backgroundColor:
        theme.color[
          outline && inverted
            ? hoverColor || 'primary'
            : outline
            ? 'transparent'
            : hoverColor || 'lightAccent'
        ],
      borderColor: outline
        ? getBorderHoverColor({ hoverColor, disabled, inverted, theme })
        : 'transparent',

      '& svg': {
        fill: `${getHoverColor({
          hoverColor,
          inverted,
          outline,
          theme,
          iconHoverColor
        })} !important`,

        '& path': {
          fill: `${getHoverColor({
            hoverColor,
            inverted,
            outline,
            theme,
            iconHoverColor
          })} !important`
        }
      }
    }
  })
);

const paddingMap = {
  sm: '0 12px',
  md: '0 15px',
  lg: '0 60px',
  xl: '0 25px',
  xxl: '0 30px'
};

const heightMap = {
  sm: 26,
  md: 36,
  lg: 46,
  xl: 56,
  xxl: 66
};

const mobileHeightMap = {
  sm: 26,
  md: 46,
  lg: 56,
  xl: 56,
  xxl: 66
};

const fontSizeMap = {
  sm: 12,
  md: 14,
  lg: 14,
  xl: 12,
  xxl: 18
};

const getColor = ({ iconColor, color, disabled, inverted, outline, theme }) => {
  if (disabled)
    return theme.color[outline || inverted ? 'contrast4' : 'staticWhite'];
  else if (iconColor) return theme.color[iconColor];
  else if (outline)
    return theme.color[color ? color : inverted ? 'primary' : 'contrast'];
  else if (color && theme.button.contrastGroup.includes(color))
    return theme.color.primary;
  else if (color && theme.button.primaryGroup.includes(color))
    return theme.color.contrast;
  else return theme.color.staticWhite;
};

const getHoverColor = ({
  hoverColor,
  outline,
  inverted,
  theme,
  iconHoverColor
}) => {
  if (iconHoverColor && theme.button.primaryGroup.includes(iconHoverColor))
    return theme.color[iconHoverColor || 'primary'];
  else if (hoverColor && theme.button.contrastGroup.includes(hoverColor))
    return theme.color[outline && !inverted ? hoverColor : 'primary'];
  else if (hoverColor && theme.button.primaryGroup.includes(hoverColor))
    return theme.color[outline && !inverted ? hoverColor : 'contrast'];
  else return theme.color[outline ? 'contrast' : 'staticWhite'];
};

const getBackgroundColor = ({ color, disabled, inverted, outline, theme }) => {
  if (outline) return 'transparent';
  else if (disabled) return theme.color[inverted ? 'contrast2' : 'staticGrey5'];
  else return theme.color[color || 'accent'];
};

const getBorderColor = ({ color, disabled, inverted, outlineColor, theme }) => {
  if (disabled) return theme.color[inverted ? 'contrast4' : 'contrast6'];
  else if (outlineColor) return theme.color[outlineColor];
  else if (color) return theme.color[color];
  else return theme.color.contrast6;
};

const getBorderHoverColor = ({ hoverColor, disabled, inverted, theme }) => {
  if (disabled) return theme.color[inverted ? 'contrast4' : 'contrast6'];
  else if (hoverColor) return theme.color[hoverColor];
  else return theme.color[inverted ? 'primary' : 'lightAccent'];
};

export const Anchor = styled('a', {
  shouldForwardProp: prop => isPropValid(prop)
})(({ theme, color, hoverColor }) => ({
  textDecoration: 'none',
  margin: 0,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  transitionDuration: '0.1s',

  p: {
    color: theme.color[color || 'contrast'],
    transitionDuration: '0.1s'
  },

  '&:hover': {
    p: {
      color: theme.color[hoverColor || 'lightAccent']
    }
  }
}));
