import styled from '@emotion/styled';

import { MouseEventHandler } from 'react';
import lightTheme from 'themes/lightTheme';

const { color: COLORS } = lightTheme;

const sizes = (size: string | number) =>
  ({
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    ml: 24,
    xl: 32,
  })[size] || size;

type IconProps = {
  color: keyof typeof COLORS;
  activeFillColor?: keyof typeof COLORS;
  hover?: keyof typeof COLORS;
  size?: string | number;
  active?: keyof typeof COLORS | false;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  default?: boolean;
};

export const Icon = styled.span<IconProps>(
  ({
    theme,
    color,
    activeFillColor,
    hover,
    size = 'md',
    active = false,
    onClick,
    ...props
  }) => {
    const isActiveFillColor = active && activeFillColor && !hover;
    let fill = theme.color[color || 'contrast'];
    if (isActiveFillColor) {
      fill = theme.color[activeFillColor];
    } else if (hover) {
      fill = theme.color[hover];
    } else if (active) {
      fill = theme.color[active];
    }

    return {
      display: 'inline-flex',
      width: sizes(size),
      height: sizes(size),
      cursor: onClick ? 'pointer' : 'default',
      pointerEvents: onClick ? 'initial' : 'none',
      '& svg': {
        color: theme.color.contrast,
        stroke: 'inherit',
        margin: 0,
        fill:
          active && activeFillColor
            ? theme.color[activeFillColor]
            : theme.color[color || 'contrast'],
        width: 'inherit',
        height: 'inherit',
        '& path': !props.default && {
          transition: 'all .2s ease',
          fill:
            active && activeFillColor
              ? theme.color[activeFillColor]
              : theme.color[color || 'contrast'],
        },
        ':hover': {
          fill: fill,
          '& path': {
            fill: fill,
          },
        },
      },
    };
  },
);
