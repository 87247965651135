import React from 'react';
import { createContainer } from 'unstated-next';

let initialState = {
  type: null,
  data: null,
  name: null
};

function reducer(state, action) {
  switch (action.type) {
    case 'clip': {
      return {
        ...state,
        type: action.type,
        data: action.clip,
        name: action.clip.name
      };
    }
    case 'project': {
      return {
        ...state,
        type: action.type,
        data: action.projectId,
        name: action.projectName
      };
    }
    case 'clear': {
      return {
        ...initialState,
        type: action.type
      };
    }
    default: {
      throw new Error(`${action.type} does not exist`);
    }
  }
}

const useMoreLikeThis = () => {
  let [state, dispatcher] = React.useReducer(reducer, initialState);

  let handleMoreLikeThisProject = React.useCallback(
    (projectName, projectId) => {
      dispatcher({ type: 'project', projectId, projectName });
    },
    []
  );

  let handleMoreLikeThisClip = React.useCallback(clip => {
    dispatcher({ type: 'clip', clip });
  }, []);

  let clearMoreLikeThis = React.useCallback(clip => {
    dispatcher({ type: 'clear' });
  }, []);

  return {
    state,
    clearMoreLikeThis,
    handleMoreLikeThisProject,
    handleMoreLikeThisClip
  };
};

export default createContainer(useMoreLikeThis);
