import Cookies from 'universal-cookie';
import Hubspot from '../helpers/hubspot';

export const cookieConsentInit = () => {
  if (window.CookieConsent) {
    window.CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        analytics: {
          readOnly: false,
          // Note (Maurizio): this feature is nice if you know all the cookies
          // in advance, but with GTM, Hubspot, Impact and so on it's hard.
          // autoClear: {
          //   cookies: [
          //     { name: '__cf_bm' },
          //     { name: '__hssc' },
          //     { name: '__hstc' },
          //     { name: '_cfuvid' },
          //     { name: '_fbp' },
          //     { name: '_gcl_au' },
          //     { name: 'hubspotutk' },
          //     { name: 'li_gc' },
          //     { name: 'lidc' },
          //   ]
          // }
        }
      },
      onConsent: ({cookie}) => {
        if (window.CookieConsent.acceptedCategory('analytics')){
          // Hubspot tracking code
          Hubspot.init('4300863');
        }
      },
      onChange: ({cookie, changedCategories}) => {
        if (changedCategories.includes('analytics')){
          if (window.CookieConsent.acceptedCategory('analytics')) {
            
          } else {
            revokeCookies();
          }
        }
      },
  
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description: 'We use cookies to enhance site navigation and analyze usage. Learn more about our <a href="/privacy-policy" class="cc-link">cookie policy</a>.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'We use cookies',
                  description: 'We use cookies to enhance site navigation and analyze usage. Learn more about our <a href="/privacy-policy" class="cc-link">cookie policy</a>.'
                },
                {
                  title: 'Strictly Necessary cookies',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'More information',
                  description: 'For any queries in relation to our policy on cookies and your choices, please <a href="/contact">contact us</a>'
                }
              ]
            }
          }
        }
      }
    });
  }  
};

export const revokeCookies = () => {
  const cookies = new Cookies();
  const whitelist = ['filmsupply_session', 'XSRF-TOKEN', 'cc_cookie'];
  Object.keys(cookies.getAll()).forEach((cookie) => {
    if (whitelist.some((item) => cookie !== item)) {
      cookies.remove(cookie, { domain: '.filmsupply.com' });
    }
  });
};