import React from 'react';
import PropTypes from 'prop-types';
import lightTheme from '../../themes/lightTheme';
import * as Styled from './styles/StyledTypography';

// Text Designated for marketing pages
export const Text = props => {
  return <Styled.Text {...props} />;
};

export const H1 = props => {
  return <Styled.H1 {...props} />;
};

export const H2 = props => {
  return <Styled.H2 {...props} />;
};

export const T1 = props => {
  return <Styled.T1 {...props} />;
};

export const T2 = props => {
  return <Styled.T2 {...props} />;
};

export const P1 = props => {
  return <Styled.P1 {...props} />;
};

export const P2 = props => {
  return <Styled.P2 {...props} />;
};

export const P3 = props => {
  return <Styled.P3 {...props} />;
};

export const P4 = props => {
  return <Styled.P4 {...props} />;
};

export const Subtitle = props => {
  return <Styled.Subtitle {...props} />;
};

const COLORS = Object.keys(lightTheme.color);

const paragraphPropTypes = {
  children: PropTypes.node,
  /** Specifies if text should have font-weight of 700 */
  bold: PropTypes.bool,
  /** Specifies if text should have font-weight of 600 */
  semibold: PropTypes.bool,
  /** Specifies what color the button should be */
  color: PropTypes.oneOf(COLORS),
  /** Specifies if letters of text should all be uppercase */
  uppercase: PropTypes.bool,
  /** Specifies if text should align center */
  center: PropTypes.bool,
  /** Specifies if text should align right */
  right: PropTypes.bool,
  /** Specifies if text should not wrap */
  nowrap: PropTypes.bool,
  /** Specifies if text should truncate followed by ellipsis */
  truncate: PropTypes.bool,
  /** Specifies if text should be italicised */
  italic: PropTypes.bool,
  /** Specifies if text should be underlined */
  underline: PropTypes.bool,
  /** Specifies if text should muted */
  disabled: PropTypes.bool,
  /** Specifies amount of letter-spacing text should have */
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Specifies font size */
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Specifies lineHeight */
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Specifies element */
  as: PropTypes.string,
  /** Specifies onClick function */
  onClick: PropTypes.func
};

T1.propTypes = {
  ...paragraphPropTypes
};

T2.propTypes = {
  ...paragraphPropTypes
};

P1.propTypes = {
  ...paragraphPropTypes
};

P2.propTypes = {
  ...paragraphPropTypes
};

P3.propTypes = {
  ...paragraphPropTypes
};

P4.propTypes = {
  ...paragraphPropTypes
};
