import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Skeleton from 'components/Skeleton/Skeleton';
import Flex from 'components/Flex/Flex';
import { P1, P2, P3 } from 'components/Typography/Typography';
import { TextLink } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import * as Styled from './styles/StyledMegaNav';
import cloudinaryTransform from 'helpers/cloudinary';

const ReactPlayer = React.lazy(() => import('react-player'));

const MegaNavCollections = ({
  isDarkTheme,
  featured,
  collectionData,
  isVisible
}) => {
  const theme = useTheme();
  const [hovering, setHovering] = useState(false);

  const { collection } = featured.data || {};

  const imgUrl = cloudinaryTransform(
    collection?.trailer_video_data?.preview?.poster,
    {
      resize: {
        height: 156,
        type: 'fit'
      }
    }
  );

  return (
    <Flex
      css={{
        maxWidth: 1000,
        width: '100%',
        marginTop: 40,
        height: 250,
        display: isVisible ? 'flex' : 'none'
      }}
    >
      <Flex column css={{ flexBasis: 343, minWidth: 343 }}>
        <P1 bold color="primary" css={{ paddingBottom: 22 }}>
          Latest Collection
        </P1>
        <Flex
          align="center"
          justify="center"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          css={{
            background: `url(${imgUrl}) center center no-repeat`,
            backgroundSize: 'cover',
            width: '100%',
            height: 156,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          {collection && hovering ? (
            <React.Fragment>
              <React.Suspense fallback={null}>
                <ReactPlayer
                  height="100%"
                  width="auto"
                  playing
                  playsinline
                  url={[collection?.trailer_video_data?.preview?.videos[2]]}
                  preload="none"
                  loop
                  muted
                  css={{
                    margin: 'auto',
                    width: 'auto',
                    maxHeight: '156px',
                    height: '156px',
                    zIndex: 0,
                    position: 'absolute',
                    left: 0
                  }}
                />
              </React.Suspense>
            </React.Fragment>
          ) : !collection ? (
            <Skeleton css={{ paddingBottom: '56.25%' }} />
          ) : null}
          <TextLink
            textComponent="T2"
            color="staticWhite"
            semibold
            uppercase
            hoverColor="staticWhite"
            to={`/collections/${collection?.slug}/${collection?.id}`}
            css={{
              letterSpacing: '1px',
              width: '100%',
              height: '100%',
              zIndex: 1,
              position: 'absolute',
              left: 0
            }}
          >
            {collection?.name}
          </TextLink>
        </Flex>
      </Flex>
      <Flex wrap column css={{ width: '100%', paddingLeft: 70 }}>
        <Flex justify="between" css={{ width: '100%', paddingBottom: 17 }}>
          <P1 bold color="primary">
            Collections
          </P1>
          <Link to="/collections" css={{ textDecoration: 'none' }}>
            <Styled.ExploreAllLink>
              <P2 color="primary" semibold>
                Explore All
              </P2>
              <Icon name="caretRight" color="primary" size={10} />
            </Styled.ExploreAllLink>
          </Link>
        </Flex>
        <Flex
          wrap
          justify="between"
          css={{
            width: '100%',
            borderTop: `1px solid ${theme.color.contrast2}`,
            paddingTop: 22
          }}
        >
          <Flex justify="start" align="start" css={{ width: '100%' }}>
            {collectionData.map((category, index) => (
              <P3
                key={index}
                uppercase
                color="primary"
                bold
                css={{ marginBottom: 12, letterSpacing: '1px', width: '25%' }}
              >
                {category.name}
              </P3>
            ))}
          </Flex>
          <Flex justify="start" align="start" css={{ width: '100%' }}>
            {collectionData.map((category, index) => (
              <Flex
                key={index}
                wrap
                column
                justify="start"
                align="start"
                css={{ width: '25%', maxHeight: 120 }}
              >
                <div css={{ width: '100%' }}>
                  {category.collections.data.map((collection, index) => (
                    <CollectionItem
                      key={`collection-${index}`}
                      name={collection.name}
                      slug={collection.slug}
                      id={collection.id}
                      isDarkTheme={isDarkTheme}
                    />
                  ))}
                </div>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

MegaNavCollections.propTypes = {
  collectionData: PropTypes.array,
  featured: PropTypes.object,
  isVisible: PropTypes.bool,
  isDarkTheme: PropTypes.bool
};

MegaNavCollections.defaultProps = {
  isVisible: false,
  isDarkTheme: false
};

export default MegaNavCollections;

const CollectionItem = ({ name, slug, id, isDarkTheme }) => {
  return (
    <Styled.CollectionItem
      color="contrast4"
      hoverColor="primary"
      textComponent="P1"
      to={`/collections/${slug}/${id}`}
      isDarkTheme={isDarkTheme}
      css={{ width: '100%' }}
      action="replace"
    >
      {name}
    </Styled.CollectionItem>
  );
};

CollectionItem.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isDarkTheme: PropTypes.bool
};

CollectionItem.defaultProps = {
  isDarkTheme: false
};
