import styled from '@emotion/styled';

export const Header = styled.nav(({ theme }) => ({
  width: '100%',
  height: 56,
  background: theme.color.contrast,
  position: 'sticky',
  top: 0,
  zIndex: 9999 /* priority z index. Anything in Header will inherit this z-index over theirs */
}));
