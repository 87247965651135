import React from 'react';
import { createContainer } from 'unstated-next';
import { useBrowseURL } from 'hooks/useBrowseSearch';
import useRouterQuery from 'hooks/useRouterQuery';
import { reduce, filter, difference, chain } from 'lodash-es';

let initialState = {
  type: 'reset',
  filterList: {},
  filters: {},
  pending: {}
};

function filterReducer(state, action) {
  switch (action.type) {
    case 'include-pending':
    case 'exclude-pending':
      let actionTypeKey = action.type.split('-')[0];
      let prevFilterState = state.pending[action.filter.slug].state;

      let pending = {
        ...state.pending,
        [action.filter.slug]: {
          ...action.filter,
          state: prevFilterState === actionTypeKey ? null : actionTypeKey
        }
      };

      return {
        ...state,
        type: action.type,
        pending
      };
    case 'include':
    case 'exclude': {
      let selected = filter(
        state.filters,
        filter =>
          filter.slug === action.filter.slug && filter.state === action.type
      )[0];

      let filters = {
        ...state.filters,
        [action.filter.slug]: { ...action.filter, state: action.type }
      };
      delete filters[selected?.slug];

      return {
        ...state,
        type: action.type,
        filters,
        pending: filters
      };
    }
    case 'remove': {
      return {
        ...state,
        type: action.type,
        filters: state.filters.filter(
          value => value.slug !== action.filter.slug
        )
      };
    }
    case 'mount': {
      let slugs = Object.values(action.filtersInURL)
        .flat()
        .reduce((acc, slug) => {
          let value = null;

          for (let key in action.filtersInURL) {
            let arr = Array.isArray(action.filtersInURL[key])
              ? action.filtersInURL[key]
              : [action.filtersInURL[key]];
            value = arr.includes(slug);
            if (value) {
              value = key;
              break;
            }
          }

          return {
            ...acc,
            [slug]: value
          };
        }, {});

      let filters = reduce(
        slugs,
        (acc, key, slug) => {
          let filterItem = null;
          let filterKey = key.split('no-')[1] || key;
          for (let index in action.filterList) {
            let list = action.filterList[index]?.categories?.data;
            if (list[0]?.name === 'Filmmaker') {
              list = list[0]?.categories?.data;
            } else {
              let listItemIndex = list.findIndex(
                item => item.slug === filterKey
              );
              list = list[listItemIndex]?.categories?.data;
            }

            if (list) {
              let pos = list.findIndex(data => data?.slug === slug);

              if (pos > -1) {
                filterItem = {
                  ...list[pos],
                  state: /no-/.test(key) ? 'exclude' : 'include'
                };
                break;
              }
            }
          }

          if (!filterItem) return acc;

          return {
            ...acc,
            [slug]: filterItem
          };
        },
        {}
      );

      return {
        ...state,
        type: action.type,
        filterList: action.filterList,
        filters,
        pending: filters
      };
    }
    case 'reset': {
      return {
        ...initialState,
        filterList: state.filterList
      };
    }
    case 'save': {
      let filters = chain(state.pending)
        .filter(filter => filter.state)
        .reduce((acc, filter) => {
          return {
            ...acc,
            [filter.slug]: filter
          };
        }, {})
        .value();
      return {
        ...state,
        type: action.type,
        filters,
        pending: filters
      };
    }
    case 'select_user_filter': {
      return {
        ...state,
        type: action.type,
        filters: action.filters,
        pending: action.filters
      };
    }
    default: {
      throw new Error(`${action.type} does not exist`);
    }
  }
}

function useFilters() {
  let [showFilters, setShowFilters] = React.useState(false);
  let [state, dispatcher] = React.useReducer(filterReducer, initialState);

  let toggleFilters = React.useCallback(() => setShowFilters(!showFilters), [
    showFilters
  ]);

  return {
    showFilters,
    toggleFilters,
    setShowFilters,
    dispatcher,
    state
  };
}

let Filters = createContainer(useFilters);

export let useFiltersContainer = () => {
  let {
    state,
    dispatcher,
    setFilters,
    setActiveFilters,
    ...filters
  } = Filters.useContainer();
  let { removeQueryByKeys, removeQuery } = useRouterQuery();
  let { getBrowseQuery, updateBrowseQuery } = useBrowseURL();

  let parseFiltersForURL = React.useCallback(
    (filtersState = state.filters) => {
      return reduce(
        filtersState,
        (acc, value) => {
          let filterKey = `${value.state === 'exclude' ? 'no-' : ''}${
            value.filter
          }`;
          return {
            ...acc,
            [filterKey]: [...(acc[filterKey] || []), value.slug]
          };
        },
        {}
      );
    },
    [state.filters]
  );

  let clearAllFilters = React.useCallback(() => {
    let { filters: filtersInURL } = getBrowseQuery();
    let filterKeys = Object.keys(filtersInURL);
    if (filterKeys.length) removeQueryByKeys(...filterKeys);
    dispatcher({ type: 'reset' });
  }, [dispatcher, getBrowseQuery, removeQueryByKeys]);

  let activeFilterCount = React.useMemo(() => {
    return reduce(
      state.filters,
      (acc, { filter, state }) => {
        return {
          ...acc,
          [filter]: (acc[filter] || 0) + 1
        };
      },
      { total: Object.keys(state.filters).length }
    );
  }, [state.filters]);

  let selectUserFilter = React.useCallback(
    selectedFilter => {
      let { filters: filtersInURL, ...rest } = getBrowseQuery();

      let included = selectedFilter.include.reduce((acc, userFilter) => {
        return {
          ...acc,
          [userFilter.slug]: userFilter
        };
      }, {});

      let excluded = selectedFilter.exclude.reduce((acc, userFilter) => {
        return {
          ...acc,
          [userFilter.slug]: userFilter
        };
      }, {});

      let nextFiltersURL = parseFiltersForURL({ ...included, ...excluded });
      let diff = difference(
        Object.keys(filtersInURL),
        Object.keys(nextFiltersURL)
      );
      if (diff.length) removeQueryByKeys(...diff);
      dispatcher({
        type: 'select_user_filter',
        filters: { ...included, ...excluded }
      });
      updateBrowseQuery({
        ...rest,
        ...parseFiltersForURL({ ...included, ...excluded })
      });
    },
    [
      dispatcher,
      getBrowseQuery,
      parseFiltersForURL,
      removeQueryByKeys,
      updateBrowseQuery
    ]
  );

  let handleFilterClick = (filter, type = 'include') => {
    let { filters: filtersInURL } = getBrowseQuery();
    let filterKey = `${type === 'exclude' ? 'no-' : ''}${filter.filter}`;
    let slugs = Array.isArray(filtersInURL[filterKey])
      ? filtersInURL[filterKey]
      : [filtersInURL[filterKey]].filter(val => val);
    let hasFilter = slugs.includes(filter.slug);

    let newSlugs = hasFilter
      ? slugs.filter(value => value !== filter.slug)
      : [...slugs, filter.slug];

    let oppositeKey = `${type === 'exclude' ? '' : 'no-'}${filter.filter}`;
    removeQuery({ [oppositeKey]: filter.slug });
    updateBrowseQuery({ [filterKey]: newSlugs });

    dispatcher({ type, filter });
  };

  let handleFilterPending = (filter, type = 'include') => {
    dispatcher({ type: `${type}-pending`, filter });
  };

  let handleSaveFilters = () => {
    updateBrowseQuery(parseFiltersForURL(state.pending));
    dispatcher({ type: 'save' });
  };

  let setFiltersOnMount = React.useCallback(
    filterList => {
      let { filters: filtersInURL } = getBrowseQuery();
      dispatcher({ type: 'mount', filterList, filtersInURL });
    },
    [dispatcher, getBrowseQuery]
  );

  React.useEffect(() => {
    if (state.type === 'save') {
      let { filters: filtersInURL, ...rest } = getBrowseQuery();
      let nextFiltersURL = parseFiltersForURL();
      let diff = difference(
        Object.keys(filtersInURL),
        Object.keys(nextFiltersURL)
      );
      if (diff.length) removeQueryByKeys(...diff);
      updateBrowseQuery({ ...rest, ...nextFiltersURL });
    }
  }, [
    getBrowseQuery,
    parseFiltersForURL,
    removeQueryByKeys,
    state.type,
    updateBrowseQuery
  ]);

  React.useEffect(() => {
    let { filters: filtersInURL } = getBrowseQuery();
    if (!Object.keys(filtersInURL).length) {
      dispatcher({ type: 'reset' });
    }
  }, [dispatcher, getBrowseQuery]);

  return {
    ...filters,
    state,
    setActiveFilters,
    activeFilterCount,
    handleFilterClick,
    setFiltersOnMount,
    clearAllFilters,
    handleFilterPending,
    handleSaveFilters,
    selectUserFilter
  };
};

export default Filters;
