import styled from '@emotion/styled';
import { TextLink } from 'components/Button/Button';

export const CollectionItem = styled(TextLink)(({ theme, isDarkTheme }) => ({
  display: 'block',
  marginLeft: -10,
  marginRight: 10,
  width: '50%',
  padding: '5px 10px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  ':hover': {
    background: isDarkTheme ? theme.color.contrast3 : theme.color.contrast1
  }
}));

export const ExploreAllLink = styled.div(({ theme }) => ({
  padding: '5px 10px',
  display: 'flex',
  alignItems: 'center',
  color: theme.color.primary,
  cursor: 'pointer',
  span: {
    marginLeft: 8
  },
  ':hover': {
    backgroundColor: theme.color.contrast1,
    span: {
      transform: 'translate(-3px, 0px)',
      transition: '0.3s ease-in-out'
    }
  }
}));

export const SupportLink = styled.div(() => ({
  padding: '5px 0',
  display: 'flex',
  alignItems: 'center',
  span: {
    marginLeft: 8
  }
}));

export const ContactUsLink = styled(TextLink)(({ theme, isDarkTheme }) => ({
  width: '95%',
  display: 'flex',
  justifyContent: 'start',
  padding: '5px 0',
  marginBottom: 5,
  p: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  ':hover': {
    backgroundColor: isDarkTheme ? theme.color.contrast3 : theme.color.contrast1
  }
}));
