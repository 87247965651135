import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import { Media } from 'react-matches';
import { useTheme } from '@emotion/react';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { T2, P1, P2, P4 } from 'components/Typography/Typography';
import Flex from 'components/Flex/Flex';

import * as StyledCart from './styles/StyledCartPopover';

import Cart from 'unstated/Cart';
import Modals from 'unstated/Modals';
import FeatureToggles from 'unstated/FeatureToggles';

const CartPopover = (props) => {
  const { cart, cartLicenses, popoverOpen } = Cart.useContainer();
  const { licenseEditorModal, removeClipModal } = Modals.useContainer();
  const cartItems = cart?.cart_items?.data || [];
  const cartCount = cartItems.length;
  const total = cart?.total;
  const targetRef = React.useRef();
  const popoverRef = React.useRef();
  const theme = useTheme();
  const [hoverIndex, setHoverIndex] = React.useState(null);
  const { toggles } = FeatureToggles.useContainer();

  React.useEffect(() => {
    if (!popoverOpen && popoverRef.current?.state?.show) {
      popoverRef.current.hideTooltip();
    } else if (popoverOpen) {
      targetRef.current.click();
    }
  }, [popoverOpen]);

  const handleMouseOver = () => targetRef.current.click();
  const handleMouseLeave = () => popoverRef.current.hideTooltip();

  const getLicensesPrice = (cartItemLicenses) => {
    if (!cartItemLicenses) return;

    return cartItemLicenses.data?.reduce(
      (totalLicensesPrice, currentLicense) =>
        totalLicensesPrice + currentLicense.price,
      0,
    );
  };

  return (
    <Media queries={theme.queries}>
      {({ matches }) => (
        <div
          onMouseOver={matches.lg ? handleMouseOver : null}
          onMouseLeave={matches.lg ? handleMouseLeave : null}
        >
          <StyledCart.StyledPopOver
            popOverRef={popoverRef}
            targetRef={targetRef}
            arrowColor={'white'}
            id={props.id ? props.id : 'cart-popover'}
            target={props.children}
            overridePosition={(
              { left, top },
              currentEvent,
              currentTarget,
              node,
            ) => {
              const width = node.getBoundingClientRect().width;
              if (left + width > window.innerWidth) {
                // little hack to move the arrow over when repositioning the tooltip
                const diff = left + width - window.innerWidth;
                node.children[0].innerHTML =
                  node.children[0].innerHTML +
                  `.__react_component_tooltip.place-bottom:after {
          left: calc(50% + ${diff + 10}px);
        }`;
                return {
                  top: popoverOpen ? top + 10 : top,
                  left: left - (left + width - window.innerWidth + 10),
                };
              } else return { top: top - 10, left };
            }}
          >
            <StyledCart.StyledDropdown bgColor="staticWhite">
              <StyledCart.DropdownCell area="subtotal">
                <Flex align="center" justify="start">
                  <Icon name="checkCircle" size="lg" color="accent" />
                  <P1 color="staticBlack" css={{ margin: '5px 0 5px 10px' }}>
                    {cartCount} clip{cartCount !== 1 && 's'} added
                  </P1>
                </Flex>
                <Flex align="center" justify="center">
                  <P4 color="staticBlack" semibold>
                    Total:
                  </P4>
                  <T2 color="staticBlack" semibold css={{ marginLeft: '10px' }}>
                    {currency(total, { formatWithSymbol: true }).format()}
                  </T2>
                </Flex>
              </StyledCart.DropdownCell>
              <StyledCart.DropdownCell area="clip">
                {cartItems.length > 0 &&
                  cartItems.map((item, i) => {
                    let license;

                    cartLicenses.map((lic) => {
                      if (lic?.cart_items?.includes(item)) license = lic;
                      return null;
                    });

                    return (
                      <StyledCart.Item
                        key={item.id}
                        onMouseOver={() => setHoverIndex(i)}
                        onMouseLeave={() => setHoverIndex(null)}
                      >
                        <StyledCart.ItemCell area="image">
                          <img
                            css={{
                              maxWidth: '75px',
                              height: 'auto',
                              paddingRight: '15px',
                            }}
                            src={item.clip.data.movie.data.thumb.paths[480]}
                            alt={item.clip.data.name}
                          />
                          <Flex column justify="center" align="start">
                            <P2
                              color="staticBlack"
                              semibold
                              css={{ margin: '0 0 3px 0' }}
                            >
                              #{item.clip.data.id}
                            </P2>
                            <P4 color="staticBlack" semibold>
                              {item.licenses.data[0].full_name.org_type} License
                            </P4>
                          </Flex>
                        </StyledCart.ItemCell>
                        <StyledCart.ItemCell area="price">
                          {hoverIndex === i ? (
                            <Fragment>
                              <Button
                                size="sm"
                                color="staticGrey6"
                                hoverColor="staticGrey1"
                                css={{ marginRight: 5 }}
                                onClick={
                                  license
                                    ? () =>
                                        licenseEditorModal.open({
                                          data: { license, cartItem: item },
                                        })
                                    : null
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                iconOnly
                                size="sm"
                                color="staticGrey6"
                                hoverColor="darkRed"
                                onClick={() =>
                                  removeClipModal.open({ data: item })
                                }
                              >
                                <Icon name="trash" size={12} />
                              </Button>
                            </Fragment>
                          ) : (
                            <P2 color="staticBlack" semibold>
                              {currency(getLicensesPrice(item.licenses), {
                                formatWithSymbol: true,
                              }).format()}
                            </P2>
                          )}
                        </StyledCart.ItemCell>
                      </StyledCart.Item>
                    );
                  })}
              </StyledCart.DropdownCell>
              <StyledCart.DropdownCell area="checkout">
                <Button
                  color="staticBlack"
                  hoverColor="staticGrey2"
                  full
                  to="/cart"
                  disabled={!cartItems.length}
                >
                  <Icon name="cart" size="lg" />
                  <P2 color="staticWhite" semibold>
                    Checkout
                  </P2>
                </Button>
              </StyledCart.DropdownCell>
              {toggles.enableCartPopoverAd && (
                <Flex css={{ padding: 4 }}>
                  <img
                    src="https://res.cloudinary.com/filmsupply/image/upload/static/CTA/cart-popover/cart-popover-cta.jpg"
                    alt="cart popover ad"
                    width="100%"
                  />
                </Flex>
              )}
            </StyledCart.StyledDropdown>
          </StyledCart.StyledPopOver>
        </div>
      )}
    </Media>
  );
};

CartPopover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default CartPopover;
