import React from 'react';

/**
 * Run a function only once after component mounts
 * @param {closure} fun
 * @returns
 */
export default function(fun) {
  return React.useEffect(fun, []);
}
