import styled from '@emotion/styled';

export const Image = styled.div(({ theme, img }) => ({
  width: 63,
  height: 36,
  borderRadius: 0,
  marginRight: 18,
  backgroundImage: `url(${img})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}));
