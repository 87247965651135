import styled from '@emotion/styled';
import { Checkbox } from 'informed';
export const CheckboxWrapper = styled.div({
  position: 'relative',
  display: 'block',
  width: '14px',
  height: '14px',
  flexShrink: 0
});
export const BaseCheckbox = styled.input(
  ({ theme, color, inverted, excluded, borderColor }) => ({
    position: 'absolute',
    marginLeft: -9999,
    visibility: 'hidden',
    '& + label': {
      cursor: 'pointer',
      position: 'absolute',
      width: '14px',
      height: '14px',
      left: '0px',
      top: '0px',
      margin: '0',
      transition: 'background .1s ease-in-out',
      borderRadius: 0,
      backgroundColor: inverted
        ? theme.color.staticWhite
        : theme.color.contrast,
      border: `1px solid ${
        theme.color[
          borderColor ? borderColor : inverted ? 'staticBlack' : 'primary'
        ]
      }`
    },
    '& + label:before, + label:after': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      content: '""'
    },
    '& + label:before': {
      color: theme.color.orange
    },
    '& + label:after': {
      opacity: '0',
      width: '6px',
      height: '3px',
      background: 'transparent',
      top: 1,
      left: excluded ? 2 : 1,
      transform: excluded ? 'none' : 'rotate(-45deg)',
      transition: 'opacity .1s ease-in-out',
      boxSizing: 'initial',
      border: `2px solid ${theme.color.staticWhite}`,
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: excluded ? 'none' : `2px solid ${theme.color.staticWhite}`
    },
    // Once Selected
    ':checked': {
      '& + label': {
        opacity: '1',
        backgroundColor: theme.color[color || 'accent'],
        border: `2px solid ${theme.color[color || 'accent']}`
      },
      '& + label:after': {
        opacity: '1'
      }
    }
  })
);
export const CheckboxInput = BaseCheckbox.withComponent(Checkbox, {
  shouldForwardProp: prop => prop !== 'inverted'
});
