import React from 'react';

import Auth from 'unstated/Auth';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import PropTypes from 'prop-types';

const ErrorBoundaryUnstated = ({ children }) => {
  const { user } = Auth.useContainer();

  return <ErrorBoundary user={user}>{children}</ErrorBoundary>;
};

ErrorBoundaryUnstated.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundaryUnstated;
