import styled from '@emotion/styled';
import Flex from 'components/Flex/Flex';

export const Avatar = styled(Flex)(({ theme, size }) => ({
  width: heightMap[size],
  height: heightMap[size],
  fontSize: fontSizeMap[size],
  fontWeight: 'bold',
  borderRadius: '50%',
  background: theme.color.accent,
  color: theme.color.staticWhite,
  overflow: 'hidden',
  textAlign: 'center',
}));

const heightMap = {
  sm: 32,
  md: 60,
  lg: 80,
  xl: 100,
};

const fontSizeMap = {
  sm: 16,
  md: 25,
  lg: 30,
  xl: 40,
};
