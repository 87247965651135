import styled from '@emotion/styled';

export const Hr = styled.hr(
  {
    margin: 0,
    borderStyle: 'solid',
    borderBottom: 'none'
  },
  ({ theme, color }) => ({
    borderColor: theme.color[color || 'contrast']
  })
);
