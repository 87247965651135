import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Media } from 'react-matches';
import { useTheme } from '@emotion/react';

import Bubble from 'components/Bubble/Bubble';
import Flex from 'components/Flex/Flex';
import Filmsupply from 'images/Filmsupply.svg?react';
import Icon from 'components/Icon/Icon';
import CartPopover from 'components/PopOver/CartPopover';
import PopOver, { Select, Option } from 'components/PopOver/PopOver';
import Skeleton from 'components/Skeleton/Skeleton';
import ThemeSwitch from 'components/ThemeSwitch/ThemeSwitch';
import NewProjectPopover from 'components/NewProjectPopover/NewProjectPopover';
import MobileAccountMenu from 'components/Header/MobileAccountMenu/MobileAccountMenu';
import FeatureToggles from 'unstated/FeatureToggles';
import * as Styled from './styles/StyledNavbar';
import MegaNav from './MegaNav';

import Cart from 'unstated/Cart';
import Auth from 'unstated/Auth';
import Avatar from 'components/Avatar/Avatar';

import Logger from 'utils/logger';
import { FROM_HEADER_LOGIN } from 'constants/tagManagerEvents';

const Navbar = ({
  handleMobileMenuOpen,
  isMobileMenuOpen,
  onlyLogo,
  featuredCollection,
  collectionData,
}) => {
  const { cart } = Cart.useContainer();
  const { toggles } = FeatureToggles.useContainer();

  const { initialFetchComplete, user, logout, masqueradeLogout } =
    Auth.useContainer();
  const theme = useTheme();
  const [showMegaNav, setShowMegaNav] = useState(null);

  const userLoggedIn = user?.email;
  const cartItems = cart?.cart_items?.data || [];
  const cartCount = cartItems.length;

  const queries = { ...theme.queries, xl2: { minWidth: 1110 } };

  const OverlayPortal = ({ isVisible }) => {
    if (!isVisible) return null;

    return ReactDOM.createPortal(<Styled.Overlay />, document.body);
  };

  const location = useLocation();

  React.useEffect(() => {
    setShowMegaNav(false);
  }, [location]);

  const handleLoginClick = () => {
    Logger({
      event: FROM_HEADER_LOGIN,
      actionType: window.location.href,
      category: 'Product Login',
    });
  };

  return (
    <Media queries={queries}>
      {({ matches }) => (
        <Fragment>
          <OverlayPortal isVisible={showMegaNav} />
          {/* Shows loading state using Skeleton components until user request returns */}
          {!initialFetchComplete ? (
            <Styled.Navbar>
              <Styled.NavItemsLeft>
                <Flex align="center">
                  <NavLink aria-label="Home" to="/" css={{ height: '17px' }}>
                    <Filmsupply
                      css={{ width: 110, fill: theme.color.primary }}
                    />
                  </NavLink>
                </Flex>

                <Flex align="center">
                  <Skeleton
                    css={{
                      marginLeft: 30,
                      marginRight: 25,
                      width: 70,
                      paddingBottom: '8%',
                    }}
                  />
                  <Skeleton
                    css={{ marginRight: 25, width: 70, paddingBottom: '8%' }}
                  />
                  <Skeleton
                    css={{ marginRight: 25, width: 70, paddingBottom: '8%' }}
                  />
                </Flex>
              </Styled.NavItemsLeft>

              <Styled.NavItemsRight>
                <Fragment>
                  <Skeleton
                    css={{ marginRight: 25, width: 70, paddingBottom: '5%' }}
                  />
                  <Skeleton
                    css={{ marginRight: 25, width: 70, paddingBottom: '5%' }}
                  />
                  <Skeleton
                    css={{ marginRight: 25, width: 70, paddingBottom: '5%' }}
                  />
                </Fragment>

                <Skeleton
                  css={{ marginRight: 25, width: 70, paddingBottom: '5%' }}
                />
                <Styled.Divider />
                <Skeleton css={{ width: 70, paddingBottom: '8%' }} />
              </Styled.NavItemsRight>
            </Styled.Navbar>
          ) : (
            // Actual Navbar displays after user data loads
            <Styled.Navbar
              userLoggedIn={userLoggedIn}
              masquerade={user.masquerade}
            >
              <Styled.NavItemsLeft>
                <Styled.MenuIconWrapper>
                  <Styled.MenuIcon
                    onClick={() => handleMobileMenuOpen(!isMobileMenuOpen)}
                  >
                    <Styled.MenuBar isMobileMenuOpen={isMobileMenuOpen} />
                    <Styled.MenuBar isMobileMenuOpen={isMobileMenuOpen} />
                    <Styled.MenuBar isMobileMenuOpen={isMobileMenuOpen} />
                    <Styled.MenuBar isMobileMenuOpen={isMobileMenuOpen} />
                  </Styled.MenuIcon>
                </Styled.MenuIconWrapper>

                <Flex align="center">
                  <NavLink
                    id={!userLoggedIn && 'headerFSLogo'}
                    aria-label="home"
                    to="/"
                    css={{ height: '17px' }}
                  >
                    <Filmsupply
                      css={{ width: 115, fill: theme.color.primary }}
                    />
                  </NavLink>
                </Flex>
                {!onlyLogo && (
                  <Flex align="center">
                    <Styled.SearchNavItem
                      id={!userLoggedIn && 'headerSearch'}
                      aria-label="search"
                      textComponent="P1"
                      to="/clips"
                      color="primary"
                      semibold
                      onMouseEnter={() => {
                        if (matches.xl) {
                          setShowMegaNav(null);
                        }
                      }}
                    >
                      <Icon name="search" size="xs" color="primary" />
                      Search
                    </Styled.SearchNavItem>
                    <Styled.NavItem
                      id={!userLoggedIn && 'headerCollections'}
                      aria-label="collections"
                      textComponent="P1"
                      to="/collections"
                      color="primary"
                      semibold
                      onMouseEnter={() => {
                        if (matches.xl) {
                          setShowMegaNav('collections');
                        }
                      }}
                    >
                      Collections
                    </Styled.NavItem>
                    <Fragment>
                      <Styled.NavItem
                        id={!userLoggedIn && 'headerPricing'}
                        textComponent="P1"
                        to="/pricing"
                        color="primary"
                        semibold
                        onMouseEnter={() => {
                          if (matches.xl) {
                            setShowMegaNav(null);
                          }
                        }}
                      >
                        Pricing
                      </Styled.NavItem>
                      {matches.xl ? (
                        <Styled.NavItem
                          id={!userLoggedIn && 'headerServices'}
                          textComponent="P1"
                          to="/services"
                          color="primary"
                          semibold
                          onMouseEnter={() => {
                            if (matches.xl) {
                              setShowMegaNav(null);
                            }
                          }}
                        >
                          Services
                        </Styled.NavItem>
                      ) : null}
                    </Fragment>
                  </Flex>
                )}
              </Styled.NavItemsLeft>
              {!onlyLogo && (
                <Styled.NavItemsRight>
                  {userLoggedIn && (
                    <Fragment>
                      {matches.lg ? (
                        <div>
                          <Styled.ProjectNavItem
                            id="headerMyProjects"
                            textComponent="P1"
                            to="/projects"
                            color="primary"
                            semibold
                            onMouseEnter={() => {
                              if (matches.xl) {
                                setShowMegaNav(null);
                              }
                            }}
                          >
                            My Projects
                          </Styled.ProjectNavItem>
                          <div
                            css={{ height: 0, marginRight: 30 }}
                            onMouseEnter={() => {
                              if (matches.xl) {
                                setShowMegaNav(null);
                              }
                            }}
                          >
                            <NewProjectPopover id="newProjectDesktopId" />
                          </div>
                        </div>
                      ) : (
                        <Styled.ProjectNavItem
                          id="headerMyProjects"
                          textComponent="P1"
                          to="/projects"
                          color="primary"
                          semibold
                          onMouseEnter={() => {
                            if (matches.xl) {
                              setShowMegaNav(null);
                            }
                          }}
                        >
                          My Projects
                        </Styled.ProjectNavItem>
                      )}
                    </Fragment>
                  )}
                  {cartCount > 0 && (
                    <CartPopover id="cart-popover1">
                      <Flex
                        justify="center"
                        align="center"
                        css={{
                          marginRight: 30,
                          display: !matches.lg ? 'none' : 'flex',
                        }}
                        onMouseEnter={() => {
                          if (matches.xl) {
                            setShowMegaNav(null);
                          }
                        }}
                      >
                        <Styled.CartNavItem
                          id={userLoggedIn && 'headerCart'}
                          to="/cart"
                        >
                          <Styled.CartWrapper css={{ pointerEvents: 'none' }}>
                            <Icon name="cart" size="xs" color="primary" />
                            <Bubble cart count={cartCount} />
                          </Styled.CartWrapper>
                        </Styled.CartNavItem>
                      </Flex>
                    </CartPopover>
                  )}
                  {(userLoggedIn || cartCount > 0) && <Styled.Divider />}
                  {userLoggedIn ? (
                    <PopOver
                      id="account-pop-over"
                      target={
                        <Styled.AccountDesktop
                          iconColor="contrast3"
                          size="lg"
                          nopad
                          color={user.masquerade ? 'purple' : 'contrast'}
                          hoverColor={'transparent'}
                          onMouseEnter={() => {
                            if (matches.xl) {
                              setShowMegaNav(null);
                            }
                          }}
                          css={{ height: 45, maxHeight: 45 }}
                        >
                          <Avatar user={user} />
                        </Styled.AccountDesktop>
                      }
                    >
                      <Select
                        css={{ zIndex: 5, right: 0, top: 7 }}
                        bgColor="staticWhite"
                      >
                        <Option as={Link} to={`/account/details`}>
                          Account
                        </Option>
                        <Option
                          id="headerMyProjects"
                          as={Link}
                          to={`/projects`}
                        >
                          My Projects
                        </Option>
                        <Option as={Link} to={`/account/licenses`}>
                          My Licenses
                        </Option>
                        {user.isAmbassador && (
                          <Option
                            as="a"
                            href={`https://app.impact.com/abe/FM/login.user`}
                          >
                            Affiliates
                          </Option>
                        )}

                        {user.masquerade ? (
                          <Option onClick={masqueradeLogout}>
                            <Icon name="close" size="xs" color="purple" />{' '}
                            <span
                              css={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: 70,
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                marginLeft: 5,
                              }}
                            >
                              {user.masquerade.client_username}
                            </span>
                          </Option>
                        ) : (
                          <Option onClick={logout}>Logout</Option>
                        )}
                        <ThemeSwitch />
                      </Select>
                    </PopOver>
                  ) : (
                    <Fragment>
                      <Styled.NavItem
                        id="headerLogin"
                        textComponent="P1"
                        to="/login"
                        color="primary"
                        semibold
                        onClick={handleLoginClick}
                        onMouseEnter={() => {
                          if (matches.xl) {
                            setShowMegaNav(null);
                          }
                        }}
                      >
                        Log In
                      </Styled.NavItem>

                      {toggles?.homeCTAsToCreateFreeAccount ? (
                        <Styled.RegisterButton
                          id="headerCreateAccount"
                          aria-label="register"
                          to="/register"
                          size="md"
                          semibold
                          onMouseEnter={() => {
                            if (matches.xl) {
                              setShowMegaNav(null);
                            }
                          }}
                        >
                          Create Free Account
                        </Styled.RegisterButton>
                      ) : (
                        <Styled.RegisterButton
                          aria-label="register"
                          to="/register"
                          size="md"
                          semibold
                          onMouseEnter={() => {
                            if (matches.xl) {
                              setShowMegaNav(null);
                            }
                          }}
                        >
                          Create Account
                        </Styled.RegisterButton>
                      )}
                    </Fragment>
                  )}

                  {matches.lg && cartCount > 0 && (
                    <Styled.MobileCartNavItem>
                      <CartPopover id="cart-popover2">
                        <Styled.CartWrapper>
                          <Icon name="cart" size="lg" color="primary" />
                          <Bubble cart count={cartCount} />
                        </Styled.CartWrapper>
                      </CartPopover>
                    </Styled.MobileCartNavItem>
                  )}

                  {!matches.lg && (
                    <Fragment>
                      {cartCount > 0 && (
                        <Styled.SearchLink
                          aria-label="search"
                          to="/clips"
                          onMouseEnter={() => {
                            if (matches.xl) {
                              setShowMegaNav(null);
                            }
                          }}
                        >
                          <Icon name="search" size="lg" color="primary" />
                        </Styled.SearchLink>
                      )}

                      {cartCount > 0 ? (
                        <Styled.MobileCartNavItem css={{ paddingRight: 8 }}>
                          <CartPopover id="cart-popover2">
                            <Styled.CartWrapper>
                              <Icon name="cart" size="lg" color="primary" />
                              <Bubble cart count={cartCount} />
                            </Styled.CartWrapper>
                          </CartPopover>
                        </Styled.MobileCartNavItem>
                      ) : null}

                      {userLoggedIn ? (
                        <MobileAccountMenu
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleOffMobileMenu={() =>
                            handleMobileMenuOpen(false)
                          }
                        />
                      ) : cartCount === 0 ? (
                        <Styled.RegisterButton
                          aria-label="register"
                          to="/register"
                          size="md"
                          semibold
                          css={{ display: 'initial' }}
                        >
                          Create Free Account
                        </Styled.RegisterButton>
                      ) : null}
                    </Fragment>
                  )}
                </Styled.NavItemsRight>
              )}
            </Styled.Navbar>
          )}
          {matches.xl && (
            <MegaNav
              showMegaNav={showMegaNav}
              setShowMegaNav={setShowMegaNav}
              featuredCollection={featuredCollection}
              collectionData={collectionData}
            />
          )}
        </Fragment>
      )}
    </Media>
  );
};

Navbar.propTypes = {
  handleMobileMenuOpen: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  onlyLogo: PropTypes.bool,
  featuredCollection: PropTypes.object.isRequired,
};

export default Navbar;
