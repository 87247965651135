import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const skeletonStyles = theme =>
  css({
    backgroundColor: `${theme.color.contrast1}`,
    backgroundImage: `linear-gradient(90deg, ${theme.color.contrast1}, ${theme.color.contrast2}, ${theme.color.contrast1})`,
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: 0,
    display: 'inline-block',
    lineHeight: '1',
    width: '100%',
    animation: `${theme.keyframes.skeleton} 1.2s ease-in-out infinite`
  });

const Skeleton = styled.div(({ theme }) => ({
  ...skeletonStyles(theme)
}));
export default Skeleton;
