import styled from '@emotion/styled';

export const Bubble = styled.div(({ theme, cart, countLength }) => ({
  width: countLength === 3 ? 30 : countLength === 2 ? 28 : 18,
  height: 18,
  borderRadius: 40,
  position: 'absolute',
  top: -10,
  right: -10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: cart ? theme.color.blue : theme.color.accent,
  color: theme.color.staticWhite,
  fontWeight: 'bold',
  fontSize: 12,
  lineHeight: 1.2,

  ...(cart && {
    width: countLength === 3 ? 24 : countLength === 2 ? 22 : 14,
    height: 14,
    fontSize: 10
  })
}));
