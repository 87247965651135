import styled from '@emotion/styled';
import lightTheme from 'themes/lightTheme';
const { color: COLORS } = lightTheme;

export const modalStyles = (props) => ({
  content: {
    padding: props.noPad ? 0 : `${props.MARGIN * 2}px 0`,
    margin: 0,
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    position: 'relative',
    maxHeight: props.fullScreenMobile ? '100vh' : 'calc(100vh - 100px)',
    overflow: props.visibleOverflow ? 'visible' : 'scroll',
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    background: lightTheme.color.staticWhite,
    boxShadow: props.theme?.shadow.depth1,
    scrollbarWidth: 'none',
    '::WebkitScrollbar': {
      width: 0,
      background: 'transparent',
    },

    ...(props.fullScreenMobile && { height: '100vh', width: '100vw' }),

    ...(props.alignTop && { marginTop: 20 }),

    div: {
      maxWidth: '70vw',
      maxHeight: '50vh',
      overflow: 'auto',
    },

    //-- Because we possibly want to overwrite any existing styles... Make sure any new styling is done above spreading props.
    ...props.contentStyles,
  },
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: props.alignTop ? 'flex-start' : 'center',
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0,0.8)',
    backdropFilter: 'blur(3px)',
    //-- Because we possibly want to overwrite any existing styles... Make sure any new styling is done above spreading props.
    ...props.overlayStyles,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

type CloseButtonProps = {
  padding: string | number;
  closeButtonColor: string | undefined;
  inverted?: boolean;
};
export const CloseButton = styled.div<CloseButtonProps>(
  ({ theme, padding, closeButtonColor, inverted = false }) => {
    let backgroundThemeColor = 'staticWhite';
    if (closeButtonColor) {
      backgroundThemeColor = closeButtonColor;
    } else if (inverted) {
      backgroundThemeColor = 'staticBlack';
    }
    return {
      position: 'absolute',
      display: 'inline-flex',
      top: 5,
      right: 5,
      padding,
      background: theme.color[backgroundThemeColor],
      border: 'none',
      cursor: 'pointer',
      zIndex: 10,
      borderRadius: 0,
    };
  },
);

export default modalStyles;
