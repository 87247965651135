import React from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

function useRouterQuery() {
  let history = useHistory();

  let getQueryParams = React.useCallback(() => {
    return qs.parse(history.location.search, {
      sort: false,
      parseBooleans: true,
      parseNumbers: true
    });
  }, [history.location.search]);

  let updateQuery = React.useCallback(
    (
      { pathname = history.location.pathname, search, hash },
      replace = true
    ) => {
      history.push({
        pathname,
        search: qs.stringify(
          replace ? search : { ...getQueryParams(), ...search },
          { sort: false, skipNull: true }
        ),
        hash
      });
    },
    [getQueryParams, history]
  );

  let removeQuery = React.useCallback(
    query => {
      // TODO (JMC) Clean up below by setting things to null or undefined 👉 https://www.npmjs.com/package/query-string#falsy-values (I wish I would have found this a long time ago!!!)
      let queryString = qs.stringify(query);
      let regex = new RegExp(
        `(?!^\\?)(${queryString}&)|(\\??&?${queryString}$)`
      );
      let newSearch = history.location.search.replace(regex, '');
      history.push({ ...history.location, search: newSearch });
    },
    [history]
  );

  let removeQueryByKeys = React.useCallback(
    (...queryKeys) => {
      let search = qs.parse(history.location.search);
      queryKeys.forEach(q => delete search[q]);
      history.push({ ...history.location, search: qs.stringify(search) });
    },
    [history]
  );

  return {
    getQueryParams,
    history,
    updateQuery,
    removeQuery,
    removeQueryByKeys
  };
}

export default useRouterQuery;
