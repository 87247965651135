import React, { Fragment, ReactNode } from 'react';
import ReactModal from 'react-modal';

import Icon from 'components/Icon/Icon';
import modalStyles, { CloseButton } from './styles/StyledModal';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

function getParent() {
  return document.querySelector('#root');
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  closeButtonColor?: string;
  contentStyles?: Record<string, string>;
  overlayStyles?: Record<string, string>;
  size: string;
  background?: string;
  noPad?: boolean;
  noCloseButton: boolean;
  inverted: boolean;
  className?: string;
  noForeignClick?: boolean;
};
const Modal = ({
  children,
  inverted,
  closeButtonColor,
  noCloseButton,
  isOpen,
  onClose,
  className,
  noPad,
  noForeignClick = false
}: Props) => {
  const MARGIN = noPad ? 12 : 20;

  return (
    <Fragment>
      <div>
        <ReactModal
          isOpen={isOpen}
          onClose={onClose}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick={noForeignClick ? false : true}
          style={modalStyles({ MARGIN })}
          parentSelector={getParent}
          className={className}
        >
          {children}

          {!noCloseButton && (
            <CloseButton
              role="button"
              onClick={onClose}
              closeButtonColor={closeButtonColor}
              padding={MARGIN}
            >
              <Icon
                name="close"
                size={12}
                color={inverted ? 'staticWhite' : 'staticBlack'}
              />
            </CloseButton>
          )}
        </ReactModal>
      </div>
    </Fragment>
  );
};

export default Modal;
