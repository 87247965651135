import React from 'react';
import lightTheme from 'themes/lightTheme';
import { NavLink } from 'react-router-dom';
import * as icons from './icons';

import * as Styled from './styles/StyledIcon';

const ICON_LIST = ['creditCard', 'Checkbox'];

const { color: COLORS } = lightTheme;

type IconProps = {
  /** The name of the icon - reference ./src/icons for options */
  name: keyof typeof icons;
  /** Indicates the icon color - reference lightTheme.js for options */
  color: keyof typeof COLORS;
  /** Indicates the hover color of the icon */
  hover?: keyof typeof COLORS;
  /** Indicates whether the icon is active when used in navigation */
  active?: keyof typeof COLORS | false;
  /** Indicates the active color of the icon when used in navigation */
  activeFillColor?: keyof typeof COLORS;
  /** Defines the size in px of the icon (sets the width and maxHeight)*/
  size?: string | number;
  /** Page to navigate to when clicked */
  to?: string;
  /** Web link to navigate to when clicked */
  href?: string;
  /** Specifies how the external link should be opened */
  target?: '_blank' | '_self' | '_top' | '_parent';

  width?: string | number;
};

const Icon = ({
  name,
  to,
  href,
  target,
  color,
  hover,
  activeFillColor,
  size,
  ...restProps
}: IconProps) => {
  const Component = icons[name || 'help'];

  if (to) {
    return (
      <NavLink to={to}>
        <Styled.Icon
          {...restProps}
          color={color}
          hover={hover}
          activeFillColor={activeFillColor}
          size={size}
          default
        >
          <Component />
        </Styled.Icon>
      </NavLink>
    );
  }

  if (href) {
    return (
      <a href={href} target={target}>
        <Styled.Icon
          {...restProps}
          color={color}
          hover={hover}
          activeFillColor={activeFillColor}
          size={size}
          default
        >
          <Component />
        </Styled.Icon>
      </a>
    );
  }

  return (
    <Styled.Icon
      {...restProps}
      color={color}
      hover={hover}
      activeFillColor={activeFillColor}
      size={size}
      default={ICON_LIST.includes(name as string)}
    >
      <Component />
    </Styled.Icon>
  );
};

export default Icon;
