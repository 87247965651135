import styled from '@emotion/styled';
import { Button } from 'components/Button/styles/StyledButton';
import { TextLink } from 'components/Button/Button';
import { Hr } from 'components/Hr/styles/StyledHr';
import { P2, H2 } from 'components/Typography/styles/StyledTypography';

export const Footer = styled.div({ margin: 'auto' });
export const FindFootageWrapper = styled.div();
export const ServicesWrapper = styled.div();
export const FooterColumn = styled.section();
export const BrandsWrapper = styled.div();

export const FooterWrapper = styled.div(({ theme }) => {
  const {
    media: { sm, md },
  } = theme;
  const customXl = 1280;
  const xl = `@media only screen and (min-width: ${customXl}px)`;
  return {
    backgroundColor: theme.color.contrast,
    [sm]: {
      padding: '70px 20px 50px',
      [Footer]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [Hr]: {
          margin: '50px 0',
          width: '100%',
        },
      },
      [FindFootageWrapper]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [H2]: {
          maxWidth: 225,
          textAlign: 'center',
        },
        [P2]: {
          margin: '25px 0',
          textAlign: 'center',
          strong: {
            textDecoration: 'underline',
          },
        },
        [Button]: {
          maxWidth: 200,
        },
      },
      [ServicesWrapper]: {
        padding: '0 30px',
        width: '100%',
      },
      [BrandsWrapper]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 30px 50px',
        width: '100%',
        [FooterColumn]: {
          width: '100%',
          ':first-of-type': {
            marginBottom: 50,
            a: { maxWidth: 220 },
          },
          ':last-of-type': {
            strong: {
              textTransform: 'uppercase',
              display: 'block',
              marginBottom: 5,
            },
          },
          ':not(:first-of-type) > div:not(:last-of-type)': {
            marginBottom: 25,
          },
        },
      },
    },
    [md]: {
      padding: '70px 32px 60px',
      [Footer]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [Hr]: {
          margin: '50px 0',
          width: '100%',
        },
      },
      [FindFootageWrapper]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [H2]: { maxWidth: 'unset' },
        [Button]: { minHeight: 36 },
        [P2]: {
          margin: '25px 0',
          strong: { textDecoration: 'underline' },
        },
      },
      [ServicesWrapper]: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 50px',
        [FooterColumn]: {
          flexGrow: 1,
          flexBasis: '50%',
          justifyContent: 'center',
          ':first-of-type': { marginRight: 60 },
          ':last-of-type': { marginLeft: 60 },
        },
      },
      [BrandsWrapper]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 50px',
        [FooterColumn]: {
          width: '100%',
          flexGrow: 1,
          ':first-of-type': {
            marginRight: 60,
            marginBottom: 'unset',
            a: { maxWidth: 190 },
          },
          ':last-of-type': {
            marginLeft: 60,
          },
          ':not(:first-of-type) > div:not(:last-of-type)': {
            marginBottom: 25,
          },
        },
      },
    },
    [xl]: {
      padding: '70px 0 90px',
      [Footer]: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        maxWidth: 1200,
      },
      [FindFootageWrapper]: {
        minWidth: 'unset',
        alignItems: 'unset',
        flexBasis: '25%',
        [H2]: {
          maxWidth: 300,
          textAlign: 'unset',
        },
        [P2]: {
          margin: '25px 0',
          textAlign: 'unset',
          strong: {
            textDecoration: 'underline',
          },
        },
        [Button]: {
          width: 200,
        },
      },
      [ServicesWrapper]: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        padding: 'unset',
        flexBasis: '45%',
        [FooterColumn]: {
          flexBasis: 'unset',
          flexGrow: 'unset',
          ':first-of-type': {
            marginRight: 'unset',
          },
          ':last-of-type': {
            marginLeft: 'unset',
          },
        },
      },
      [BrandsWrapper]: {
        padding: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        flexBasis: '20%',
        [FooterColumn]: {
          width: 220,
          marginLeft: 0,
          marginRight: 0,
          ':first-of-type': {
            marginBottom: 25,
            marginRight: 'unset',
          },
          ':last-of-type': {
            marginBottom: 25,
            marginLeft: 'unset',
          },
          ':not(:first-of-type) > div:not(:last-of-type)': {
            marginBottom: 25,
          },
        },
      },
    },
  };
});

export const StyledLink = styled(TextLink)(({ noMargin }) => ({
  marginTop: noMargin ? 0 : 12,
}));

export const SubFooterWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color.staticBlack,
  padding: '0 32px',
}));

export const SubFooterContent = styled.div(({ theme }) => ({
  color: theme.color.staticWhite,
  maxWidth: 1200,
  margin: 'auto',
}));

export const SuperDesktopDesktopOrTabletSubFooter = styled.div({
  height: 60,
  display: 'flex',
});

export const SuperDesktopDesktopOrTabletLinksWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  [FooterColumn]: {
    ':nth-of-type(1)': {
      marginRight: 20,
      display: 'flex',
      p: { whiteSpace: 'nowrap' },
    },
    ':nth-of-type(2)': {
      display: 'flex',
      a: {
        marginRight: 20,
        display: 'flex',
        p: {
          textAlign: 'center',
        },
      },
    },
    ':nth-of-type(3)': {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    ':nth-of-type(3) > div': {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 180,
      a: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
      },
    },
  },
});

export const MobileSubFooter = styled.div({
  minHeight: 120,
});

export const MobileLinksWrapper = styled.div({
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  [FooterColumn]: {
    ':nth-of-type(1)': {
      marginTop: 20,
      width: 160,
      '> div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    ':nth-of-type(2)': {
      margin: '10px 0 10px',
    },
    ':nth-of-type(3)': {
      marginBottom: 20,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      p: {
        textAlign: 'center',
      },
    },
  },
});

export const ProductLink = styled(StyledLink)(
  {
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  ({ isMedium }) => ({
    marginBottom: isMedium ? 10 : 20,
  }),
);

export const FooterLinks = styled.div(
  {
    display: 'flex',
  },
  ({ isMedium }) => ({
    flexWrap: isMedium ? 'nowrap' : 'wrap',
    flexDirection: isMedium ? 'column' : 'row',
  }),
);

export const FooterLink = styled(StyledLink)(
  {
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: '400',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    transition: 'color .2s linear',
    display: 'inline-block',
  },
  ({ theme, small, noShow, isMedium }) => ({
    color: theme.color.text.dark,
    fontSize: small ? 12 : 14,
    marginBottom: small ? 0 : 10,
    display: isMedium && noShow ? 'none' : 'inline-block',
    paddingLeft: isMedium && small ? 20 : 0,
    ':hover': {
      color: theme.color.text.primary,
    },
    [theme.media.lg]: {
      paddingLeft: isMedium && small ? 25 : 0,
    },
  }),
);
