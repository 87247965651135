import React from 'react';
import PropTypes from 'prop-types';
import lightTheme from 'themes/lightTheme';
import Flex from 'components/Flex/Flex';
import { useTheme } from '@emotion/react';

const CircleLoader = ({ inverted, color, children, duration }) => {
  const radius = 90 / 2 - 5 * 2;
  const cx = 90 / 2;
  const cy = 90 / 2;
  const circumference = 90 * Math.PI;
  const seconds = duration ? `${duration}s` : '2s';

  const defaultStyles = {
    '& svg': {
      animation: `rotateProgress ${seconds} ease infinite forwards`,
      animationFillMode: 'forwards',
      '& circle': {
        animation: `loadProgress ${seconds} ease infinite forwards`
      }
    },

    '@keyframes rotateProgress': {
      to: {
        transform: 'rotate(360deg)'
      }
    },

    '@keyframes loadProgress': {
      '0%': {
        strokeDasharray: `1, ${circumference}`,
        strokeDashoffset: 0
      },
      '50%': {
        strokeDasharray: `${circumference - 100}, ${circumference}`,
        strokeDashoffset: `-${circumference / 4}`
      },
      '100%': {
        strokeDasharray: `${circumference - 100}, ${circumference}`,
        strokeDashoffset: `-${circumference - 65}`
      }
    }
  };

  const theme = useTheme();

  return (
    <Flex column justify="center" align="center" css={{ ...defaultStyles }}>
      <svg width={90} height={90} viewBox={`0 0 ${90} ${90}`} fill="none">
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          stroke={theme.color[inverted ? 'contrast2' : 'contrast7']}
          strokeWidth={5}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset="0"
        />
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          stroke={theme.color[color ? color : 'accent']}
          strokeWidth={5}
          strokeDasharray={`${circumference} ${circumference}`}
        />
      </svg>
      {children}
    </Flex>
  );
};

const COLORS = Object.keys(lightTheme.color);

CircleLoader.propTypes = {
  /** Render Children under loader */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Sets loader to inverted */
  inverted: PropTypes.bool,
  /** The color of the loader */
  color: PropTypes.oneOf(COLORS),
  /** The duration of the animation in seconds */
  duration: PropTypes.number
};

export default CircleLoader;
