import React, { Fragment } from 'react';
import lightTheme from 'themes/lightTheme';
import * as Styled from './styles/StyledCheckbox';
const COLORS = Object.keys(lightTheme.color);

type Props = {
  field?: string;
  checked?: boolean;
  color?: keyof typeof COLORS;
  [x: string]: any;
};

const Checkbox = ({ field, checked, color, ...restProps }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) inputRef.current.checked = checked as boolean;
  }, [checked]);

  return (
    <Styled.CheckboxWrapper className={restProps.className}>
      {field ? (
        <Fragment>
          <Styled.CheckboxInput
            id={field}
            // @ts-ignore: TODO: can-o-worms wrt <Styled.CheckboxInput>. Not enough time in this sprint to sort out.
            field={field}
            // @ts-ignore: TODO: can-o-worms wrt <Styled.CheckboxInput>. Not enough time in this sprint to sort out.
            color={color}
            {...restProps}
            forwardedRef={inputRef}
          />
          <label htmlFor={field} />
        </Fragment>
      ) : (
        <Fragment>
          <Styled.BaseCheckbox
            type="checkbox"
            id={restProps.id}
            // @ts-ignore: TODO: can-o-worms wrt <Styled.CheckboxInput>. Not enough time in this sprint to sort out.
            checked={checked}
            // @ts-ignore: TODO: can-o-worms wrt <Styled.CheckboxInput>. Not enough time in this sprint to sort out.
            color={color}
            {...restProps}
          />
          <label htmlFor={restProps.id} />
        </Fragment>
      )}
    </Styled.CheckboxWrapper>
  );
};
export default Checkbox;
