import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles/StyledAvatar';

const getUserInitials = (user) => {
  let nameString = '';
  if (user && user.data && user.data.name) {
    const nameArray = user.data.name.split(' ');
    nameString += nameArray[0].substring(0, 1);
    if (nameArray.length > 1) {
      nameString += nameArray[nameArray.length - 1].substring(0, 1);
    }
  } else if (user.first_name || user.last_name) {
    if (user.first_name) {
      nameString += user.first_name.substring(0, 1);
    }
    if (user.last_name) {
      nameString += user.last_name.substring(0, 1);
    }
  } else if (user.username) {
    nameString += user.username.substring(0, 1);
  }
  return nameString.toUpperCase();
};

const getUserImage = (user) => {
  let src = null;
  if (user && user.image) {
    src = user.image;
  } else if (user.data) {
    if (user.data.image_small.data.paths.original) {
      src = user.data.image_square.data.paths.original;
    }
    if (user.data.image_large.data.paths.original) {
      src = user.data.image_square.data.paths.original;
    }
    if (user.data.image_square.data.paths.original) {
      src = user.data.image_square.data.paths.original;
    }
  }
  return src;
};

const getImageAlt = (user) => {
  let altString = '';
  if (user.data && user.data.name) {
    altString = user.data.name;
  } else if (user.first_name || user.last_name) {
    if (user.first_name) {
      altString += user.first_name;
    }
    if (user.last_name) {
      altString += ` ${user.last_name}`;
    }
  } else if (user.username) {
    altString = user.username;
  }
  return altString;
};

const Avatar = ({ user, size = 'sm', ...rest }) => {
  let initials;
  let src;
  let alt;

  if (user) {
    initials = getUserInitials(user);
    src = getUserImage(user);
    alt = getImageAlt(user);
  }

  return (
    <Styled.Avatar size={size} column align="center" justify="center" {...rest}>
      <Fragment>
        {src ? (
          <img
            src={src}
            alt={alt}
            css={{
              objectFit: 'cover',
              textAlign: 'center',
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <p css={{ margin: 0 }}>{initials}</p>
        )}
      </Fragment>
    </Styled.Avatar>
  );
};

Avatar.propTypes = {
  /* sm - 35, md - 60, lg - 80, xl - 100 */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /** userObject or Filmmaker object with data */
  user: PropTypes.shape({
    data: PropTypes.shape({
      image_large: PropTypes.object,
      image_square: PropTypes.object,
      image_small: PropTypes.object,
      name: PropTypes.string,
    }),
    first_name: PropTypes.string,
    image: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
};

export default Avatar;
