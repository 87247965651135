import React from 'react';
import { string, func, object, oneOfType } from 'prop-types';
import { useTheme } from '@emotion/react';
import Flex from 'components/Flex/Flex';
import MegaNavCollections from './MegaNavCollections';
import MegaNavFilmsupply from './MegaNavFilmsupply';
import MegaNavSupport from './MegaNavSupport';
import Auth from 'unstated/Auth';

const MegaNav = ({
  showMegaNav,
  setShowMegaNav,
  featuredCollection,
  collectionData,
}) => {
  const theme = useTheme();
  const { user } = Auth.useContainer();
  const isDarkTheme = user?.theme === 'dark';

  return (
    <Flex
      align="start"
      justify="center"
      onMouseLeave={() => {
        setShowMegaNav(null);
      }}
      css={{
        position: 'relative',
        width: '100%',
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
        background: isDarkTheme ? theme.color.contrast1 : theme.color.contrast,
        display: showMegaNav ? 'flex' : 'none',
      }}
    >
      <MegaNavCollections
        onMouseEnter={() => setShowMegaNav('collections')}
        onMouseLeave={() => {
          setShowMegaNav(null);
        }}
        isDarkTheme={isDarkTheme}
        featured={featuredCollection}
        collectionData={collectionData}
        isVisible={showMegaNav === 'collections'}
      />
      <MegaNavSupport
        onMouseEnter={() => setShowMegaNav('support')}
        onMouseLeave={() => {
          setShowMegaNav(null);
        }}
        isDarkTheme={isDarkTheme}
        isVisible={showMegaNav === 'support'}
      />
      <MegaNavFilmsupply
        onMouseEnter={() => setShowMegaNav('filmsupply')}
        onMouseLeave={() => {
          setShowMegaNav(null);
        }}
        isDarkTheme={isDarkTheme}
        isVisible={showMegaNav === 'filmsupply'}
      />
    </Flex>
  );
};

MegaNav.propTypes = {
  showMegaNav: oneOfType([string, null]),
  setShowMegaNav: func,
  featuredCollection: object,
};

export default MegaNav;
