import React from 'react';
import { Media } from 'react-matches';
import { useTheme } from '@emotion/react';

import PopOver from 'components/PopOver/PopOver';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { P1 } from 'components/Typography/Typography';
import Flex from 'components/Flex/Flex';
import * as Styled from './styles/StyledNewProjectPopover';
import Projects from 'unstated/Projects';

const NewProjectPopover = props => {
  const { popoverState } = Projects.useContainer();

  const theme = useTheme();

  const popoverRef = React.useRef();
  const targetRef = React.useRef();

  React.useEffect(() => {
    if (!popoverState.open && popoverRef.current?.state?.show) {
      popoverRef.current.hideTooltip();
    } else if (popoverState.open) {
      targetRef.current.click();
    }
  }, [popoverState.open]);

  return (
    <Media queries={theme.queries}>
      {({ matches }) => (
        <PopOver
          isCapture
          popOverRef={popoverRef}
          targetRef={targetRef}
          arrowColor={'white'}
          id={props.id ? props.id : 'new-project-popover'}
          target={props.children}
          overridePosition={(
            { left, top },
            currentEvent,
            currentTarget,
            node
          ) => {
            const width = node.getBoundingClientRect().width;
            if (left + width > window.innerWidth) {
              // little hack to move the arrow over when repositioning the tooltip
              const diff = left + width - window.innerWidth;
              node.children[0].innerHTML =
                node.children[0].innerHTML +
                `.__react_component_tooltip.place-bottom:after {
          left: calc(50% + ${diff + 10}px);
        }`;
              return {
                top,
                left: left - (left + width - window.innerWidth + 10)
              };
            } else return { top: top - 15, left };
          }}
        >
          {popoverState.project && popoverState.clip && (
            <div
              css={{
                minWidth: 300,
                maxWidth: 300
              }}
              className="projectsPopoverContainer"
            >
              <Flex
                justify="start"
                align="center"
                css={{
                  padding: 20,
                  borderBottom: `1px solid ${theme.color.staticGrey7}`
                }}
              >
                <Icon
                  name="checkCircle"
                  color="accent"
                  css={{ marginRight: 15 }}
                />
                <P1 color="staticBlack" as="p4">
                  Find all your saved projects here
                </P1>
              </Flex>
              <Flex justify="start" align="center" css={{ padding: 20 }}>
                <Styled.Image
                  img={popoverState.clip.movie.data.thumb.paths[480]}
                />
                <Flex grow={1} column justify="center">
                  <P1 color="staticBlack" as="p4">
                    Saved to
                  </P1>
                  <P1 color="staticBlack" bold as="p4">
                    {popoverState.project.name}
                  </P1>
                </Flex>
              </Flex>
              <Button
                to={`/project/${popoverState.project.slug}/${popoverState.project.id}`}
                bold
                css={{ margin: '5px 20px 20px', width: 'calc(100% - 40px)' }}
                onClick={() => popoverRef.current.hideTooltip()}
              >
                View Project
              </Button>
            </div>
          )}
        </PopOver>
      )}
    </Media>
  );
};

export default NewProjectPopover;
