import React from 'react';
import { createContainer } from 'unstated-next';
import useRouterQuery from 'hooks/useRouterQuery';
import useAxios from 'hooks/useAxios';
import Auth from 'unstated/Auth';
import qs from 'query-string';
import reduce from 'lodash-es/reduce';

export const CLIP_LIMIT = 48;

export let initialState = {
  type: null,
  clips: { data: {}, meta: {}, total: 0 },
  collections: { data: [], meta: {} },
  shoots: { data: [], meta: {} }
};

function browseReducer(state, action) {
  switch (action.type) {
    case 'new_search': {
      let { clips, ...data } = action.payload;
      return {
        type: action.type,
        ...data,
        clips: {
          ...action.payload.clips,
          data: {
            [action.payload.clips.meta.current_page]: clips.data
          },
          total: state.clips.total + clips.data.length
        }
      };
    }
    case 'next_clips': {
      return {
        ...state,
        type: action.type,
        clips: {
          ...action.payload,
          data: {
            ...state.clips.data,
            [action.payload.meta.current_page]: action.payload.data
          },
          total: state.clips.total + action.payload.data.length
        }
      };
    }
    case 'prev_clips': {
      return {
        ...state,
        type: action.type,
        clips: {
          ...action.payload,
          data: {
            [action.payload.meta.current_page]: action.payload.data,
            ...state.clips.data
          },
          total: state.clips.total + action.payload.data.length
        }
      };
    }
    case 'reset_search': {
      return {
        ...initialState,
        type: action.type
      };
    }
    default: {
      throw new Error(`${action.type} does not exst`);
    }
  }
}

function useBrowseContainer() {
  let [browse, setBrowse] = React.useReducer(browseReducer, initialState);

  let loadBrowse = React.useCallback(payload => {
    setBrowse(payload);
  }, []);

  return { loadBrowse, browse };
}

let Browse = createContainer(useBrowseContainer);

export let parseFilters = filters => {
  return reduce(
    filters,
    (acc, filter, key) => {
      let filterKey = /no-/.test(key)
        ? `exclude[${key.split('-')[1]}]`
        : `include[${key}]`;
      return {
        ...acc,
        [filterKey]: filter
      };
    },
    {}
  );
};

export let useBrowseSearch = () => {
  let { loadBrowse, browse } = Browse.useContainer();
  let { user } = Auth.useContainer();
  let { updateBrowseQuery, getBrowseQuery } = useBrowseURL();
  let [{ data, ...response }, request] = useAxios();
  let [, searchHistoryReq] = useAxios();
  let attemptedSearch = React.useRef(null);
  let onMountRef = React.useRef(true);
  let searchRef = React.useRef({ page: null });

  let resetBrowse = React.useCallback(() => {
    loadBrowse({ type: 'reset_search' });
  }, [loadBrowse]);

  let search = React.useCallback(
    async searchParams => {
      let { search, page, tags, filters } = searchParams;
      searchRef.current = searchParams;

      let parsedFilters = parseFilters(filters);

      return Promise.all([
        request.get(`/search/clips`, {
          params: {
            search,
            page,
            tags,
            ...parsedFilters
          }
        }),
        request.get(`/search/curated-collections`, {
          params: {
            search,
            ...parsedFilters
          }
        }),
        request.get(`/search/shoots`, {
          params: {
            search,
            ...parsedFilters
          }
        })
      ]);
    },
    [request]
  );

  let nextPage = React.useCallback(async () => {
    if (
      browse.clips.meta.total <
      browse.clips.meta.per_page * browse.clips.meta.current_page
    )
      return;
    let pages = Object.keys(browse.clips.data);
    let page = Number(pages[pages.length - 1]) + 1;
    let { filters, ...query } = getBrowseQuery();
    let parsedFilters = parseFilters(filters);
    let clips = await request.get(`/search/clips`, {
      params: { ...query, ...parsedFilters, page }
    });
    loadBrowse({ type: 'next_clips', payload: clips });
  }, [
    browse.clips.data,
    browse.clips.meta.current_page,
    browse.clips.meta.per_page,
    browse.clips.meta.total,
    getBrowseQuery,
    loadBrowse,
    request
  ]);

  let prevPage = React.useCallback(async () => {
    let page = Number(Object.keys(browse.clips.data)[0]) - 1;
    let { filters, ...query } = getBrowseQuery();
    let parsedFilters = parseFilters(filters);
    let clips = await request.get(`/search/clips`, {
      params: { ...query, ...parsedFilters, page }
    });
    loadBrowse({ type: 'prev_clips', payload: clips });
    updateBrowseQuery({ page });
  }, [
    browse.clips.data,
    getBrowseQuery,
    loadBrowse,
    request,
    updateBrowseQuery
  ]);

  // search when url is updated
  React.useEffect(() => {
    let {
      page: prevPage,
      filters: prevFilters,
      ...prevSearch
    } = searchRef.current;
    let {
      page: nextPage,
      filters: nextFilters,
      ...nextSearch
    } = getBrowseQuery();
    let prev = qs.stringify({ ...prevSearch, ...prevFilters });
    let next = qs.stringify({ ...nextSearch, ...nextFilters });

    async function onSearch() {
      let searchParams = getBrowseQuery();
      let result = await search(searchParams);

      if (!result[0].data.length && result[0].meta.suggestion?.term) {
        let { term } = result[0].meta.suggestion;
        attemptedSearch.current = searchParams.search;
        searchParams = { ...searchParams, search: term };
        result = await search(searchParams);
        let { filters, ...rest } = searchParams;
        updateBrowseQuery({ ...rest, ...filters });
      }

      let payload = {
        clips: result[0],
        collections: result[1],
        shoots: result[2]
      };

      if (
        searchParams?.search?.length &&
        user.id &&
        payload.clips.data.length
      ) {
        let { filters, ...rest } = searchParams;
        let parsedFilters = parseFilters(filters);
        searchHistoryReq.post('search/history', {
          clip_id: payload.clips.data[0].id,
          editorial: false, // TODO (JMC) where should editorial come from?
          ...rest,
          ...parsedFilters
        });
      }
      loadBrowse({
        type: 'new_search',
        payload
      });
    }

    if (attemptedSearch.current)
      return () => {
        attemptedSearch.current = null;
      };

    if (onMountRef.current) {
      let { filters, ...rest } = getBrowseQuery();
      updateBrowseQuery({ ...rest, ...filters });
      onSearch();
    } else if (prev !== next) {
      if (prev) loadBrowse({ type: 'reset_search' });
      onSearch();
    }

    return () => (onMountRef.current = false);
  }, [
    getBrowseQuery,
    loadBrowse,
    search,
    searchHistoryReq,
    updateBrowseQuery,
    user.id
  ]);

  return {
    response,
    browse,
    search,
    updateBrowseQuery,
    getBrowseQuery,
    nextPage,
    resetBrowse,
    attemptedSearch: attemptedSearch.current,
    locationSearch: searchRef.current,
    prevPage
  };
};

export let useBrowseURL = () => {
  let { getQueryParams, updateQuery } = useRouterQuery();

  let getBrowseQuery = React.useCallback(() => {
    let { search, page = 1, tags = [], ...filters } = getQueryParams();

    let urlTags = Array.isArray(tags) ? tags : [tags];

    return {
      search,
      page,
      tags: urlTags,
      filters: {
        ...filters
      }
    };
  }, [getQueryParams]);

  let updateBrowseQuery = React.useCallback(
    (searchParams = {}) => {
      let { page, filters, ...rest } = getBrowseQuery();

      let currentSearch = { ...rest, ...filters };
      let nextSearch = { ...currentSearch, ...searchParams };

      let curr = qs.stringify(currentSearch);
      let next = qs.stringify(nextSearch, { skipNull: true });
      if (curr === next) return; // return identical search

      if (currentSearch.search !== nextSearch.search) {
        return updateQuery({
          pathname: '/clips',
          search: { search: nextSearch.search, page: 1 }
        });
      }

      nextSearch = { search: nextSearch.search, page: 1, ...nextSearch };
      updateQuery({ pathname: '/clips', search: nextSearch });
    },
    [getBrowseQuery, updateQuery]
  );

  return { getBrowseQuery, updateBrowseQuery };
};

export default Browse;
