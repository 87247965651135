import { GrowthBook } from '@growthbook/growthbook-react';

export const growthbook = new GrowthBook({
  apiHost: import.meta.env.VITE_APP_GROWTHBOOK_HOST,
  clientKey: import.meta.env.VITE_APP_GROWTHBOOK_CLIENT_KEY,
  ...(import.meta.env.VITE_APP_GROWTHBOOK_ENV !== 'local' && {
    decryptionKey: import.meta.env.VITE_APP_GROWTHBOOK_DECRYPTION_KEY,
  }),
  enableDevMode: import.meta.env.VITE_APP_GROWTHBOOK_ENV === 'local',
});
