import React from 'react';
import axios from 'axios';

export const NOT_ASKED = 'Not Asked';
export const LOADING = 'Loading';
export const SUCCESS = 'Success';
export const FAILURE = 'Failure';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case NOT_ASKED:
      return {
        ...state,
        remote: NOT_ASKED,
        success: false,
        notAsked: true,
        ...action.payload
      };
    case LOADING:
      return {
        ...state,
        remote: LOADING,
        success: false,
        loading: true,
        notAsked: false,
        data: [],
        errors: null
      };
    case SUCCESS:
      return {
        ...state,
        remote: SUCCESS,
        success: true,
        loading: false,
        notAsked: false,
        ...action.payload
      };
    case FAILURE:
      return {
        ...state,
        remote: FAILURE,
        success: false,
        loading: false,
        notAsked: false,
        data: null,
        ...action.payload
      };
    default:
      throw new Error();
  }
};

export const useAxios = (config, initialData) => {
  let configRef = React.useRef(config);

  const [state, dispatch] = React.useReducer(dataFetchReducer, {
    remote: NOT_ASKED,
    success: false,
    loading: false,
    notAsked: true,
    errors: null,
    data: initialData || []
  });

  let request = React.useCallback(() => {
    let instance = axios.create({
      ...configRef,
      baseURL: `${import.meta.env.VITE_APP_API_URL}${configRef.current
        ?.baseURL || ''}`,
      withCredentials: true
    });

    instance.interceptors.request.use(
      function(config) {
        dispatch({ type: LOADING });
        return {
          ...config
        };
      },
      function(error) {
        dispatch({ type: FAILURE, payload: error });
        return error.response.data;
      }
    );

    instance.interceptors.response.use(
      function(response) {
        let data = {
          ...response,
          data:
            response.data?.data?.data || response.data?.data || response.data,
          meta: response.data?.meta
        };

        dispatch({ type: SUCCESS, payload: data });

        return data;
      },
      function(error) {
        dispatch({ type: FAILURE, payload: error });
        return error?.response?.data;
      }
    );
    return instance;
  }, []);

  return [state, React.useMemo(() => request(), [request])];
};

export default useAxios;
