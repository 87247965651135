import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
const StyledNavLink = styled(NavLink);

export const Main = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%'
});

export const Col = styled.div(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minWidth: 282,
    height: 300,
    paddingRight: 48
  },
  ({ theme, borderWidth = 1, paddingLeft = 48 }) => ({
    borderLeft: `${borderWidth}px solid ${theme.color.contrast}`,
    paddingLeft
  })
);

export const CenterCol = styled.div(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: 48
  },
  ({ theme, borderWidth = 1, paddingLeft = 48 }) => ({
    borderLeft: `${borderWidth}px solid ${theme.color.contrast1}`,
    borderRight: `${borderWidth}px solid ${theme.color.contrast1}`,
    paddingLeft
  })
);

export const ChevronLinkCol = styled.div({
  display: 'flex',
  paddingTop: 10,
  height: 130,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
});

export const LinksCol = styled.div(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 266,
    minHeight: 300,
    paddingRight: 48,
    paddingBottom: 8
  },
  ({ paddingLeft = 48 }) => ({
    paddingLeft
  })
);

const LinkBoldStyle = ({ theme, isDarkTheme }) => ({
  borderRadius: 4,
  transition: 'background-color .2s ease,color .2s ease',
  minWidth: 130,
  color: theme.color.primary,
  fontSize: 12,
  fontWeight: 700,
  padding: '7px 13px',
  lineHeight: '16px',
  textDecoration: 'none',
  ':hover': {
    color: theme.color.primary,
    background: isDarkTheme ? theme.color.contrast3 : theme.color.contrast1
  }
});
export const Bold = styled.span(LinkBoldStyle);
export const LinkBold = StyledNavLink(LinkBoldStyle);
export const LinkBoldHREF = styled.a(LinkBoldStyle);

export const RowLink = styled.a(({ theme }) => ({
  color: theme.color.primary,
  fontSize: 10,
  fontWeight: '600',
  lineHeight: '10px',
  letterSpacing: 1,
  textDecoration: 'none'
}));

export const SideLink = styled.a(
  {
    width: 115,
    borderRadius: 4,
    padding: '7px 13px 7px 13px',
    transition: 'background-color .2s ease,color .2s ease',
    textDecoration: 'none'
  },
  ({ theme, isDarkTheme, bold = false }) => ({
    textTransform: bold ? 'uppercase' : 'none',
    color: isDarkTheme
      ? theme.color.contrast5
      : bold
      ? theme.color.primary
      : theme.color.contrast3,
    fontSize: bold ? 10 : 12,
    fontWeight: bold ? 600 : 400,
    lineHeight: '12px',
    ':hover': {
      color: theme.color.primary,
      background: isDarkTheme ? theme.color.contrast3 : theme.color.contrast1
    }
  })
);

export const ThisIsMusicbedImgLink = StyledNavLink({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 5
});

export const LinkHolder = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContet: 'flex-start',
  alignItems: 'flex-start',
  padding: 20,
  paddingTop: 10
});

export const ImgHolder = styled.div({
  display: 'flex'
});

export const Heading = styled.div(({ theme }) => ({
  paddingBottom: 13,
  textTransform: 'uppercase',
  color: theme.color.primary,
  fontSize: 10,
  fontWeight: 600,
  lineHeight: '12px'
}));

export const Div = styled.div(({ theme, isDarkTheme }) => ({
  color: isDarkTheme ? theme.color.contrast5 : theme.color.contrast3,
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '18px'
}));

export const Row = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxWidth: 509,
  color: theme.color.contrast,
  fontSize: 12,
  marginTop: 14,
  marginBottom: 24,
  fontWeight: 400,
  ':hover': {
    color: theme.color.primary,
    backgroundColor: theme.color.contrast
  }
}));
