import React from 'react';
import PropTypes from 'prop-types';
import lightTheme from '../../themes/lightTheme';

import * as Styled from './styles/StyledFlex';

// so that propTypes work with storybook
const Flex = ({ wrap = false, ...restProps }) => (
  <Styled.Flex wrap={wrap ? 1 : 0} {...restProps} />
);

const COLORS = Object.keys(lightTheme.color);

Flex.propTypes = {
  /** Sets display to 'inline-flex' */
  inline: PropTypes.bool,
  /** Sets border radius to 4px */
  rounded: PropTypes.bool,
  /** Sets flexDirection to 'column' */
  column: PropTypes.bool,
  /** Sets direction to 'row-reverse' or 'column-reverse */
  reverse: PropTypes.bool,
  /** Sets the justifyContent property */
  justify: PropTypes.oneOf([
    'start',
    'end',
    'between',
    'space-between',
    'around',
    'space-around',
    'space-evenly',
    'evenly',
    'center'
  ]),
  /** Sets the alignItems property */
  align: PropTypes.oneOf([
    'start',
    'end',
    'between',
    'space-between',
    'around',
    'space-around',
    'stretch',
    'baseline',
    'center'
  ]),
  /** Sets flexWrap to 'wrap' */
  wrap: PropTypes.bool,
  /** Sets flexGrow value*/
  grow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Sets the value for flexBasis - accepts a string or number (50, '50px', '50%') */
  basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Sets flexShrink value */
  shrink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Specifies a background color for the box */
  backgroundColor: PropTypes.oneOf(COLORS),
  /** Specifies a background image for the box */
  backgroundImage: PropTypes.string,
  /** Sets the border property for one side if item is aligned in group */
  endcap: PropTypes.oneOf(['left', 'right', 'top', 'bottom'])
};

export default Flex;
