import debounce from 'lodash-es/debounce';
import { addTrailingSlash } from '../helpers/InitScriptsComponent';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const LOGGER_DEBUG = false; // turn this on to debug events to console
const debouncedLoggers = {};

/**
 * Logs events to Google Tag Manager, FullStory, and internal tracking all at once.
 * We are debouncing by event name because there are some components that re-render
 * multiple times in a row which would cause some events to have duplicate logs generated
 * @param {Object} data
 * @param {String} data.event required
 * @param {Boolean} gtmEvent
 * @param {Boolean} fullStoryEvent
 * @returns {Promise}
 */
export default function logger(data, gtmEvent = true, fullStoryEvent = true) {
  // put it on the next tick so it won't interefere with any main code execution
  setTimeout(async () => {
    if (!data.event) {
      throw Error(
        'Attempted to log an event without an event name: ' +
          JSON.stringify(data)
      );
    }
    if (typeof debouncedLoggers[data.event] !== 'function') {
      debouncedLoggers[data.event] = debounce(log, 250);
    }
    debouncedLoggers[data.event](data, gtmEvent, fullStoryEvent);
  }, 1);
  // this is sometimes used inside jsx ternary show/hide, so this needs to return a truthy value
  return true;
}

/**
 * Performs the core logging function
 * @param {Object} data
 * @param {String} data.event
 * @param {Boolean} gtmEvent
 * @param {Boolean} fullStoryEvent
 * @returns {Error|null}
 */
async function log(data, gtmEvent, fullStoryEvent) {
  if (!data.page_name) {
    data.page_name = window.location.href;
  }

  // log to Google Tag Manager
  if (IS_PRODUCTION && gtmEvent && window.dataLayer) {
    window.dataLayer.push(data);
    // clear action type from dataLayer for subsequent logs.
    if (data.actionType) {
      window.dataLayer.push({ actionType: '' });
    }
  } else if (gtmEvent && window.dataLayer) {
    if (data.page_name) {
      // The first GTM load appears to fire a GA event
      data.page_name = addTrailingSlash(data.page_name);
    }
    window.dataLayer.push(data);

    // clear action type from dataLayer for subsequent logs.
    if (data.actionType) {
      window.dataLayer.push({ actionType: '' });
    }
  }

  // log to FullStory
  if (
    IS_PRODUCTION &&
    fullStoryEvent &&
    window.FS &&
    typeof window.FS.event === 'function' &&
    data.event
  ) {
    window.FS.event(data.event, data);
  }

  if (LOGGER_DEBUG) {
    // eslint-disable-next-line
    console.log(`Event Logged: ${data.event}`, data);
  }

  return Promise.resolve();
}
