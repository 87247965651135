import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { loadRecaptcha } from 'react-recaptcha-v3';
import Logger from 'utils/logger';
import history from '../browserHistory';
import Hubspot from './hubspot';
import { cookieConsentInit } from 'utils/cookieConsent';

export const addTrailingSlash = (url) => {
  // Adds a trailing slash for consistency in GA reports
  const isTrailingSlash = url.substr(-1) === '/';
  const updatedUrl = isTrailingSlash ? url : `${url}/`;
  return updatedUrl;
};

const gaTracking = (gaLocation, isOnLoad) => {
  // Google Analytics
  if (window.ga) {
    if (isOnLoad) {
      /**
       * Send page load to Google Analytics
       */
      window.ga('set', 'location', gaLocation);
      window.ga('send', 'pageview');
    } else {
      /**
       * Send page view to Google Analytics
       * whenever user navigates to another page
       */
      window.ga('send', 'pageview', gaLocation);
    }
  }
};

const Initialize = () => {
  /**
   * This component lazy-loads marketing-related or logging scripts
   * in order to temporarily improve Lighthouse scores & SEO
   */
  useEffect(() => {
    // Page load tracking
    cookieConsentInit();

    const loadRecaptchaAsync = async () => {
      // Initialize invisible recaptcha
      await loadRecaptcha(import.meta.env.VITE_APP_RECAPTCHAv3_SITE_KEY);
    };
    loadRecaptchaAsync();
    Logger({ event: 'app 2.0 loaded' });

    const gaLocation = addTrailingSlash(window.location.href);
    gaTracking(gaLocation, true);
 
    // Page view tracking
    history.listen((location) => {
      const gaLocation = addTrailingSlash(location.pathname);
      gaTracking(gaLocation, false);
      Hubspot.trackPageView(gaLocation);
    });

    // Smoothscroll
    smoothscroll.polyfill();
  }, []);
  return null;
};

export default Initialize;
