import * as Sentry from "@sentry/browser";

const isError = obj => {
  return Object.prototype.toString.call(obj) === '[object Error]';
};

export const errorLogger = ({ message = '', error, details }) => {
  if (!error) return;

  if (isError(error)) {
    Sentry.captureException(error);
  } else {
    Sentry.setContext('details', details).captureMessage(message, 'info');
  }
};
