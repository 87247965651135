import React, { Fragment } from 'react';
import { createContainer } from 'unstated-next';
import axios from 'axios';
import AuthUnstated from 'unstated/Auth';

const fetchToggles = async userEmail => {
  return axios.get(`${import.meta.env.VITE_APP_SWITCHBOARD_API_URL}`, {
    params: {
      environment: import.meta.env.VITE_APP_SWITCHBOARD_API_ENV,
      email: userEmail
    },
    headers: {
      Authorization: `Bearer ${import.meta.env.VITE_APP_SWITCHBOARD_API_TOKEN}`
    }
  });
};

const useFeatureToggles = initialState => {
  return { toggles: initialState };
};

let FeatureToggles = createContainer(useFeatureToggles);

export const AsyncFeatureToggleProvider = ({ children }) => {
  const { user, initialFetchComplete } = AuthUnstated.useContainer();
  const [defaultToggles, setDefaultToggles] = React.useState(null);
  const fetchedUserToggles = React.useRef(false);
  const fetchedDefaultToggles = React.useRef(false);

  React.useLayoutEffect(() => {
    const fetchUserToggles = async userEmail => {
      try {
        let { data: toggles } = await fetchToggles(userEmail);
        setDefaultToggles(toggles);
      } catch {
        setDefaultToggles(defaultToggles || {});
      }
    };

    if (initialFetchComplete && !fetchedUserToggles.current) {
      fetchedUserToggles.current = true;
      fetchUserToggles(user?.email);
    } else if (!defaultToggles && !fetchedDefaultToggles.current) {
      fetchedDefaultToggles.current = true;
      fetchUserToggles();
    }
  }, [initialFetchComplete, user, defaultToggles]);

  return (
    <Fragment>
      {defaultToggles && (
        <FeatureToggles.Provider initialState={defaultToggles}>
          {children}
        </FeatureToggles.Provider>
      )}
    </Fragment>
  );
};

export default FeatureToggles;
