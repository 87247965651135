import React from 'react';
import { createContainer } from 'unstated-next';

const useKeyCommands = () => {
  const [keyCommand, setKeyCommand] = React.useState(null);
  const [blocked, setBlocked] = React.useState(false);
  const isMounted = React.useRef();

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      document.addEventListener('visibilitychange', () => {
        setKeyCommand(null);
      });

      window.onfocus = () => {
        setKeyCommand(null);
      };

      window.addEventListener('keydown', e => {
        if (e.metaKey && e.key === 'Meta' && !keyCommand) {
          setKeyCommand('scrub');
        }
        if (e.shiftKey && e.key === 'Shift' && !keyCommand) {
          setKeyCommand('quickPreview');
        }
      });
      window.addEventListener('keyup', e => {
        if (e.key === 'Meta' || e.key === 'Shift') {
          setKeyCommand(null);
        }
      });
    }
  }, [keyCommand]);

  return { keyCommand: blocked ? null : keyCommand, setBlocked };
};

export default createContainer(useKeyCommands);
