import React from 'react';
import { useTheme } from '@emotion/react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import Modals from 'unstated/Modals';
import FeatureToggles from 'unstated/FeatureToggles';
import { P1, P2, P3 } from 'components/Typography/Typography';

import { SupportLink, ContactUsLink } from './styles/StyledMegaNav';
import { PHONE_NUMBER } from 'constants/contactInfo';

const MegaNavSupport = ({ isDarkTheme, isVisible }) => {
  const theme = useTheme();
  const { toggles } = FeatureToggles.useContainer();
  const { footageResearchModal } = Modals.useContainer();

  return (
    <Flex
      css={{
        maxWidth: 1000,
        width: '100%',
        marginTop: 50,
        height: 250,
        display: isVisible ? 'flex' : 'none',
      }}
    >
      <Link
        to={toggles.footageResearch && '/contact'}
        css={{ textDecoration: 'none' }}
      >
        <Flex
          css={{
            flexBasis: 310,
            minWidth: 310,
            minHeight: 170,
            padding: '35px 45px 35px 30px',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: isDarkTheme
                ? theme.color.contrast3
                : theme.color.contrast1,
              '.support-link': {
                span: {
                  transform: 'translate(3px, 0px)',
                  transition: '0.3s ease-in-out',
                },
              },
            },
          }}
          onClick={!toggles.footageResearch && footageResearchModal.open}
        >
          <Icon
            color="primary"
            size={26}
            name="footageResearch"
            css={{ marginRight: 20 }}
          />
          <div>
            <Flex
              justify="between"
              align="center"
              css={{ marginBottom: 14, letterSpacing: '1px' }}
            >
              <P3 uppercase color="primary" semibold css={{}}>
                Have Questions?
              </P3>
            </Flex>

            <P2 color="contrast4" css={{ marginBottom: 20 }}>
              We are here to help! We'll email you back within one working day.
            </P2>
            <SupportLink className="support-link">
              <P2 color="primary" bold>
                Start Now
              </P2>
              <Icon color="primary" size={8} name="caretRight" />
            </SupportLink>
          </div>
        </Flex>
      </Link>
      <Link to="/faq" css={{ textDecoration: 'none' }}>
        <Flex
          css={{
            flexBasis: 320,
            minWidth: 320,
            minHeight: 170,
            padding: '35px 30px 35px 30px',
            marginLeft: 20,
            ':hover': {
              backgroundColor: isDarkTheme
                ? theme.color.contrast3
                : theme.color.contrast1,
              '.support-link': {
                span: {
                  transform: 'translate(3px, 0px)',
                  transition: '0.3s ease-in-out',
                },
              },
            },
          }}
        >
          <Icon
            color="primary"
            size={26}
            name="question"
            css={{ marginRight: 20 }}
          />
          <div>
            <P3
              uppercase
              color="primary"
              semibold
              css={{ marginBottom: 14, letterSpacing: '1px' }}
            >
              Frequent Questions
            </P3>
            <P2 color="contrast4" css={{ marginBottom: 20 }}>
              Questions about footage licensing and the website can be found
              here.
            </P2>
            <SupportLink className="support-link">
              <P2 color="primary" bold>
                View FAQs
              </P2>
              <Icon color="primary" size={8} name="caretRight" />
            </SupportLink>
          </div>
        </Flex>
      </Link>
      <Flex wrap column css={{ width: '100%', paddingLeft: 50 }}>
        <Flex justify="between" css={{ width: '100%', paddingBottom: 22 }}>
          <P1 bold color="primary">
            Contact Us
          </P1>
        </Flex>
        <Flex
          justify="between"
          align="start"
          css={{
            width: '100%',
            borderTop: `1px solid ${theme.color.contrast2}`,
            paddingTop: 22,
          }}
        >
          <Flex column justify="start" align="start" css={{ width: '48%' }}>
            <ContactUsLink
              isDarkTheme={isDarkTheme}
              color="primary"
              hoverColor="primary"
              textComponent="P1"
              bold
              left
              href="https://twitter.com/filmsupply"
            >
              <Icon
                name="twitter"
                size="16px"
                color="primary"
                css={{ marginRight: 20 }}
              />
              Twitter
            </ContactUsLink>
            <ContactUsLink
              isDarkTheme={isDarkTheme}
              color="primary"
              hoverColor="primary"
              textComponent="P1"
              bold
              left
              href="https://www.facebook.com/FilmSupply"
            >
              <Icon
                name="facebook"
                size="16px"
                color="primary"
                css={{ marginRight: 20 }}
              />
              Facebook
            </ContactUsLink>
            <ContactUsLink
              isDarkTheme={isDarkTheme}
              color="primary"
              hoverColor="primary"
              textComponent="P1"
              bold
              left
              href="https://instagram.com/filmsupply"
            >
              <Icon
                name="instagram"
                size="16px"
                color="primary"
                css={{ marginRight: 20 }}
              />
              Instagram
            </ContactUsLink>
          </Flex>
          <Flex justify="start" align="start" column css={{ width: '52%' }}>
            <ContactUsLink
              isDarkTheme={isDarkTheme}
              color="primary"
              hoverColor="primary"
              textComponent="P1"
              bold
              left
              href={PHONE_NUMBER.HREF}
            >
              {PHONE_NUMBER.WITH_COUNTRY_CODE}
            </ContactUsLink>
            <ContactUsLink
              isDarkTheme={isDarkTheme}
              color="primary"
              hoverColor="primary"
              textComponent="P1"
              bold
              left
              href="mailto:support@filmsupply.com"
              css={{ width: 'auto' }}
            >
              support@filmsupply.com
            </ContactUsLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

MegaNavSupport.propTypes = {
  isDarkTheme: bool,
  isVisible: bool,
};

export default MegaNavSupport;
