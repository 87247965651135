import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route as ReactRoute } from 'react-router-dom';
import { completedVerticals } from 'helpers/userConditionals';
import Auth from 'unstated/Auth';

// Replace with user.roles when authentication is implemented
const roles = [
  {
    id: 2,
    name: 'RoleName'
  },
  {
    id: 3,
    name: 'RoleName'
  },
  {
    id: 5,
    name: 'RoleName'
  }
];

const Route = ({
  name,
  component: Component,
  path,
  permissions,
  redirect,
  userConditionals,
  exact,
  authenticate
}) => {
  const { user, initialFetchComplete } = Auth.useContainer();

  // Check for required user conditionals
  let isAuthorized = true;

  if (initialFetchComplete) {
    if (userConditionals) {
      if (userConditionals.completedVerticals)
        isAuthorized = completedVerticals(user);
    }

    // Authentication & Permissions Route
    else if (authenticate) {
      // Return null component until server has returned
      // /auth response in the main <App/> component.
      if (Array.isArray(user)) return null;

      // Check if user is in a restricted role or not
      let isRestricted = false;
      roles &&
        roles.map(role => {
          if (permissions.restrict.includes(role.id)) {
            isRestricted = true;
            return null;
          }
          return null;
        });

      // If user is logged in and not restricted, proceed
      // to route, or redirect to appropriate route.
      return user.username && !isRestricted && isAuthorized ? (
        <ReactRoute
          exact={exact}
          path={path}
          render={() => <Component user={user} />}
        ></ReactRoute>
      ) : (
        <Redirect
          to={
            isRestricted && redirect
              ? redirect
              : isRestricted && !redirect
              ? '/'
              : redirect || '/login'
          }
        />
      );
    }

    // Make sure user passes authorization
    else if (isAuthorized)
      return (
        <ReactRoute exact={exact} path={path}>
          <Component user={user} />
        </ReactRoute>
      );
    else return <Redirect to={redirect || '/'} />;
  } else return null;
};

Route.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    redirect: PropTypes.arrayOf(PropTypes.number)
  }),
  redirect: PropTypes.string,
  exact: PropTypes.bool,
  authenticate: PropTypes.bool
};

export default Route;
