import styled from '@emotion/styled';
import PopOver from 'components/PopOver/PopOver';
import { Cell } from 'components/CSSGrid/CSSGrid';

export const StyledPopOver = styled(PopOver)({
  cursor: 'pointer',

  '&, & *': {
    transition: 'none !important'
  }
});

export const StyledDropdown = styled.div(({ theme, bgColor }) => ({
  borderRadius: 0,
  backgroundColor: theme.color[bgColor],
  width: '350px',
  display: 'grid',
  gridTemplateAreas: "'subtotal' 'clip' 'checkout'",
  whiteSpace: 'normal',

  [theme.media.lg]: {
    position: 'static'
  }
}));

export const DropdownCell = styled(Cell)(({ theme, area }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',

  ...(area === 'clip' && {
    maxHeight: '250px',
    overflow: 'scroll'
  }),

  '&:first-of-type': {
    borderBottom: `1px solid ${theme.color.staticGrey7}`
  },

  '&:nth-of-type(2)': {
    padding: '15px 10px 5px',
    flexDirection: 'column',
    alignItems: 'unset'
  }
}));

export const Item = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: "'image price'",
  borderRadius: 0,
  padding: '10px',
  maxHeight: 54,

  '&:hover': {
    backgroundColor: theme.color.staticGrey7
  },

  '& > div': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

export const ItemCell = styled(Cell)(({ theme }) => ({
  '&:last-of-type': {
    justifyContent: 'flex-end'
  }
}));
