import styled from '@emotion/styled';

export const AccountMobile = styled.button(() => ({
  display: 'flex',
  marginLeft: 13,
  paddingLeft: 12,
  // Remove default button styling
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  border: 'none',
  background: 'none',
  outline: 'none',
}));

export const MenuContainer = styled.div(({ theme, top }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  left: 0,
  top: `calc(${top}px - 50px)`,
  '@keyframes slideIn': {
    '0%': {
      top: `calc(${top}px - 50px)`,
      opacity: 0,
    },
    '100%': {
      top,
      opacity: 1,
    },
  },
  animation: `slideIn 0.3s forwards`,
  width: '100%',
  backgroundColor: theme.color.contrast,
  maxHeight: `calc(100vh - ${top}px)`,
  overflowY: 'scroll',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
}));

export const ProfileRow = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '20px 20px 0px 9px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ProfileRowLeft = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: 8,
  height: 39,
  alignItems: 'center',
}));

export const BlackSpacer = styled.div(({ theme }) => ({
  display: 'flex',
  width: 3,
  backgroundColor: theme.color.primary,
  height: 30,
  marginRight: 4,
}));

export const UserName = styled.div(({ theme }) => ({
  display: 'flex',
  color: theme.color.primary,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '-0.16px',
}));

export const Email = styled.div(({ theme }) => ({
  display: 'flex',
  color: theme.color.contrast4,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '18.2px',
  letterSpacing: '-0.14px',
}));

export const SpacerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingLeft: 20,
  paddingRight: 20,
}));

export const MenuSpacer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 12,
  width: '100%',
  borderBottomWidth: 1,
  borderBottomColor: theme.color.contrast2,
  borderBottomStyle: 'solid',
  paddingBottom: 24,
}));
