/**
 * Normalize an url for tracking services.
 * Adds a trailing slash if it is not present.
 * @param {string} url
 * @returns string
 */
const normalizeUrl = (url: string): string => {
  return url.endsWith('/') ? url : url + '/';
};

export default normalizeUrl;
