import React from 'react';
import { createContainer } from 'unstated-next';
import NotificationCard from 'components/Notification/Notification';

const useNotification = () => {
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationContent, setNotificationContent] = React.useState(null);
  const [isFlash, setIsFlash] = React.useState(false);
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState(342);
  const openNotification = async ({ content, flash, fullWidth, maxWidth }) => {
    await setNotificationContent(content);
    await setShowNotification(true);
    if (fullWidth) {
      setIsFullWidth(true);
    }
    if (maxWidth) {
      setMaxWidth(maxWidth);
    }
    if (flash) {
      setIsFlash(true);
      setTimeout(() => {
        closeNotification();
      }, 3000);
    } else {
      setIsFlash(true);
      setTimeout(() => {
        closeNotification();
      }, 5000);
    }
  };

  const closeNotification = () => {
    setShowNotification(false);
    setIsFlash(false);
    setIsFullWidth(false);
    setMaxWidth(342);
  };
  return {
    showNotification,
    notificationContent,
    openNotification,
    closeNotification,
    isFlash,
    isFullWidth,
    maxWidth
  };
};

const NotificationUnstated = createContainer(useNotification);

const GlobalNotification = () => {
  const {
    showNotification,
    notificationContent,
    closeNotification,
    isFlash,
    isFullWidth,
    maxWidth
  } = NotificationUnstated.useContainer();
  return (
    <NotificationCard
      showNotification={showNotification}
      isFlash={isFlash}
      fullWidth={isFullWidth}
      maxWidth={maxWidth}
      closeNotification={closeNotification}
    >
      {notificationContent}
    </NotificationCard>
  );
};

export default NotificationUnstated;

export { GlobalNotification };
