import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const ScrollHandler = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      const element = location.hash
        ? document.querySelector(location.hash)
        : null;
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop : 0
      });
    } catch (e) {
      history.push({ ...history.location, hash: null });
    }
  }, [history, location]);
  return null;
};

export default ScrollHandler;
