import React, { Fragment } from 'react';
import { Global } from '@emotion/react';
import { resetStyles } from 'resetStyles';
import { globalStyles } from '../../globalStyles';
import { useTheme } from '@emotion/react';

const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <Fragment>
      <Global styles={resetStyles} />
      <Global styles={globalStyles(theme)} />
    </Fragment>
  );
};

export default GlobalStyles;
