import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios from 'hooks/useAxios';

const useEditFest = () => {
  const [, fetchFromAPI] = useAxios();

  const getEditfest = React.useCallback(async () => {
    return await fetchFromAPI.get(`/user/edit-fest/code`);
  }, [fetchFromAPI]);

  const canDownloadEditfestClip = React.useCallback(
    async clipId => {
      return await fetchFromAPI.get(`/clips/${clipId}/edit-fest/can-download`);
    },
    [fetchFromAPI]
  );

  const addCode = async code => {
    const response = await fetchFromAPI.post(`/user/edit-fest/code`, {
      code
    });
    return response;
  };

  return {
    addCode,
    getEditfest,
    canDownloadEditfestClip
  };
};

export default createContainer(useEditFest);
