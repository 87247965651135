import React from 'react';
import { StyledLink, FooterColumn } from './styles/StyledFooter';
import FeatureToggles from 'unstated/FeatureToggles';

export function Brands() {
  const { toggles } = FeatureToggles.useContainer();

  return (
    <>
      <FooterColumn>
        <StyledLink
          id="FooterFM"
          aria-label="FM"
          href="https://fm.co"
          target="_blank"
          rel="noreferrer"
          hoverColor="contrast4"
          color="primary"
          textComponent="P2"
          noMargin
        >
          <strong>FM</strong> is a family of brands dedicated to empowering the
          creative.
        </StyledLink>
      </FooterColumn>
      <FooterColumn>
        <div>
          <StyledLink
            id="FooterFilmsupply"
            to="/"
            hoverColor="contrast4"
            color="primary"
            textComponent="P2"
            noMargin
          >
            <strong>filmsupply</strong>
            Footage licensing for productions
          </StyledLink>
        </div>
        <div>
          <StyledLink
            id="FooterMusicbed"
            href="https://www.musicbed.com/"
            target="_blank"
            rel="noopener"
            hoverColor="contrast4"
            color="primary"
            textComponent="P2"
            noMargin
          >
            <strong>musicbed</strong>
            Music licensing for filmmakers
          </StyledLink>
        </div>

        <div>
          <StyledLink
            id="FooterStills"
            href="https://stills.com/"
            target="_blank"
            rel="noopener"
            hoverColor="contrast4"
            color="primary"
            textComponent="P2"
            noMargin
          >
            <strong>stills</strong>
            Photography licensing for designers
          </StyledLink>
        </div>
      </FooterColumn>
    </>
  );
}
