import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';

export const PopOver = styled(ReactTooltip)(({ theme, ...props }) => ({
  '&.popover-container': {
    whiteSpace: 'nowrap',
    borderRadius: 0,
    padding: 0,
    boxShadow: theme.shadow.depth2,
    opacity: '1 !important',
    '*': {
      scrollbarWidth: 'none',
      transition: 'none !important',
    },
  },
}));

export const Option = styled.li(({ theme, ...props }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  height: 31,
  width: 115,
  cursor: 'pointer',
  fontWeight: 600,
  borderRadius: 0,
  backgroundColor: [
    props.inverted ? theme.color.contrast1 : theme.color.staticWhite,
  ],
  color: [props.inverted ? theme.color.primary : theme.color.staticBlack],
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: [
      props.inverted ? theme.color.contrast3 : theme.color.staticGrey7,
    ],
  },
}));

export const Select = styled.ul(({ theme, bgColor, marginBottom = 4 }) => ({
  margin: 0,
  padding: '7px 4px',
  listStyle: 'none',
  backgroundColor: theme.color[bgColor || 'staticWhite'],
  [Option]: {
    '&:not(:last-child)': {
      marginBottom,
    },
  },
}));
