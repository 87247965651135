import { get } from 'lodash-es';
import normalizeUrl from 'helpers/normalizeUrl';

export function gtmDataLayer(
  props: Record<
    string,
    string | number | null | Record<string, unknown> | Record<string, unknown>[]
  >
): void {
  // This adapter function isolates 3rd party library usage and makes the usage of GTM more declarative
  window.dataLayer.push(props);
}

type Props = {
  user?: any;
  pageUrl: string;
  pageType: string;
  pageName: string;
};
export function trackPage({
  user,
  pageUrl,
  pageType,
  pageName,
  ...restProps
}: Props): void {
  const jobRole = get(user, 'active_occupation.occupation_type.name');
  const businessType = get(user, 'active_occupation.business_type.name');
  const roles = get(user, 'roles');

  window.dataLayer.push({
    userId: get(user, 'id'),
    pageUrl: pageUrl ? normalizeUrl(pageUrl) : '',
    pageType,
    pageName,
    jobRole,
    businessType,
    roles,
    ...restProps
  });
}
