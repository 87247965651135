import styled from '@emotion/styled';
import Flex from 'components/Flex/Flex';
import { HEADER_HEIGHT } from 'components/Header/Header';

export const BrandsNavbar = styled.nav<{ isDarkTheme: boolean }>(
  ({ theme, isDarkTheme }) => ({
    position: 'relative',
    zIndex: 10000,
    width: '100%',
    height: HEADER_HEIGHT.BRANDS_NAV_BAR,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: isDarkTheme ? theme.color.contrast2 : theme.color.staticBlack,
    padding: '0px 20px',

    [theme.media.lg]: {
      padding: '0 32px',
      justifyContent: 'space-between',
    },
    [theme.media.xl]: {
      padding: '0 32px',
    },
  }),
);

export const BrandsNavbarBrandsGroup = styled(Flex)(({ theme }) => ({
  [`&:hover ${String(BrandsNavbarLink)}`]: {
    color: theme.color.staticGrey4,
  },
  [`&:hover ${String(BrandsNavbarDivider)}`]: {
    backgroundColor: theme.color.staticGrey4,
  },
}));

export const BrandsNavbarLink = styled.a(({ theme }) => ({
  fontSize: 11,
  lineHeight: '11px',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.color.staticWhite,
  textDecoration: 'none',
  transition: 'color 0.6s ease',

  '&&&:hover': {
    color: theme.color.staticWhite,
  },
}));

export const BrandsNavbarDivider = styled.div(({ theme }) => ({
  height: 10,
  width: '1px',
  backgroundColor: theme.color.staticWhite,
  margin: '0 8px',
  transition: 'background-color 0.6s ease',
}));

export const BrandsNavbarContactGroup = styled(Flex)(({ theme }) => ({
  display: 'none',

  [`&:hover ${String(BrandsNavbarLink)}`]: {
    color: theme.color.staticGrey4,
  },
  [`&:hover ${String(BrandsNavbarContact)}`]: {
    color: theme.color.staticGrey4,
  },

  [theme.media.lg]: {
    display: 'flex',
    gap: 20,
  },
}));
export const BrandsNavbarContact = styled.a(({ theme }) => ({
  fontSize: 11,
  lineHeight: '12px',
  fontWeight: 500,
  color: theme.color.staticWhite,
  textDecoration: 'none',
  transition: 'color 0.3s',
  '&&&:hover': {
    color: theme.color.staticWhite,
  },
}));
