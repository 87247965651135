/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import lightTheme from '../../themes/lightTheme';
import { Link, NavLink } from 'react-router-dom';
import { P1, P2, P3, P4, T1, T2 } from 'components/Typography/Typography';

import * as Styled from './styles/StyledButton';

const Button = ({
  type,
  target,
  to,
  href,
  children,
  className,
  ...restProps
}) => {
  const linkStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  };

  // creating a new props object to pass to styled components removes all emotion and React DOM warnings.
  // https://reactjs.org/warnings/unknown-prop.html
  const styledProps = { ...restProps };

  if (to && !restProps.disabled) {
    return (
      <Styled.Button className={className} type={type} {...styledProps}>
        {children}
        <Link
          to={to}
          css={linkStyle}
          aria-label={restProps['aria-label'] || ''}
        />
      </Styled.Button>
    );
  } else if (href && !restProps.disabled) {
    return (
      <Styled.Button className={className} type={type} {...styledProps}>
        {children}
        <a
          href={href}
          target={target}
          css={linkStyle}
          aria-label={restProps['aria-label'] || ''}
        >
          {null}
        </a>
      </Styled.Button>
    );
  } else {
    return (
      <Styled.Button className={className} {...styledProps}>
        {children}
      </Styled.Button>
    );
  }
};

const TextLink = ({
  href,
  to,
  textComponent,
  children,
  hoverColor,
  color,
  className,
  nav,
  textComponentStyles,
  id,
  ...restProps
}) => {
  const BaseComponent = () => {
    switch (textComponent) {
      case 'T1':
        return (
          <T1 {...restProps} css={textComponentStyles}>
            {children}
          </T1>
        );
      case 'T2':
        return (
          <T2 {...restProps} css={textComponentStyles}>
            {children}
          </T2>
        );
      case 'P1':
        return (
          <P1 {...restProps} css={textComponentStyles}>
            {children}
          </P1>
        );
      case 'P2':
        return (
          <P2 {...restProps} css={textComponentStyles}>
            {children}
          </P2>
        );
      case 'P3':
        return (
          <P3 {...restProps} css={textComponentStyles}>
            {children}
          </P3>
        );
      case 'P4':
        return (
          <P4 {...restProps} css={textComponentStyles}>
            {children}
          </P4>
        );
      default:
        return (
          <P2 {...restProps} css={textComponentStyles}>
            {children}
          </P2>
        );
    }
  };

  if (!href && !to) {
    return (
      <Styled.Anchor
        id={id}
        className={className}
        hoverColor={hoverColor}
        color={color}
        {...restProps}
        css={{ cursor: 'pointer' }}
      >
        <BaseComponent />
      </Styled.Anchor>
    );
  } else if (href) {
    return (
      <Styled.Anchor
        id={id}
        className={className}
        href={href}
        hoverColor={hoverColor}
        color={color}
        {...restProps}
      >
        <BaseComponent />
      </Styled.Anchor>
    );
  } else if (to) {
    if (nav) {
      return (
        <Styled.Anchor
          id={id}
          as={NavLink}
          className={className}
          to={to}
          hoverColor={hoverColor}
          color={color}
          {...restProps}
        >
          <BaseComponent />
        </Styled.Anchor>
      );
    }
    return (
      <Styled.Anchor
        id={id}
        as={Link}
        className={className}
        to={to}
        hoverColor={hoverColor}
        color={color}
        {...restProps}
      >
        <BaseComponent />
      </Styled.Anchor>
    );
  } else {
    return null;
  }
};

const COLORS = Object.keys(lightTheme.color);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  /** Specifies the height of the button */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  /** Specifies what color the button should be */
  color: PropTypes.oneOf(COLORS),
  /** Specifies what color the button hover state should be */
  hoverColor: PropTypes.oneOf(COLORS),
  /** Specifies what color the icon hover state should be */
  iconHoverColor: PropTypes.oneOf(COLORS),
  /** Specifies the initial color of the icon */
  iconColor: PropTypes.oneOf(COLORS),
  /** Specifies what type the button should be */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Specifies what side the icon should be on */
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  /** Page to navigate to when clicked */
  to: PropTypes.string,
  /** Web link to navigate to when clicked */
  href: PropTypes.string,
  /** Specifies how the external link should be opened */
  target: PropTypes.oneOf(['_blank', '_self', '_top', '_parent']),
  /** Specifies if a button should be full-width */
  full: PropTypes.bool,
  /** Indicates when the button is disabled */
  disabled: PropTypes.bool,
  /** inverts disabled button colors */
  inverted: PropTypes.bool,
  /** Bolds button text */
  bold: PropTypes.bool,
  /** SemiBolds button text */
  semibold: PropTypes.bool,
  /** Use for buttons with only an icon as it's children */
  iconOnly: PropTypes.bool,
  /** renders an outline button */
  outline: PropTypes.bool,
  /** removes button padding */
  nopad: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  inverted: false,
  size: 'lg',
  type: 'button',
  target: '_self',
  iconPlacement: 'left',
};

export default Button;

TextLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  /** Specifies which text component should be rendered */
  textComponent: PropTypes.oneOf(['T1', 'T2', 'P1', 'P2', 'P3', 'P4']),
  /** Specifies whiat color the button should be */
  color: PropTypes.oneOf(COLORS),
  /** Specifies whiat color the button hover state should be */
  hoverColor: PropTypes.oneOf(COLORS),
  /** Page to navigate to when clicked */
  to: PropTypes.string,
  /** Web link to navigate to when clicked */
  href: PropTypes.string,
  nav: PropTypes.bool,
  textComponentStyles: PropTypes.object,
};

export { TextLink };
