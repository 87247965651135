import React from 'react';
import { TextLink } from 'components/Button/Button';

export function Links({ isMobile }) {
  return (
    <>
      <TextLink
        id="FooterPrivacyPolicy"
        hoverColor="staticWhite"
        to="/privacy-policy"
        color="staticGrey5"
        textComponent="P2"
      >
        Privacy Policy
      </TextLink>
      <TextLink
        id="FooterLicenseTerms"
        hoverColor="staticWhite"
        to="/license-terms"
        color="staticGrey5"
        textComponent="P2"
      >
        License Terms
      </TextLink>
      {!isMobile && (
        <TextLink
          id="FooterTermsAndConditions"
          hoverColor="staticWhite"
          to="/terms-and-conditions"
          color="staticGrey5"
          textComponent="P2"
        >
          Terms of Use
        </TextLink>
      )}
      <TextLink
        id="FooterCareers"
        hoverColor="staticWhite"
        href="https://fm.co/careers"
        target="_blank"
        rel="noopener noreferrer"
        color="staticGrey5"
        textComponent="P2"
      >
        Careers
      </TextLink>
    </>
  );
}
