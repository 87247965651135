import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';
import { P2, H2 } from 'components/Typography/Typography';
import FeatureToggles from 'unstated/FeatureToggles';
import Modals from 'unstated/Modals';

const footageResearchUrl = '/footage-research';

export function FindFootage({ isMobile }) {
  const history = useHistory();
  const { toggles } = FeatureToggles.useContainer();
  const { footageResearchModal } = Modals.useContainer();

  const hasFootageResearch = toggles.footageResearch;

  const handleFindMyFootage = () => {
    if (hasFootageResearch) {
      history.push(footageResearchUrl);
    } else {
      footageResearchModal.open();
    }
  };

  return (
    <>
      <H2 color="primary" semibold>
        Need help finding the right footage?
      </H2>{' '}
      <P2 color="primary">
        Have it delivered to your account{isMobile && <br />} within{' '}
        <strong>1 business day</strong>
      </P2>{' '}
      <Button
        id="FooterFindMyFootage"
        onClick={handleFindMyFootage}
        bold
        textComponent="P1"
        color="primary"
        hoverColor="primary"
      >
        Find My Footage
      </Button>
    </>
  );
}
