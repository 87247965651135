import React from 'react';
import Icon from 'components/Icon/Icon';

export function SocialLinks() {
  return (
    <div>
      <a
        id="FooterTwitter"
        aria-label="twitter"
        href="https://twitter.com/filmsupply"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="twitter" size="20px" color="staticWhite" />
      </a>
      <a
        id="FooterFacebook"
        aria-label="facebook"
        href="https://www.facebook.com/FilmSupply"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="facebook" size="20px" color="staticWhite" />
      </a>
      <a
        id="FooterInstagram"
        aria-label="instagram"
        href="https://instagram.com/filmsupply"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="instagram" size="20px" color="staticWhite" />
      </a>
      <a
        id="FooterLinkedin"
        aria-label="linkedin"
        href="https://www.linkedin.com/company/fm-co"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="linkedin" size="20px" color="staticWhite" />
      </a>
    </div>
  );
}
