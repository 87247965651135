import React, { Fragment } from 'react';
import * as Styled from './styles/StyledFeatureBanner';
import { Media } from 'react-matches';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import Flex from 'components/Flex/Flex';
import { P1, T1, T2, P4 } from 'components/Typography/Typography';
import Icon from 'components/Icon/Icon';
import useAxios from 'hooks/useAxios';
import Auth from 'unstated/Auth';

const FeatureBanner = () => {
  const [featureData, setFeatureData] = React.useState(null);
  const [restrictedRoute, setRestrictedRoute] = React.useState(true);
  const [remove, setRemove] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [mobileModalOpen, setMobileModalOpen] = React.useState(true);

  const location = useLocation();
  const theme = useTheme();
  const { user } = Auth.useContainer();
  const [featureBanner, featureBannerApi] = useAxios({
    baseURL: '/user/feature-banner'
  });
  const mounted = React.useRef();

  const restrictedUrls = [
    '/cart',
    '/cart/success',
    '/pricing',
    '/footage-research-form'
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      if (!mounted.current && user.email) {
        mounted.current = true;
        let data = await featureBannerApi.get();
        if (data) {
          setFeatureData(data.data);
          featureBannerApi.post('/read');
          setMobileModalOpen(true);
        }
      }
    };
    fetchData();
  }, [featureBanner, featureData, featureBannerApi, user]);

  React.useEffect(() => {
    if (featureData) {
      let restricted = false;
      featureData.exclude_on.forEach(page => {
        if (location.pathname === page) restricted = true;
      });
      restrictedUrls.forEach(page => {
        if (location.pathname === page) restricted = true;
      });
      setRestrictedRoute(restricted);
    }
  }, [location.pathname, featureData, restrictedUrls]);

  const handleClose = () => {
    setRemove(true);
    setModalOpen(false);
    setMobileModalOpen(false);
    setTimeout(() => {
      featureBannerApi.post('/dismiss');
    }, 500);
  };

  const getFormattedDescription = desc => {
    const search = '{{(.*?)}}';
    const regex = new RegExp(search, 'g');

    let result = [];
    desc.split(' ').forEach(word => {
      let skip = false;
      word.replaceAll(regex, (match, capture) => {
        skip = true;
        result.push([<Styled.Key key={capture}>{capture}</Styled.Key>, ' ']);
        return '';
      });
      if (!skip) result.push(`${word} `);
    });
    return result;
  };

  if (featureData && !restrictedRoute && user.email)
    return (
      <Media queries={theme.queries}>
        {({ matches }) => (
          <Fragment>
            <Modal
              isOpen={matches.lg ? modalOpen : mobileModalOpen}
              onClose={handleClose}
              noPad
              shouldCloseOnOverlayClick={false}
            >
              <div
                css={{
                  maxWidth: 520,
                  width: 'calc(100vw - 80px)',
                  margin: '45px 0'
                }}
              >
                <img
                  src={featureData.image.data.paths.profile.medium}
                  alt={featureData.title}
                />
                <div
                  css={{
                    maxWidth: 440,
                    margin: '25px auto 0',
                    textAlign: 'center',
                    padding: '0 40px'
                  }}
                >
                  <Styled.NewTag>NEW!</Styled.NewTag>

                  {featureData.title && (
                    <T1
                      color="staticBlack"
                      center
                      bold
                      css={{ margin: '20px 0 15px' }}
                    >
                      {featureData.title}
                    </T1>
                  )}

                  {featureData.description && (
                    <P1 color="staticBlack" center>
                      {getFormattedDescription(featureData.description)}
                    </P1>
                  )}
                </div>
              </div>
            </Modal>

            {matches.lg && (
              <Fragment>
                <Styled.Container
                  remove={remove}
                  isBrowse={location.pathname === '/clips'}
                >
                  <Flex align="center" justify="end">
                    <Styled.Image
                      src={featureData.image.data.paths.profile.small}
                      alt={featureData.title}
                    />
                    <div
                      css={{ marginLeft: 20, maxWidth: 'calc(100% - 200px)' }}
                    >
                      <Styled.NewTag>NEW!</Styled.NewTag>

                      {featureData.title && (
                        <T2 color="staticBlack" bold css={{ marginBottom: 6 }}>
                          {featureData.title}
                        </T2>
                      )}

                      {featureData.description && (
                        <P1 color="staticBlack">
                          {getFormattedDescription(featureData.description)}
                        </P1>
                      )}
                    </div>
                  </Flex>
                  <Styled.CloseIcon onClick={handleClose}>
                    <Icon name="close" size={10} color="staticBlack" />
                  </Styled.CloseIcon>
                  <Styled.ExpandButton onClick={() => setModalOpen(true)}>
                    <Icon
                      name="viewLarger"
                      size={10}
                      color="staticWhite"
                      css={{ marginRight: 5 }}
                    />
                    <P4 color="staticWhite" bold>
                      View Larger
                    </P4>
                  </Styled.ExpandButton>
                </Styled.Container>
              </Fragment>
            )}
          </Fragment>
        )}
      </Media>
    );
  else return null;
};

export default FeatureBanner;
