import React from 'react';
import { createContainer } from 'unstated-next';

const useSelectedLicense = () => {
  const [license, setLicense] = React.useState(null);

  return { license, setLicense };
};

export default createContainer(useSelectedLicense);
