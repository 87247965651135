import * as keyframes from './keyframes';

const darkTheme = {
  name: 'Primary Theme',
  mode: 'dark',
  color: {
    primary: '#FFF',
    contrast: '#0E0F17',
    contrast1: '#1C1D26',
    contrast2: '#292A33',
    contrast3: '#32333D',
    contrast4: '#73767A',
    contrast5: '#BFC2C3',
    contrast6: '#E2E3E4',
    contrast7: '#F3F4F4',
    staticWhite: '#FFF',
    staticBlack: '#0E0F17',
    staticGrey1: '#1C1D26',
    staticGrey2: '#292A33',
    staticGrey3: '#32333D',
    staticGrey4: '#73767A',
    staticGrey5: '#BFC2C3',
    staticGrey6: '#E2E3E4',
    staticGrey7: '#F3F4F4',
    blue: '#2483FF',
    lightBlue: '#3E91FF',
    accent: '#2483FF',
    lightAccent: '#3E91FF',
    red: '#EC1C1C',
    darkRed: '#D61B1B',
    yellow: '#f5a623',
    goldenYellow: '#F4A622',
    orange: '#df482b',
    lightOrange: '#F4A622',
    darkTeal: '#089898',
    teal: '#09a6a7',
    green: '#2BD0A8',
    purple: '#B752FF',
    darkPurple: '#57367C'
  },
  button: {
    contrastGroup: [
      'contrast',
      'contrast1',
      'contrast2',
      'contrast3',
      'contrast4',
      'staticBlack',
      'blue',
      'lightBlue',
      'lightAccent',
      'accent',
      'red',
      'darkRed',
      'yellow',
      'orange',
      'darkTeal',
      'teal',
      'green'
    ],
    primaryGroup: [
      'primary',
      'contrast5',
      'contrast6',
      'contrast7',
      'primary',
      'staticGrey6',
      'staticWhite'
    ]
  },
  shadow: {
    depth1: '0px 1px 20px rgba(0, 0, 0, 0.08)',
    depth2: '0px 5px 10px rgba(0, 0, 0, 0.13)'
  },
  font: {
    sans: 'proxima-nova,Proxima Nova,Helvetica Neue,Helvetica,Arial,sans-serif',
    serif: 'ivypresto-headline, serif'
  },
  queries: {
    sm: { maxWidth: 569 },
    md: { minWidth: 570 },
    lg: { minWidth: 834 },
    xl: { minWidth: 1024 },
    xxl: { minWidth: 1440 },
    xxxl: { minWidth: 2000 }
  },
  media: {
    mobileOnly: '@media only screen and (max-width: 569px)',
    mobileAndMd: '@media only screen and (max-width: 834px)',
    sm: '@media only screen and (min-width: 0px)',
    md: '@media only screen and (min-width: 570px)',
    lg: '@media only screen and (min-width: 834px)',
    xl: '@media only screen and (min-width: 1024px)',
    xxl: '@media only screen and (min-width: 1440px)',
    xxxl: '@media only screen and (min-width: 2000px)'
  },
  keyframes
};

export type DarkTheme = typeof darkTheme;

export default darkTheme;
