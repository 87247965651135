import React from 'react';
import * as Styled from './styles/StyledPopOver';
import PropTypes from 'prop-types';
import ReactPopOver from 'react-tooltip';
import { useTheme } from '@emotion/react';

export const Option = (props) => {
  return (
    <Styled.Option {...props}>
      <span>{props.children}</span>
    </Styled.Option>
  );
};

Option.propTypes = {
  children: PropTypes.any,
};

export const Select = (props) => {
  return <Styled.Select {...props}>{props.children}</Styled.Select>;
};

Select.propTypes = {
  children: PropTypes.node,
};

const PopOver = (props) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <div
        ref={props.targetRef}
        data-tip
        data-event={props.popoverEvent || 'click'}
        data-for={props.id}
        className={props.className}
      >
        {props.target}
      </div>
      <Styled.PopOver
        id={props.id}
        aria-haspopup="true"
        globalEventOff="click"
        effect="solid"
        place={props.place || 'bottom'}
        arrowColor={props.arrowColor || 'transparent'}
        backgroundColor={props.backgroundColor || theme.color.staticWhite}
        textColor={
          props.inverted ? theme.color.staticBlack : theme.color.staticWhite
        }
        clickable
        resizeHide
        opacity={1}
        ref={props.popOverRef}
        css={props.tooltipStyles}
        {...props}
        className={`popover-container ${props.className}`}
      >
        {props.children}
      </Styled.PopOver>
    </React.Fragment>
  );
};

PopOver.propTypes = {
  rebuild: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  inverted: PropTypes.bool,
  children: PropTypes.node,
  target: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  popoverEvent: PropTypes.string,
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  popOverRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  arrowColor: PropTypes.string,
  place: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  ...ReactPopOver.propTypes,
};

export default PopOver;
