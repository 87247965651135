import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import MobileMenu from './MobileMenu';

import useAxios from 'hooks/useAxios';

import * as Styled from './styles/StyledHeader';
import { BrandsNavbar } from './BrandsNavbar';

export const HEADER_HEIGHT = {
  BASE: 86,
  LG: 86,
  NAVBAR: 56,
  BRANDS_NAV_BAR: 30,
};

const Header = ({ onlyLogo }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [featuredCollection, reqFeaturedCollection] = useAxios({
    baseURL: '/collections/curated/featured',
  });
  const [collections, collectionAPI] = useAxios({
    baseURL: '/collections/featured',
  });

  React.useEffect(() => {
    reqFeaturedCollection.get();
    collectionAPI.get();
  }, [reqFeaturedCollection, collectionAPI]);

  return (
    <>
      <BrandsNavbar />
      <Styled.Header>
        <Navbar
          isMobileMenuOpen={isMobileMenuOpen}
          handleMobileMenuOpen={setIsMobileMenuOpen}
          onlyLogo={onlyLogo}
          featuredCollection={featuredCollection}
          collectionData={collections.data}
        />
        <MobileMenu
          isMobileMenuOpen={isMobileMenuOpen}
          handleMobileMenuOpen={setIsMobileMenuOpen}
        />
      </Styled.Header>
    </>
  );
};

Header.propTypes = {
  /** Shows only the FS logo and no other options in Header */
  onlyLogo: PropTypes.bool,
};

export default Header;
