import React from 'react';
import Flex from 'components/Flex/Flex';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import { P1 } from 'components/Typography/Typography';

export const PageFallback = () => {
  return (
    <Flex align="center" justify="center" css={{ minHeight: '80vh' }}>
      <CircleLoader>
        <P1 center color="primary" bold css={{ marginTop: 20 }}>
          Loading
        </P1>
      </CircleLoader>
    </Flex>
  );
};
