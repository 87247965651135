import styled from '@emotion/styled';
import { HEADER_HEIGHT } from 'components/Header/Header';

export const Layout = styled.div(
  {
    display: 'block',
    position: 'relative',
  },
  ({ theme, backgroundColor }) => ({
    backgroundColor: backgroundColor ? theme.color[backgroundColor] : 'initial',
  }),
);

export const Page = styled.div(
  ({ theme, offsetHeader, noHeight, pageTopPadding }) => ({
    minHeight: offsetHeader
      ? `calc(100vh - ${HEADER_HEIGHT.BASE}px)`
      : noHeight
        ? 'auto'
        : '100vh',
    display: 'block',
    paddingTop: pageTopPadding ? pageTopPadding : 0,
    position: 'relative',

    [theme.media.mobileOnly]: {
      paddingTop: pageTopPadding ? pageTopPadding : 20,
    },
    [theme.media.LG]: {
      minHeight: offsetHeader
        ? `calc(100vh - ${HEADER_HEIGHT.LG}px)`
        : noHeight
          ? 'auto'
          : '100vh',
    },
  }),
);

export const PageContent = styled.div(({ theme, isBrowse }) => ({
  margin: '0 auto',
  padding: '0 16px 60px 16px',
  transition: 'all .2s',
  maxWidth: '100%',

  '&.blur-page': {
    filter: 'blur(3px)',
    position: 'relative',
    zIndex: -1,
    pointerEvents: 'none',
    opacity: 0.5,
  },

  [theme.media.md]: {
    padding: `0 20px 20px ${isBrowse ? 0 : 20}px`,
  },
  [theme.media.lg]: {
    padding: `0 32px 20px ${isBrowse ? 0 : 32}px`,
  },
  [theme.media.xl]: {
    padding: `0 32px 20px ${isBrowse ? 0 : 32}px`,
  },
  [theme.media.xxl]: {
    padding: `0 32px 20px ${isBrowse ? 0 : 32}px`,
  },
}));

export const BasePageContent = styled.div(({ theme }) => ({
  margin: '0 auto',
  padding: '0 20px',

  [theme.media.lg]: {
    padding: '0 32px',
  },
  [theme.media.xl]: {
    padding: '0 32px',
  },
}));
