import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Media } from 'react-matches';
import { useTheme } from '@emotion/react';
import ScrollLock from 'react-scrolllock';
import { useHistory } from 'react-router-dom';
import { HEADER_HEIGHT } from 'components/Header/Header';
import Icon from 'components/Icon/Icon';
import Flex from 'components/Flex/Flex';

import { TextLink } from 'components/Button/Button';
import Auth from 'unstated/Auth';
import FeatureToggles from 'unstated/FeatureToggles';

import * as Styled from './styles/StyledMobileMenu';

const MobileButton = ({
  text,
  target = '_self',
  action,
  color = 'primary',
}) => {
  return (
    <Styled.MenuButton target={target} onClick={action} color={color}>
      {text}
    </Styled.MenuButton>
  );
};

const MobileMenu = ({ isMobileMenuOpen, handleMobileMenuOpen }) => {
  const { user, logout, masqueradeLogout } = Auth.useContainer();
  const history = useHistory();
  const { toggles } = FeatureToggles.useContainer();

  const theme = useTheme();

  const isSeller =
    user.isFilmmakerAdmin ||
    user.isCollectionAdmin ||
    user.isFilmmakerManager ||
    user.isOwner;
  const userLoggedIn = user?.email;

  const menuHeight = useMemo(() => {
    /* 
    Calculate the height of the menu based on the scroll distance
    and the height of the navbar and brands navbar
    */
    const getYScrollDistance = () => {
      const scrollDistance = window.scrollY;
      const brandsNavbarSize =
        HEADER_HEIGHT.BRANDS_NAV_BAR > scrollDistance
          ? HEADER_HEIGHT.BRANDS_NAV_BAR - scrollDistance
          : 0;
      return HEADER_HEIGHT.NAVBAR + brandsNavbarSize;
    };
    return isMobileMenuOpen
      ? `calc(100vh - ${getYScrollDistance()}px)`
      : HEADER_HEIGHT.NAVBAR;
  }, [isMobileMenuOpen]);

  const handleRouteChange = ({
    appRoute,
    externalRoute,
    target = '_blank',
  }) => {
    handleMobileMenuOpen(false);
    if (externalRoute) {
      if (target === '_self') {
        window.location.href = externalRoute;
      } else {
        window.open(externalRoute, target);
      }
      return;
    }
    appRoute && history.push(appRoute);
  };

  const currentYear = new Date().getFullYear();
  return (
    <Media queries={theme.queries}>
      {({ matches }) => (
        <>
          <Styled.Menu
            isMobileMenuOpen={isMobileMenuOpen}
            menuHeight={menuHeight}
          >
            {isMobileMenuOpen && !matches.lg && <ScrollLock />}
            <Styled.MenuBlock>
              <MobileButton
                text="Clips"
                action={() => handleRouteChange({ appRoute: '/clips' })}
              />
              <MobileButton
                text="Collections"
                action={() => handleRouteChange({ appRoute: '/collections' })}
              />
              <MobileButton
                text="Pricing"
                action={() => handleRouteChange({ appRoute: '/pricing' })}
              />
              <MobileButton
                text="Services"
                action={() => handleRouteChange({ appRoute: '/services' })}
              />

              {user.isAmbassador && (
                <MobileButton
                  text="Affiliates"
                  action={() =>
                    handleRouteChange({
                      externalRoute: 'https://app.impact.com/abe/FM/login.user',
                    })
                  }
                />
              )}

              {userLoggedIn && toggles.editfest_coupon_code && (
                <MobileButton
                  text="Editfest"
                  action={() =>
                    handleRouteChange({ appRoute: '/account/editfest' })
                  }
                />
              )}

              {userLoggedIn && isSeller && (
                <MobileButton
                  text="Selling Dashboard"
                  action={() =>
                    handleRouteChange({
                      externalRoute: `${import.meta.env.VITE_APP_URL}/dashboard/footage-manager/shoots`,
                      target: '_self',
                    })
                  }
                />
              )}

              <Styled.Spacer />

              {!userLoggedIn && (
                <MobileButton
                  text="Create Free Account"
                  action={() => handleRouteChange({ appRoute: '/register' })}
                />
              )}
              {userLoggedIn ? (
                <MobileButton
                  text={
                    user.masquerade
                      ? `Log Out as User: ${user.masquerade?.client_username}`
                      : 'Logout'
                  }
                  color={user.masquerade ? 'purple' : 'primary'}
                  action={user.masquerade ? masqueradeLogout : logout}
                />
              ) : (
                <MobileButton
                  text="Login"
                  action={() => handleRouteChange({ appRoute: '/login' })}
                />
              )}
            </Styled.MenuBlock>

            <Styled.MenuFooter>
              <Styled.MenuFooterSocialsRow>
                <div>
                  <a
                    id="FooterInstagram"
                    aria-label="instagram"
                    href="https://instagram.com/filmsupply"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="instagram" size="16px" color="primary" />
                  </a>
                </div>
                <div>
                  <a
                    id="FooterTwitter"
                    aria-label="twitter"
                    href="https://twitter.com/filmsupply"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="twitter" size="20px" color="primary" />
                  </a>
                </div>
                <div>
                  <a
                    id="FooterFacebook"
                    aria-label="facebook"
                    href="https://www.facebook.com/FilmSupply"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="facebook" size="20px" color="primary" />
                  </a>
                </div>
              </Styled.MenuFooterSocialsRow>

              <Styled.MenuFooterDescription>
                <div>
                  <span>
                    Filmsupply is a part of{' '}
                    <Styled.BoldText>FM</Styled.BoldText>, a family of brands
                    dedicated to empowering the creative.
                  </span>
                </div>
                <Styled.CopyRight>©{currentYear} FM LLC.</Styled.CopyRight>
              </Styled.MenuFooterDescription>

              <Styled.MenuFooterLinksRow>
                <div>
                  <TextLink
                    fontSize={12}
                    fontWeight={400}
                    to="/privacy-policy"
                    color="primary"
                    onClick={() => handleMobileMenuOpen(false)}
                  >
                    Privacy Policy
                  </TextLink>
                </div>
                <div>
                  <TextLink
                    fontSize={12}
                    fontWeight={400}
                    to="/license-terms"
                    color="primary"
                    onClick={() => handleMobileMenuOpen(false)}
                  >
                    Terms
                  </TextLink>
                </div>
              </Styled.MenuFooterLinksRow>
            </Styled.MenuFooter>
          </Styled.Menu>
        </>
      )}
    </Media>
  );
};

MobileMenu.propTypes = {
  isMobileMenuOpen: PropTypes.bool,
  isSubMenuOpen: PropTypes.bool,
  setSubMenuOpen: PropTypes.func,
  theme: PropTypes.object,
};

export default MobileMenu;
