import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios from 'hooks/useAxios';
import * as Sentry from '@sentry/browser';
import Hubspot from 'helpers/hubspot';
import md5 from 'md5';
import { ACCOUNT_CREATION } from 'constants/impactTrackingEvents';

let authedUser = null;
const useAuth = () => {
  let [{ data, ...response }, request] = useAxios({ baseURL: '/auth' });
  let [, updateUserProfile] = useAxios();
  let [user, setUser] = React.useState([]);
  let [initialFetchComplete, setInitialFetchComplete] = React.useState(false);

  let auth = React.useCallback(() => {
    const apiCall = async () => {
      if (response.notAsked) {
        const { data: userData } = await request.get();
        setUser(userData);
        if (!initialFetchComplete) setInitialFetchComplete(true);

        userData.id &&
          window.ire('identify', {
            customerId: userData.id,
            customerEmail: userData.email,
          });

        if (initialFetchComplete && userData.id) {
          Sentry.setUser({
            id: userData.id,
            email: userData.email,
            username: userData.username,
          });
        }
      }
    };
    apiCall();
  }, [initialFetchComplete, request, response.notAsked]);

  let updateUser = React.useCallback(() => {
    const apiCall = async () => {
      const { data: userData } = await request.get();
      setUser(userData);
      if (!initialFetchComplete) setInitialFetchComplete(true);
      if (initialFetchComplete && userData.id) {
        Sentry.setUser({
          id: userData.id,
          email: userData.email,
          username: userData.username,
        });
      }
    };
    apiCall();
  }, [initialFetchComplete, request]);

  let login = async ({ login, password, remember }) => {
    const { cart_hash } = window.localStorage;

    const { data: userData, ...rest } = await request.post('/login', {
      login,
      password,
      remember,
      ...(cart_hash && { cart_hash }),
    });
    if (userData) {
      setUser(userData);
      window.ire('identify', {
        customerId: userData.id,
        customerEmail: userData.email,
      });

      Sentry.setUser({
        id: userData.id,
        email: userData.email,
        username: userData.username,
      });
      return { data: userData, ...rest };
    }
    return rest;
  };

  let register = async ({
    first_name,
    last_name,
    email,
    password,
    pageUrl,
    pageName,
    hutk,
    cart_hash,
  }) => {
    const { data: userData, ...rest } = await request.post('/register', {
      first_name,
      last_name,
      email,
      password,
      password_again: password,
      activate: true,
      pageUrl,
      pageName,
      hutk,
      cart_hash,
    });

    if (userData) {
      setUser(userData);

      window.ire(
        'trackConversion',
        ACCOUNT_CREATION,
        {
          orderId: userData.id.toString(),
          customerId: userData.id.toString(),
          customerEmail: md5(userData.email),
        },
        {
          verifySiteDefinitionMatch: true,
        },
      );
      Sentry.setUser({
        id: userData.id,
        email: userData.email,
        username: userData.username,
      });
      return { data: userData, ...rest };
    }
    return rest;
  };

  let logout = async () => {
    const { data } = await request.post('/logout');
    if (data.success) {
      authedUser = null;
      document.cookie =
        'hubspotutk=;Path=/;Domain=.filmsupply.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        '__hstc=;Path=/;Domain=.filmsupply.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.location.reload();
      Sentry.setUser(null);
      localStorage.removeItem('cart_hash');
    }
  };

  let masqueradeLogout = async () => {
    await request.get('/logout/masquerade');
    document.cookie =
      'hubspotutk=;Path=/;Domain=.filmsupply.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      '__hstc=;Path=/;Domain=.filmsupply.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.reload();
  };

  let setTheme = async (theme) => {
    const refreshPages = ['/clips', '/account/licenses'];
    if (refreshPages.includes(window.location.pathname)) {
      const { data } = await updateUserProfile.put('/user/profile/theme', {
        theme,
      });
      if (data.success) window.location.reload();
    } else {
      await setUser({ ...user, theme });
      localStorage.setItem('theme', theme);
      await updateUserProfile.put('/user/profile/theme', { theme });
    }
  };

  /**
   * Track HubSpot user on authentication
   */
  const cacheAuthedUser = authedUser === null && user.id !== undefined;
  if (cacheAuthedUser) {
    authedUser = user;
    const { id, email } = user;
    Hubspot.identifyUser(id, email);
  }

  return {
    user,
    response,
    auth,
    updateUser,
    login,
    register,
    logout,
    masqueradeLogout,
    initialFetchComplete,
    setTheme,
  };
};

export default createContainer(useAuth);
