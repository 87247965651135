import styled from '@emotion/styled';

export const Notification = styled.div(
  ({ theme, showNotification, fullWidth, maxWidth }) => ({
    display: 'flex',
    opacity: showNotification ? 1 : 0,
    padding: 20,
    backgroundColor: theme.color.staticWhite,
    borderRadius: 0,
    boxShadow: theme.shadow.depth1,
    width: 'calc(100% - 30px)',
    maxWidth: fullWidth ? 'calc(100% - 30px)' : maxWidth,
    color: theme.color.contrast,
    position: 'fixed',
    zIndex: 999999,
    ...(fullWidth
      ? {
          top: '100%',
          left: 15,
          right: 15
        }
      : {
          left: '100%',
          bottom: 15
        }),
    ...(showNotification && {
      transform: fullWidth
        ? 'translateY(calc(-100% - 15px))'
        : 'translateX(calc(-100% - 15px))'
    }),
    transformOrigin: fullWidth ? 'center bottom' : 'right center',
    transition: 'transform .3s ease-in-out, opacity .8s ease',
    ':hover': {
      '.closeIcon': {
        opacity: 1
      }
    },
    [theme.media.mobileOnly]: {
      borderRadius: 16
    },
    ...(!showNotification && {
      [CloseIcon]: { display: 'none' }
    })
  })
);

export const CloseIcon = styled.div(({ theme, isFlash }) => ({
  opacity: isFlash ? 0 : 1,
  position: 'absolute',
  top: 10,
  right: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.media.mobileOnly]: {
    borderRadius: '50%',
    width: 36,
    height: 36,
    backgroundColor: theme.color.staticGrey7
  }
}));
