import React from 'react';
import Helmet from 'react-helmet';
import meta from 'meta';
import PropTypes from 'prop-types';
import defaultCanonical from 'helpers/urls';

const MetaDecorator = ({
  title,
  description,
  keywords,
  imageUrl,
  imageAlt,
  statusCode,
  header,
  noindex,
  canonical
}) => {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title || meta.name}</title>
      <meta name="title" content={title || meta.title.default} />
      <meta
        name="description"
        content={description || meta.description.default}
      />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={title || meta.og.title} />
      <meta
        property="og:description"
        content={description || meta.description.default}
      />
      <meta property="og:image" content={imageUrl || meta.og.image} />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content={title || meta.twitter.title} />
      <meta
        property="twitter:description"
        content={description || meta.description.default}
      />
      <meta property="twitter:image" content={imageUrl || meta.twitter.image} />
      <meta
        property="twitter:image:alt"
        content={imageAlt || meta.twitter.title}
      />

      {/* <!-- Enable Zoom for Input Fields on Mobile --> */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {keywords && <meta property="keywords" content={keywords} />}
      {statusCode && <meta name="prerender-status-code" content={statusCode} />}
      {header && <meta name="prerender-header" content={header} />}
      {noindex && <meta name="robots" content="noindex, nofollow" />}
      {canonical ? (
        <link rel="canonical" href={canonical} />
      ) : (
        <link rel="canonical" href={defaultCanonical()} />
      )}
      {process.env.NODE_ENV === 'production' ? (
        <>
          <link
            rel="preconnect"
            href="https://filmsupply-public.s3.amazonaws.com"
          />
        </>
      ) : null}
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  imageUrl: PropTypes.any,
  imageAlt: PropTypes.string,
  statusCode: PropTypes.number,
  header: PropTypes.string,
  noindex: PropTypes.bool,
  canonical: PropTypes.string
};

export default MetaDecorator;
