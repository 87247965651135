import * as Styled from './styles/StyledBrandsNavbar';
import { PHONE_NUMBER } from 'constants/contactInfo';
import Auth from 'unstated/Auth';

export const BrandsNavbar = () => {
  const { user } = Auth.useContainer();
  const isDarkTheme = (user as any)?.theme === 'dark';
  return (
    <Styled.BrandsNavbar isDarkTheme={isDarkTheme}>
      <Styled.BrandsNavbarBrandsGroup align="center" role="group">
        <Styled.BrandsNavbarLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.musicbed.com/"
        >
          Musicbed
        </Styled.BrandsNavbarLink>
        <Styled.BrandsNavbarDivider />
        <Styled.BrandsNavbarLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.filmsupply.com/"
        >
          Filmsupply
        </Styled.BrandsNavbarLink>
        <Styled.BrandsNavbarDivider />
        <Styled.BrandsNavbarLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.stills.com/"
        >
          Stills
        </Styled.BrandsNavbarLink>
      </Styled.BrandsNavbarBrandsGroup>
      <Styled.BrandsNavbarContactGroup role="group">
        <Styled.BrandsNavbarContact href="/contact">
          Contact Us
        </Styled.BrandsNavbarContact>
        <Styled.BrandsNavbarLink href={PHONE_NUMBER.HREF}>
          {PHONE_NUMBER.LOCAL}
        </Styled.BrandsNavbarLink>
      </Styled.BrandsNavbarContactGroup>
    </Styled.BrandsNavbar>
  );
};
