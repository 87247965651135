/* eslint-disable react/display-name */
import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import PropTypes from 'prop-types';
import NotFound from 'pages/404';
import meta from '../../meta';
import { errorLogger } from 'utils/errorLogger';

const FallbackComponent = () => (
  <NotFound
    message="There was an issue, please try refreshing the page."
    metaTitle={`Something went wrong | ${meta.title.base}`}
    noindex={false}
    smallMessage={false}
    title="Error"
  />
);

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    errorLogger({ message: 'Error Boundary Event', error });
  }
  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (import.meta.env.VITE_APP_ENV === 'production' && hasError) {
      return (
        <SentryErrorBoundary fallback={<FallbackComponent />}>
          {children}
        </SentryErrorBoundary>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ])
};
