import React from 'react';
import { useTheme } from '@emotion/react';
import { Media } from 'react-matches';
import axios from 'axios';
import Color from 'color';
import { bool, array, object } from 'prop-types';
import Skeleton from 'components/Skeleton/Skeleton';
import Flex from 'components/Flex/Flex';
import { H2, P3, Text } from 'components/Typography/Typography';
import { TextLink } from 'components/Button/Button';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import * as Styled from './styles/StyledThisIsFilmsupplyNav';
import Auth from 'unstated/Auth';

const LoadingFilms = ({ queries }) => {
  return (
    <Flex css={{ position: 'relative', width: 509 }}>
      <Media queries={{ ...queries, xl2: { minWidth: 1440 } }}>
        {({ matches }) =>
          matches.xl2 ? (
            <>
              <Flex
                grow={3}
                css={{
                  height: '100%',
                  width: 291,
                  position: 'relative'
                }}
              >
                <Skeleton css={{ height: 142 }} />
              </Flex>
              <Flex
                grow={1}
                css={{
                  height: '100%',
                  width: 101,
                  marginLeft: 8
                }}
              >
                <Skeleton css={{ height: 142 }} />
              </Flex>
              <Flex
                grow={1}
                css={{
                  height: '100%',
                  width: 101,
                  marginLeft: 8
                }}
              >
                <Skeleton css={{ height: 142 }} />
              </Flex>
            </>
          ) : (
            <>
              <Flex
                grow={3}
                css={{
                  height: '100%',
                  minWidth: 291,
                  position: 'relative'
                }}
              >
                <Skeleton css={{ height: 142 }} />
              </Flex>
            </>
          )
        }
      </Media>
    </Flex>
  );
};

const MegaNavFilmsupply = ({ isVisible }) => {
  const [films, setFilms] = React.useState(null);
  const theme = useTheme();
  const { user } = Auth.useContainer();
  const isDarkTheme = user?.theme === 'dark';
  const queries = { ...theme.queries, xl2: { minWidth: 1440 } };
  const fetchFilms = async () => {
    const results = await axios.get(
      `https://www.filmsupply.com/films/wp-json/wp/v2/films?_embed`,
      {}
    );
    return results.data;
  };
  React.useEffect(() => {
    const fetchData = async () => {
      const res = await fetchFilms();
      setFilms(res.slice(0, 3));
    };
    fetchData();
  }, []);
  return (
    <Media queries={{ ...queries, xl2: { minWidth: 1440 } }}>
      {({ matches }) => (
        <Flex
          column
          align="center"
          justify="start"
          css={{
            width: '100%',
            marginTop: 50,
            height: 399,
            display: isVisible ? 'flex' : 'none'
          }}
        >
          <Styled.Main css={{ maxWidth: 1200 }}>
            <Styled.Col borderWidth={0} css={{ paddingRight: 35 }}>
              <Text
                bold
                color="primary"
                fontSize={'26px'}
                lineHeight={'30px'}
                css={{ marginBottom: 10 }}
              >
                Empowering the Creative Community
              </Text>
              <Styled.Div
                css={{ maxWidth: 241, marginTop: 5 }}
                isDarkTheme={isDarkTheme}
              >
                License cinematic footage from the world's top filmmakers.
              </Styled.Div>
              <Styled.ChevronLinkCol
                css={{
                  position: 'relative',
                  left: -13,
                  justifyContent: 'flex-start'
                }}
              >
                <Styled.LinkBold
                  id="meganavOurStory"
                  isDarkTheme={isDarkTheme}
                  to={'/about'}
                  css={{ marginTop: 5 }}
                >
                  {`Our Story`}
                  <Icon
                    name="chevronArrowRight"
                    color={'contrast5'}
                    css={{ width: 6, marginLeft: 5, height: 6 }}
                  />
                </Styled.LinkBold>
                <Styled.LinkBoldHREF
                  id="meganavOurFilmmakers"
                  isDarkTheme={isDarkTheme}
                  href={'https://www.filmsupply.com/filmmakers/'}
                  target="_blank"
                >
                  {`Our Filmmakers`}
                  <Icon
                    name="chevronArrowRight"
                    color={'contrast5'}
                    css={{ width: 6, marginLeft: 5, height: 6 }}
                  />
                </Styled.LinkBoldHREF>
              </Styled.ChevronLinkCol>
            </Styled.Col>
            <Styled.CenterCol css={{ width: 612 }}>
              <div>
                <Styled.RowLink
                  href={'https://www.filmsupply.com/films/'}
                  target="_blank"
                >
                  {'FILMMAKER FILMS'}
                  <Icon
                    name="chevronArrowRight"
                    color={'contrast5'}
                    css={{ width: 6, marginLeft: 5, height: 6 }}
                  />
                </Styled.RowLink>

                <Styled.Row>
                  {!films ? (
                    <LoadingFilms queries={queries} />
                  ) : (
                    films.map((film, i) => {
                      if (film?.yoast_head_json?.og_image) {
                        if (i === 0) {
                          return (
                            <Flex
                              key={film?.id}
                              grow={3}
                              column
                              align="start"
                              justify="between"
                              css={{
                                height: 142,
                                width: matches.xl2 ? 291 : 291,
                                position: 'relative',
                                padding: 8,
                                background: `url(${film?.ACF?.featured_banner_image}) center center no-repeat`,
                                backgroundSize: 'cover',
                                ':hover': {
                                  transform: 'scale(1.03)'
                                }
                              }}
                            >
                              <Link
                                id="meganavFilmPlaceOne"
                                href={film?.link.replace(
                                  'fsfilms.wpengine.com',
                                  'filmsupply.com/films'
                                )}
                                color="staticWhite"
                                css={{
                                  width: '100%',
                                  height: '100%',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                  textDecoration: 'none',
                                  textTransform: 'uppercase'
                                }}
                              >
                                <H2 color="staticWhite" italic>
                                  {film?.title?.rendered}
                                </H2>
                                <Flex
                                  align="end"
                                  justify="between"
                                  css={{
                                    width: '100%',
                                    height: 'calc(100% - 30px)'
                                  }}
                                >
                                  <Flex
                                    align="center"
                                    justify="center"
                                    css={{
                                      background: theme.color.staticWhite,
                                      width: 48,
                                      height: 29
                                    }}
                                  >
                                    <Icon
                                      name="play"
                                      size="md"
                                      color="staticBlack"
                                    />
                                  </Flex>
                                  <Flex
                                    align="center"
                                    justify="center"
                                    css={{
                                      backgroundColor: `${Color(
                                        theme.color.staticBlack
                                      ).alpha(0.5)}`,
                                      width: 40,
                                      height: 16,
                                      lineHeight: 16
                                    }}
                                  >
                                    <P3 color="staticWhite">
                                      {film?.ACF?.duration}
                                    </P3>
                                  </Flex>
                                </Flex>
                              </Link>
                            </Flex>
                          );
                        } else if (matches.xl2) {
                          const id =
                            i === 1
                              ? 'meganavFilmPlaceTwo'
                              : 'meganavFilmPlaceThree';
                          return (
                            <Flex
                              key={film?.id}
                              grow={1}
                              css={{
                                height: 142,
                                width: 101,
                                background: `url(${film?._embedded['wp:featuredmedia']['0']?.media_details?.sizes?.medium.source_url}) center center no-repeat`,
                                backgroundSize: 'cover',
                                marginLeft: 8,
                                ':hover': {
                                  transform: 'scale(1.05)'
                                }
                              }}
                            >
                              <TextLink
                                id={id}
                                textComponent="T2"
                                href={film?.link.replace(
                                  'fsfilms.wpengine.com',
                                  'filmsupply.com/films'
                                )}
                                color="staticWhite"
                                semibold
                                uppercase
                                full
                                hoverColor="staticWhite"
                                css={{
                                  width: '100%',
                                  '& > p': {
                                    width: '100%',
                                    height: '100%'
                                  },
                                  height: '100%',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between'
                                }}
                              ></TextLink>
                            </Flex>
                          );
                        }
                      }
                      return null;
                    })
                  )}
                </Styled.Row>
              </div>
              <div>
                <Styled.RowLink
                  id="meganavBehindTheWork"
                  href={'https://behindthework.filmsupply.com/'}
                  target="_blank"
                >
                  {'BEHIND THE WORK'}
                  <Icon
                    name="chevronArrowRight"
                    color={'contrast5'}
                    css={{ width: 6, marginLeft: 5, height: 6 }}
                  />
                </Styled.RowLink>
                <Styled.Row>
                  <Flex
                    grow={1}
                    css={{
                      position: 'relative',
                      marginRight: matches.xl2 ? 8 : 0,
                      height: matches.xl2 ? 75 : 142,
                      width: matches.xl2 ? 165 : 292,
                      background: `url(https://cdn.musicbed.com/image/upload/${
                        matches.xl2
                          ? 'w_168,h_76,c_scale'
                          : 'w_383,h_142,c_fill'
                      }/Filmsupply/static/thisIsMusicBedMegaNav/${
                        matches.xl2 ? 'BTW1.png' : 'BTW_lg.png'
                      }) center center no-repeat`,
                      backgroundSize: 'cover',
                      marginLeft: 0,
                      imageRendering: 'pixelated',
                      ':hover': {
                        transform: 'scale(1.03)'
                      }
                    }}
                  >
                    <TextLink
                      id="meganavTheWorkPlaceOne"
                      textComponent="T2"
                      href={
                        'https://behindthework.filmsupply.com/behind-the-work/yeti-crafting-authentic-brand-films'
                      }
                      color="staticWhite"
                      semibold
                      uppercase
                      full
                      hoverColor="staticWhite"
                      css={{
                        width: '100%',
                        '& > p': {
                          width: '100%',
                          height: '100%'
                        },
                        height: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}
                    />
                  </Flex>
                  <Flex
                    grow={1}
                    css={{
                      position: 'relative',
                      display: matches.xl2 ? 'flex' : 'none',
                      marginRight: 8,
                      height: matches.xl2 ? 75 : 142,
                      width: matches.xl2 ? 165 : '100%',
                      background: `url(https://cdn.musicbed.com/image/upload/w_168,h_76,c_scale/Filmsupply/static/thisIsMusicBedMegaNav/BTW2.png) center center no-repeat`,
                      backgroundSize: 'cover',
                      imageRendering: 'pixelated',
                      marginLeft: 0,
                      ':hover': {
                        transform: 'scale(1.03)'
                      }
                    }}
                  >
                    <TextLink
                      id="meganavTheWorkPlaceTwo"
                      textComponent="T2"
                      href={
                        'https://behindthework.filmsupply.com/behind-the-work/the-north-face'
                      }
                      color="staticWhite"
                      semibold
                      uppercase
                      full
                      hoverColor="staticWhite"
                      css={{
                        width: '100%',
                        '& > p': {
                          width: '100%',
                          height: '100%'
                        },
                        height: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}
                    />
                  </Flex>
                  <Flex
                    grow={1}
                    css={{
                      position: 'relative',
                      display: matches.xl2 ? 'flex' : 'none',
                      height: matches.xl2 ? 75 : 142,
                      width: matches.xl2 ? 165 : '100%',
                      background: `url(https://cdn.musicbed.com/image/upload/w_168,h_76,c_scale/Filmsupply/static/thisIsMusicBedMegaNav/BTW3.png) center center no-repeat`,
                      backgroundSize: 'cover',
                      marginLeft: 0,
                      imageRendering: 'pixelated',
                      ':hover': {
                        transform: 'scale(1.03)'
                      }
                    }}
                  >
                    <TextLink
                      id="meganavFilmPlaceThree"
                      textComponent="T2"
                      href={
                        'https://behindthework.filmsupply.com/behind-the-work/oakley-stept-studios'
                      }
                      color="staticWhite"
                      semibold
                      uppercase
                      full
                      hoverColor="staticWhite"
                      css={{
                        width: '100%',
                        '& > p': {
                          width: '100%',
                          height: '100%'
                        },
                        height: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}
                    />
                  </Flex>
                </Styled.Row>
              </div>
            </Styled.CenterCol>
            <Styled.LinksCol paddingLeft={41}>
              <Styled.SideLink
                id="meganavArticles"
                isDarkTheme={isDarkTheme}
                href={'https://www.filmsupply.com/articles/'}
                target="_blank"
                bold
              >
                Articles
              </Styled.SideLink>
              <Styled.SideLink
                id="meganavEditing"
                isDarkTheme={isDarkTheme}
                href={'https://www.filmsupply.com/articles/category/editing/'}
                target="_blank"
              >
                Editing
              </Styled.SideLink>
              <Styled.SideLink
                id="meganavAdvertising"
                isDarkTheme={isDarkTheme}
                href={
                  'https://www.filmsupply.com/articles/category/advertising/'
                }
                target="_blank"
              >
                Advertising
              </Styled.SideLink>
              <Styled.SideLink
                id="meganavProduction"
                isDarkTheme={isDarkTheme}
                href={
                  'https://www.filmsupply.com/articles/category/production/'
                }
                target="_blank"
              >
                Production
              </Styled.SideLink>
              <Styled.SideLink
                id="meganavDirecting"
                isDarkTheme={isDarkTheme}
                href={'https://www.filmsupply.com/articles/category/directing/'}
                target="_blank"
                s
              >
                Directing
              </Styled.SideLink>
              <Styled.SideLink
                id="meganavFilmsupplyNews"
                isDarkTheme={isDarkTheme}
                href={
                  'https://www.filmsupply.com/articles/category/filmsupply-news/'
                }
                target="_blank"
                s
              >
                Filmsupply News
              </Styled.SideLink>
              <Styled.SideLink
                id="meganavAllArticles"
                isDarkTheme={isDarkTheme}
                href={'https://www.filmsupply.com/articles/'}
                target="_blank"
              >
                All Articles
              </Styled.SideLink>
            </Styled.LinksCol>
          </Styled.Main>
        </Flex>
      )}
    </Media>
  );
};

MegaNavFilmsupply.propTypes = {
  isDarkTheme: bool,
  data: array,
  collections: object,
  featured: object,
  isVisible: bool
};

export default MegaNavFilmsupply;
