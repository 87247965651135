import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles/StyledBubble';

const Bubble = ({ count, cart }) => {
  /** Parent element must be position relative */
  const countLength = count && count.toString().length;
  return (
    <Styled.Bubble cart={cart} countLength={countLength}>
      {count && count}
    </Styled.Bubble>
  );
};

export default Bubble;

Bubble.propTypes = {
  /** Number to show inside bubble */
  count: PropTypes.number,
  /** Specifies the height of the button */
  cart: PropTypes.bool
};
