import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useAxios from 'hooks/useAxios';
import Auth from 'unstated/Auth';
import baseForm from 'components/Forms/Form';
import Input from 'components/Forms/Deprecated/Input';
import Checkbox from 'components/Checkbox/Deprecated/Checkbox';
import Button from 'components/Button/Button';
import Flex from 'components/Flex/Flex';
import { P2 } from 'components/Typography/Typography';
import useMountEffect from 'hooks/useMountEffect';
import { useLocation } from 'react-router-dom';
import Hubspot from 'helpers/hubspot';
import FeatureToggles from 'unstated/FeatureToggles';
import { gtmDataLayer } from 'utils/gtm';

const RegisterForm = ({
  formState,
  formApi,
  isInverted,
  onComplete,
  buttonText,
  buttonColor = 'staticBlack',
}) => {
  const location = useLocation();
  const { toggles } = FeatureToggles.useContainer();
  const { user, response, register } = Auth.useContainer();
  const [manualOptIn, setManualOptIn] = React.useState(null);
  let [, request] = useAxios();
  const { values } = formState;

  useMountEffect(() => {
    const getLocation = async () => {
      const data = await request.get('/geoip');
      if (data.data?.is_eu || data.data?.is_ca) {
        setManualOptIn(true);
      }
    };
    getLocation();
  });

  const isSubmitDisabled = () => {
    return (
      response.loading ||
      formState.invalid ||
      !values.name ||
      !values.email ||
      !values.password
    );
  };

  const handleFormSubmit = async () => {
    const { name, email, password, terms } = values;

    let names = name.split(' ');
    if (names.length < 2) {
      formApi.setError('name', 'Enter your first and last name');
      return;
    }

    // HubSpot form tracking data
    const pageUrl = `${import.meta.env.VITE_APP_URL}${location.pathname}`;
    const pageName = `${document.title}`;
    const hutk = Hubspot.getCookieData();
    const registerReq = {
      first_name: names[0],
      last_name: names[1],
      email,
      password,
      terms,
      pageUrl,
      pageName,
      hutk,
    };
    const { data: success, errors } = await register(registerReq);
    if (success) {
      (terms || !manualOptIn) &&
        (await request.post(
          `/user/notification-preferences/register-page/${success.id}`,
          {
            email: success.email,
            preferences: [
              'email_filmsupply_films',
              'email_blog_updates',
              'email_marketing_updates',
            ],
          },
        ));
      gtmDataLayer({
        event: 'registerFormSubmission',
        page: window?.location?.pathname,
        response: success,
      });
      onComplete && onComplete({ user: success });
      return;
    }
    if (errors) {
      Object.keys(errors).map((key) => {
        if (key === 'first_name' || key === 'last_name') {
          formApi.setError('name', 'Enter your first and last name');
        } else {
          formApi.setError(key, errors[key]);
        }
        return null;
      });
    }
  };

  const ctaText = response.loading
    ? 'Registering...'
    : toggles?.homeCTAsToCreateFreeAccount
      ? 'Create Free Account'
      : buttonText;
  if (!user?.email)
    return (
      <Fragment>
        <Input
          inverted={isInverted}
          field="name"
          type="text"
          required
          label="Name"
          placeholder="First & Last"
        />
        <Input
          inverted={isInverted}
          field="email"
          type="email"
          required
          label="Email"
        />
        <Input
          inverted={isInverted}
          field="password"
          type="password"
          required
          label="Password"
        />
        {manualOptIn && (
          <Flex
            justify="start"
            align="center"
            css={{ width: '100%', marginTop: 20 }}
          >
            <Checkbox field="terms" css={{ marginTop: 3 }} />
            <div css={{ marginLeft: 10 }}>
              <P2 color="staticBlack">
                Stay up to date with films, footage, and filmmaking content.
              </P2>
            </div>
          </Flex>
        )}
        <Button
          id="register"
          bold
          full
          color={buttonColor}
          inverted={isInverted}
          type="submit"
          onClick={handleFormSubmit}
          disabled={isSubmitDisabled()}
          css={{ marginTop: 25 }}
        >
          {ctaText}
        </Button>
      </Fragment>
    );
  return null;
};

RegisterForm.propTypes = {
  formState: PropTypes.shape({
    values: PropTypes.object,
    invalid: PropTypes.bool,
  }),
  formApi: PropTypes.shape({
    setError: PropTypes.func,
  }),
  buttonColor: PropTypes.string,
  isInverted: PropTypes.bool,
  onComplete: PropTypes.func,
  buttonText: PropTypes.string,
};

RegisterForm.defaultProps = {
  isInverted: false,
  buttonText: 'Register',
};

export default baseForm(RegisterForm);
